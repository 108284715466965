import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_A_STUDENT, UPDATE_A_STUDENT } from './actionTypes';
import {
    getAStudentSuccess,
    getAStudentFailed,
    updateAStudentSuccess,
    updateAStudentFailed,
    getTeachersForChild,
    getMedicalDocs
} from './actions';
import {aStudent, editAStudent} from '../../../apiHelpers/studentDetailHelper';
import toastr from 'toastr';
import { getStoreStudents, getStoreTotal } from './selectors';

function* getStudent({ payload: id }) {
    try {
        const response = yield call(aStudent, `/api/v1/students/${id}`);
        if(response.status === 200) {
            yield put(getAStudentSuccess(JSON.parse(response.data.student)));
            yield put(getTeachersForChild(JSON.parse(response.data.teachers)));
            yield put(getMedicalDocs(JSON.parse(response.data.docs)));
        } else {
            yield put(getAStudentFailed(response.status));
            toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getAStudentFailed(error));
    }
}

function* updateStudent({ payload: student }) {
    try {
        const id = student.id
        delete student.id
        const response = yield call(editAStudent, `/api/v1/students/${id}`, {...student});
        if (response.status === 200) {
            yield put(getAStudentSuccess(JSON.parse(response.data.student)))
            yield put(updateAStudentSuccess())
            toastr.success("The child information has been updated!")
        } else {
            toastr.error("It seems the SIN informed already exists","Error")
            yield put(updateAStudentFailed(response.status));
        }
    } catch (error) {
        yield put(updateAStudentFailed(error));
    }
}


export function* watchGetStudent() {
    yield takeEvery(GET_A_STUDENT, getStudent)
}

export function* watchUpdateStudent() {
    yield takeEvery(UPDATE_A_STUDENT, updateStudent)
}


function* studentDetaliSaga() {
    yield all([
        fork(watchGetStudent),
        fork(watchUpdateStudent)
    ]);
}

export default studentDetaliSaga;