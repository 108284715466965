import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_ALL_GROUPS, CREATE_GROUP, UPDATE_GROUP, GET_ONE_GROUP } from './actionTypes';
import {
    getAllGroupsSuccess,
    getAllGroupsFailed,
    totalGroups,
    createGroupSuccess,
    createGroupFailed,
    updateGroupSuccess,
    updateGroupFailed,
    getAllGroups,
    getTeachers,
    getStudents,
    getOneGroupSuccess,
    getOneGroupFailed
} from './actions';
import {addNewGroup, allGroups, editGroup, getAGroupList} from '../../../apiHelpers/groupApiHelper';
import toastr from 'toastr';
import { getStoreGroups, getStoreTotal } from './selectors';

function* getGroups({ payload: { page, pageSize } }) {
    try {
        const response = yield call(allGroups, '/api/v1/group', {page, pageSize});
        if(response.status === 200) {
            yield put(totalGroups(response.data.total));
            yield put(getTeachers(JSON.parse(response.data.teachers)));
            yield put(getStudents(JSON.parse(response.data.students)));
            yield put(getAllGroupsSuccess(JSON.parse(response.data.groups)));
        } else {
            yield put(getAllGroupsFailed(response.status));
            toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getAllGroupsFailed(error));
    }
}

function* createAGroup({ payload: group }) {
     try {
            const response = yield call(addNewGroup, '/api/v1/group', {...group});
            if (response.status === 200) {
                yield put(getAllGroups({page: 1, pageSize: 20}))
                yield put(createGroupSuccess())
                toastr.success(response.data.message, "Success")
            } else {
                yield put(createGroupFailed(response.status));
                toastr.error(response.data.error,"Error")
            }
    } catch (error) {
        yield put(createGroupFailed(error));
    }
}

function* updateAGroup({ payload: group }) {
    try {
        const index = group.index
        const id = group.id
        delete group.id
        delete group.index
        const response = yield call(editGroup, `/api/v1/group/${id}`, {...group});
        if (response.status === 200) {
            const newGroups = yield select(getStoreGroups)
            newGroups.splice(index, 1, JSON.parse(response.data.group))
            yield put(getAllGroupsSuccess(newGroups));
            yield put(updateGroupSuccess())
            toastr.success("The group has been updated!")
        } else {
            toastr.error("It seems the group name informed already exists","Error")
            yield put(updateGroupFailed(response.status));
        }
    } catch (error) {
        yield put(updateGroupFailed(error));
    }
}

function* getOneGroup({payload: id}) {
    try {
        const response = yield call(getAGroupList, `/api/v1/group/${id}`);
        if(response.status === 200) {
            const students = JSON.parse(response.data.students)
            const teachers = JSON.parse(response.data.teachers)
            const groupList = {students: students, teachers: teachers}
            yield put(getOneGroupSuccess(groupList));
        } else {
            yield put(getOneGroupFailed(response.status));
            toastr.info("Can not find the members of this group","Info")
        }

    } catch (error) {
        yield put(getOneGroupFailed(error));
        toastr.error("Something went wrong","Error")
    }
}


export function* watchGetGroups() {
    yield takeEvery(GET_ALL_GROUPS, getGroups)
}

export function* watchCreateGroup() {
    yield takeEvery(CREATE_GROUP, createAGroup)
}

export function* watchUpdateGroup() {
    yield takeEvery(UPDATE_GROUP, updateAGroup)
}

export function* watchGetAGroup() {
    yield takeEvery(GET_ONE_GROUP, getOneGroup)
}


function* groupSaga() {
    yield all([
        fork(watchGetGroups),
        fork(watchCreateGroup),
        fork(watchUpdateGroup),
        fork(watchGetAGroup)
    ]);
}

export default groupSaga;