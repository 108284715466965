import axios from 'axios';

const getNotifications = () => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: '/api/v1/notifications',
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        }
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const inactiveNotification = (data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'post',
        url: '/api/v1/revert_notification',
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

export { getNotifications, inactiveNotification }