import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_WORKBOOK, GET_ACTIVITY, GET_GROUPS } from './actionTypes';
import {getAllWorkbookSuccess, getAllWorkbookFailed, getAllActivitySuccess, getGroupListSuccess} from './actions';
import { getAllActivities } from '../../../apiHelpers/activityApiHelper';
import toastr from 'toastr';
import { getStoreGroups, getStoreTotal } from './selectors';

function* getWorkbooks() {
    try {
        const response = yield call(getAllActivities, '/api/v1/workbook');
        if(response.status === 200) {
            yield put(getAllWorkbookSuccess(JSON.parse(response.data.workbooks)));
            yield put(getAllActivitySuccess(JSON.parse(response.data.activities)));

        } else {
            yield put(getAllWorkbookFailed(response.status));
            // toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getAllWorkbookFailed(error));
    }
}

function* getGroups() {
    try {
        const response = yield call(getAllActivities, '/api/v1/get_groups');
        if(response.status === 200) {
            yield put(getGroupListSuccess(JSON.parse(response.data.groups)));
            yield put(getAllWorkbookSuccess(JSON.parse(response.data.workbooks)));
        } else {
            // toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        toastr.error("Something went wrong","Error")
    }
}


export function* watchGetWorkbooks() {
    yield takeEvery(GET_ACTIVITY, getWorkbooks)
}

export function* watchGetGroups() {
    yield takeEvery(GET_GROUPS, getGroups)
}



function* activitySaga() {
    yield all([
        fork(watchGetWorkbooks),
        fork(watchGetGroups)
    ]);
}

export default activitySaga;