import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

const SweetAlertSuccess = (props) => {
  return (
    <SweetAlert
      success
      title={props.title}
      showConfirm={false}
      onConfirm={() => props.close()}
      style={{padding: "4.25rem 1.25rem"}}
      timeout={2000}
    >
      {props.description}
    </SweetAlert>
  )
}

export default SweetAlertSuccess;
