import {
    GET_ATTENDANCES,
    GET_ATTENDANCES_SUCCESS,
    GET_ATTENDANCES_FAILED,
    GET_STUDENTS_LIST,
    GET_STUDENTS_LIST_SUCCESS,
    CREATE_ACCIDENT_REPORT,
    CREATE_ACCIDENT_REPORT_SUCCESS,
    CREATE_ACCIDENT_REPORT_FAILED,
    GET_ACCIDENT_REPORT,
    GET_ACCIDENT_REPORT_SUCCESS,
    SET_DEFAULT,
    UPDATE_ACCIDENT_REPORT,
    UPDATE_ACCIDENT_REPORT_SUCCESS,
    UPDATE_ACCIDENT_REPORT_FAILED,
    EMAIL_SENT,
    GET_OLD_STUDENTS_LIST,
    GET_OLD_STUDENTS_LIST_SUCCESS
} from './actionTypes';
import {GET_ALL_USERS} from "../user/actionTypes";

export const getAttendances = (duration) => {
    return {
        type: GET_ATTENDANCES,
        payload: duration
    }
}

export const getAttendancesSuccess = (attendances) => {
    return {
        type: GET_ATTENDANCES_SUCCESS,
        payload: attendances
    }
}
export const getAttendancesFailed = (error) => {
    return {
        type: GET_ATTENDANCES_FAILED,
        payload: error
    }
}

export const getStudentsList = () => {
    return {
        type: GET_STUDENTS_LIST
    }
}

export const getOldStudentsList = () => {
    return {
        type: GET_OLD_STUDENTS_LIST
    }
}

export const getOldStudentsListSuccess = (students) => {
    return {
        type: GET_OLD_STUDENTS_LIST_SUCCESS,
        payload: students
    }
}

export const getStudentsListSuccess = (students) => {
    return {
        type: GET_STUDENTS_LIST_SUCCESS,
        payload: students
    }
}

export const createAccidentReport = (data) => {
    return {
        type: CREATE_ACCIDENT_REPORT,
        payload: data
    }
}

export const createAccidentReportSuccess = (report) => {
    return {
        type: CREATE_ACCIDENT_REPORT_SUCCESS,
        payload: report
    }
}

export const createAccidentReportFailed = () => {
    return {
        type: CREATE_ACCIDENT_REPORT_FAILED
    }
}

export const getAccidentReport = (page) => {
    return {
        type: GET_ACCIDENT_REPORT,
        payload: page
    }
}

export const getAccidentReportSuccess = (data, total) => {
    return {
        type: GET_ACCIDENT_REPORT_SUCCESS,
        payload: data,
        total: total
    }
}

export const setDefault = () => {
    return {
        type: SET_DEFAULT
    }
}

export const updateAccidentReport = (data) => {
    return {
        type: UPDATE_ACCIDENT_REPORT,
        payload: data
    }
}

export const updateAccidentReportSuccess = (report, index) => {
    return {
        type: UPDATE_ACCIDENT_REPORT_SUCCESS,
        payload: report,
        index: index
    }
}

export const updateAccidentReportFailed = () => {
    return {
        type: UPDATE_ACCIDENT_REPORT_FAILED
    }
}

export const emailSent = (index) => {
    return {
        type: EMAIL_SENT,
        payload: index
    }
}
