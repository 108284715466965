import axios from 'axios';
import toastr from 'toastr'
import Unauthorized from "./unauthorized";

const getAttendances = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        params : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const getAllStudents = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        }
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        if (err.response.status === 401) {
            Unauthorized()
            return
        }
        return err.response
    });
}


export { getAttendances, getAllStudents }