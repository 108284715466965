import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAILED,
    GET_TOTAL_VALUES
} from './actionTypes';

export const getDashboardData = () => {
    return {
        type: GET_DASHBOARD_DATA
    }
}

export const getDashboardDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_DATA_SUCCESS,
        payload: data
    }
}

export const getDashboardDataFailed = (error) => {
    return {
        type: GET_DASHBOARD_DATA_FAILED,
        payload: error
    }
}

export const getTotalResult = (values) => {
    return {
        type: GET_TOTAL_VALUES,
        payload: values
    }
}