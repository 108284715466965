import axios from 'axios';
import toastr from 'toastr'
import Unauthorized from "./unauthorized";

const addNewUser = (url, data) => {
    return axios.post(url, data).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        return err.response.data
    });
}

const allUsers = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        params : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        if (err.response.status === 401) {
            Unauthorized()
            return
        }
        return err.response
    });
}

const delUser = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "delete",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        }
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        toastr.error(err.response.data.errors[0],"Error")
        throw err[1];
    });
}

const editUser = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "put",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response.data;
    }).catch(err => {
        return err.response.data
    });
}

export { addNewUser, allUsers, editUser, delUser }