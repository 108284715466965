import {
    GET_GALLERY_DATA,
    GET_GALLERY_DATA_SUCCESS,
    GET_GALLERY_DATA_FAILED,
    TOTAL_GALLERY
} from './actionTypes';

const initialState = {
    error: undefined,
    loading: false,
    gallery: undefined,
    total: undefined
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_GALLERY_DATA:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_GALLERY_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                gallery: action.payload
            }
            break;
        case TOTAL_GALLERY:
            state = {
                ...state,
                loading: false,
                total: action.payload
            }
            break;
        case GET_GALLERY_DATA_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                gallery: undefined
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;