import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_AGENDA_DATA } from './actionTypes';
import {getAgendaDataSuccess, getAgendaDataFailed} from './actions';
import { getAgendaData } from '../../../apiHelpers/agendaApiHelper';

function* getAgenda({payload}) {
    try {
        const response = yield call(getAgendaData, '/api/v1/agendas', payload);
        if(response.status === 200) {
            yield put(getAgendaDataSuccess(JSON.parse(response.data.agenda)));
        } else {
            yield put(getAgendaDataFailed(response.status));
        }

    } catch (error) {
        yield put(getAgendaDataFailed(error));
    }
}


export function* watchGetAgenda() {
    yield takeEvery(GET_AGENDA_DATA, getAgenda)
}



function* AgendaSaga() {
    yield all([
        fork(watchGetAgenda)
    ]);
}

export default AgendaSaga;