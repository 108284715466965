import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, FormGroup, Label, Form } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getAttStudents, getGroupList, createAttendance } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import toastr from "toastr";
import { DatePicker } from 'antd';




const Attendances = (props) => {


    useEffect(() => {
        props.getGroupList()
    }, [])


    const [date, setDate] = useState('')
    const [group, setGroup] = useState("Select Group")



    const options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }



    const attendances = () => {
        if (props.attendances.length !== 0) {
            return props.attendances.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{item.firstName + " " + item.lastName}</Td>
                        <Td className="attend-td">
                            <select className={"form-control"} value={item.present ? item.present : "Select"} onChange={(e) => saveAttendance(item, e.target.value)}>
                                <option disabled={true}>Select</option>
                                <option value="Présent(P)">Présent(P)</option>
                                <option value={"Absent(A)"}>Absent(A)</option>
                                <option value={"Maladie(M)"}>Maladie(M)</option>
                                <option value={"Vacances(V)"}>Vacances(V)</option>
                                <option value={"Férié(F)"}>Férié(F)</option>
                                <option value={"Demi-Jounée(DJ)"}>Demi-Jounée(DJ)</option>
                            </select>
                        </Td>
                    </Tr>
                )
            })
        }
    }

    const saveAttendance = (item, value) => {
        const params = {
            student_id: item.id,
            date: date,
            action: value,
            group_id: group
        }
        props.createAttendance(params)
    }


    const handleDate = (value, dateString) => {
        if (group === "Select Group") {
            toastr.info("Please select the group", "Info")
            setDate('')
            return false
        }
        if (value) {
            setDate(dateString)
            props.getAttStudents({ date: dateString, group_id: group })
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Attendances')} breadcrumbItem={props.t('Attendances')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={4} sm={3}>
                                            <span className="form-group">
                                                <Input
                                                    type="select"
                                                    value={"Select Group"}
                                                    name="group"
                                                    value={group}
                                                    onChange={(e) => setGroup(e.target.value)}
                                                >
                                                    <option disabled={true}>Select Group</option>
                                                    {options()}
                                                </Input>
                                            </span>
                                            <span className={"ml-2"}>
                                                <DatePicker className="form-control" onChange={handleDate} />
                                            </span>
                                        </Col>
                                    </Row>

                                    <Row className="mt-4">
                                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr>
                                                    <Th data-priority="1">No</Th>
                                                    <Th data-priority="1">Name</Th>
                                                    <Th data-priority="1">Presence</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {attendances()}
                                            </Tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { groups } = state.ActivityReducer;
    const { modal, attendances, editModal } = state.AttendanceReducer;
    return { groups, modal, editModal, attendances };
}

export default withRouter(connect(mapStateToProps, { getGroupList, getAttStudents, createAttendance })(withNamespaces()(Attendances)));
