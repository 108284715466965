import {
    GET_ACTIVITY,
    GET_WORKBOOK,
    GET_WORKBOOK_SUCCESS,
    GET_WORKBOOK_FAILED,
    GET_ACTIVITY_SUCCESS,
    GET_ACTIVITY_FAILED,
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILED
} from './actionTypes';

export const getGroupList = () => {
    return {
        type: GET_GROUPS
    }
}

export const getGroupListSuccess = (groups) => {
    return {
        type: GET_GROUPS_SUCCESS,
        payload: groups
    }
}

export const getAllWorkbooks = () => {
    return {
        type: GET_WORKBOOK
    }
}

export const getAllWorkbookSuccess = (workbooks) => {
    return {
        type: GET_WORKBOOK_SUCCESS,
        payload: workbooks
    }
}

export const getAllWorkbookFailed = (error) => {
    return {
        type: GET_WORKBOOK_FAILED,
        payload: error
    }
}

export const getAllActivities = () => {
    return {
        type: GET_ACTIVITY
    }
}


export const getAllActivitySuccess = (activities) => {
    return {
        type: GET_ACTIVITY_SUCCESS,
        payload: activities,
    }
}

export const getAllActivityFailed = (error) => {
    return {
        type: GET_ACTIVITY_FAILED,
        payload: error
    }
}




