import React, {useState, useEffect} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, Container} from 'reactstrap';

//i18n
import {withNamespaces} from 'react-i18next';
import {Link} from 'react-router-dom';

import ChangePasswordModal from "../../Modals/change_password_modal";
import SweetAlertSuccess from "../../Modals/sweet_alert_success";
import axios from "axios";


const ProfileMenu = (props) => {

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [changePwd, setChangePwd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const closeModal = (val) => {
    setChangePwd(val)
  }

  const changePassword = values => {
    setLoading(true);
    const headers = JSON.parse(localStorage.getItem("authHeaders"));
    const config = {
      method: "post",
      url: `/api/v1/manage_users/change_password`,
      headers: {
        accesstoken: headers.accesstoken,
        client: headers.client,
        uid: headers.uid,
      },
      data: values
    };
    return axios(config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        setLoading(false);
        setChangePwd(false);
        setShowSuccess(true);
        setTimeout(() => window.location.href = "/logout", 2500)
      })
      .catch((err) => {
        setLoading(false);
        throw err[1];
      });
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setusername(obj.data.name);
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
          <span className="d-xl-inline-block ml-2 mr-1">{username}</span>
          <i className="mdi mdi-chevron-down d-xl-inline-block"></i>
        </DropdownToggle>
        <DropdownMenu right>
          <Link to={"#"} onClick={() => setChangePwd(true)} className="dropdown-item">
            <i className="fas fa-lock font-size-16 align-middle mr-1"/>
            <span>Change Password</span>
          </Link>
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <ChangePasswordModal isOpen={changePwd} loading={loading} changePassword={changePassword} close={closeModal}/>
      {showSuccess && (
        <SweetAlertSuccess title={"Good job!"} description={"The password has successfully changed!"}
                           close={() => setShowSuccess(false)}/>
      )}
    </React.Fragment>
  );
}

export default withNamespaces()(ProfileMenu);
