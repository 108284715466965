import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_DASHBOARD_DATA } from './actionTypes';
import {getDashboardDataSuccess, getDashboardDataFailed, getTotalResult} from './actions';
import { getDashboardData } from '../../../apiHelpers/attendanceApiHelper';

function* getDashData() {
    try {
        const response = yield call(getDashboardData, '/api/v1/dashboard');
        if(response.status === 200) {
            yield put(getDashboardDataSuccess(JSON.parse(response.data.dashboard_data)));
            yield put(getTotalResult(JSON.parse(response.data.total)))
        } else {
            yield put(getDashboardDataFailed(response.status));
        }

    } catch (error) {
        yield put(getDashboardDataFailed(error));
    }
}


export function* watchGetDashboard() {
    yield takeEvery(GET_DASHBOARD_DATA, getDashData)
}



function* DashboardSaga() {
    yield all([
        fork(watchGetDashboard)
    ]);
}

export default DashboardSaga;