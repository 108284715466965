export const GET_REPORT_ACTIVITIES = 'GET_REPORT_ACTIVITIES';
export const GET_REPORT_ACTIVITIES_SUCCESS = 'GET_REPORT_ACTIVITIES_SUCCESS';
export const GET_REPORT_ACTIVITIES_FAILED = 'GET_REPORT_ACTIVITIES_FAILED';
export const GET_REPORT_ITEMS = 'GET_REPORT_ITEMS';
export const GET_REPORT_ITEMS_SUCCESS = 'GET_REPORT_ITEMS_SUCCESS';
export const GET_REPORT_ITEMS_FAILED = 'GET_REPORT_ITEMS_FAILED';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';


