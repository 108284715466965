import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, FormGroup, Label, Form } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getDashboardData } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import moment from "moment";





const Dashboard = (props) => {


    useEffect(() => {
        props.getDashboardData()
    }, [])


    const pannel = () => {
        if (props.dashboardData && props.dashboardData !== "nothing") {
            return props.dashboardData.map((item, index) => {
                return (
                    <Col xs={12} sm={6} md={4} className={"individual"} key={index}>
                        <Row className={"dashboard-individual"}>
                            <h5>{item.name} - Absences</h5>
                            <Col xs={7} className={"wrap-text mt-2"}>
                                {teachers(item.users)}
                                <h4><b>{item.total}</b></h4>
                                <h6>{moment().format('ll')}</h6>
                            </Col>
                            <Col xs={5} className={"wrap-icon"}>
                                <i className="fas fas fa-users awesome-icon"></i>
                            </Col>
                        </Row>
                    </Col>
                )
            })
        }
    }

    const teachers = (teachers) => {
        if(teachers) {
            return teachers.map((item, index) => {
                return (
                    <h6 key={index}>
                        {item.name}
                    </h6>
                )
            })
        }
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Dashboard')} breadcrumbItem={props.t('Dashboard')} />
                    {props.total && (
                        <Row>
                            <Col>

                                        <Row>
                                            <Col xs={12} sm={6} className={"dashboard-absence mb-2"}>
                                                <Row className={"dashboard-inner"}>
                                                    <Col xs={7} className={"wrap-text"}>
                                                        <h5>Today's absences</h5>
                                                        <h3><b>{props.total.total_absent}</b></h3>
                                                        <h5>{moment().format('ll')}</h5>
                                                    </Col>
                                                    <Col xs={5} className={"wrap-icon"}>
                                                        <i className="fas fa-user-slash awesome-icon"></i>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} sm={6} className={"dashboard-absence mb-2"}>
                                                <Row className={"dashboard-inner"}>
                                                    <Col xs={7} className={"wrap-text"}>
                                                        <h5>Today's presences</h5>
                                                        <h3><b>{props.total.total_present}</b></h3>
                                                        <h5>{moment().format('ll')}</h5>
                                                    </Col>
                                                    <Col xs={5} className={"wrap-icon"}>
                                                        <i className="fas fa-user-check awesome-icon"></i>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row className={"mt-5 dashboard-detail"}>
                                            {pannel()}
                                        </Row>

                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { dashboardData, total } = state.DashboardReducer;
    return { dashboardData, total };
}

export default withRouter(connect(mapStateToProps, { getDashboardData })(withNamespaces()(Dashboard)));
