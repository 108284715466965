import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import {
    GET_ATTENDANCES,
    GET_STUDENTS_LIST,
    CREATE_ACCIDENT_REPORT,
    GET_ACCIDENT_REPORT,
    UPDATE_ACCIDENT_REPORT,
    GET_OLD_STUDENTS_LIST
} from './actionTypes';
import {
    getAttendancesSuccess,
    getAttendancesFailed,
    getStudentsListSuccess,
    createAccidentReportFailed,
    createAccidentReportSuccess,
    getAccidentReportSuccess,
    updateAccidentReportSuccess,
    updateAccidentReportFailed,
    getOldStudentsListSuccess
} from './actions';
import { getAttendances, getAllStudents,  } from '../../../apiHelpers/reportApiHelper';
import { paramPost, paramGet, paramPut, noParamGet } from "../../../apiHelpers/commonApiHelper";
import toastr from 'toastr';

function* getAttInReport({payload}) {
    try {
        const response = yield call(getAttendances, '/api/v1/report_attendances', payload);
        if(response.status === 200 && (JSON.parse(response.data.attendances)).length > 0) {
            yield put(getAttendancesSuccess(JSON.parse(response.data.attendances)));
        } else {
            toastr.info("You do not have any student associated with this email", "info")
            yield put(getAttendancesFailed(response.status));
        }

    } catch (error) {
        yield put(getAttendancesFailed(error));
    }
}

function* getOldStudents() {
    try {
        const response = yield call(noParamGet, '/api/v1/old_students_list');
        if(response.status === 200) {
            yield put(getOldStudentsListSuccess(JSON.parse(response.data.students)));
        } else {
            toastr.info("There is no deleted children", "Info")
        }
    } catch (error) {
        toastr.warning("Something went wrong!", "Warning")
    }
}

function* getStudents() {
    try {
        const response = yield call(getAllStudents, '/api/v1/students_list');
        if(response.status === 200) {
            yield put(getStudentsListSuccess(JSON.parse(response.data.students)));
        } else {
            toastr.info("There is no registered children", "Info")
        }
    } catch (error) {
        toastr.warning("Something went wrong!", "Warning")
    }
}

function* createAccReport({payload}) {
    try {
        const response = yield call(paramPost, '/api/v1/accident_reports', payload);
        if(response.status === 201) {
            yield put(createAccidentReportSuccess(JSON.parse(response.data.report)));
            toastr.success(response.data.message, "Success");
        } else {
            yield put(createAccidentReportFailed())
            toastr.info(response.data.error, "Info");
        }
    } catch (error) {
        console.log("Error ======", error)
        yield put(createAccidentReportFailed())
        toastr.warning("Something went wrong!", "Warning")
    }
}

function* getAllAccReports({ payload: { page, pageSize } }) {
    try {
        const response = yield call(paramGet, '/api/v1/accident_reports', { page, pageSize });
        if (response.status === 200) {
            yield put(getAccidentReportSuccess(JSON.parse(response.data.report), response.data.total));
        }
        if (response.status === 404) {
            yield put(getAccidentReportSuccess([], response.data.total));
        }
    } catch (error) {
        // yield put(userApiError(error));
    }
}

function* updateAccReport({payload}) {
    try {
        const response = yield call(paramPut, `/api/v1/accident_reports/${payload.id}`, payload);
        console.log("Put result ======", response);
        if(response.status === 200) {
            yield put(updateAccidentReportSuccess(JSON.parse(response.data.report), payload.index));
            toastr.success(response.data.message, "Success");
        } else {
            yield put(updateAccidentReportFailed())
            toastr.info(response.data.error, "Info");
        }
    } catch (error) {
        console.log("Error ======", error)
        yield put(updateAccidentReportFailed())
        toastr.warning("Something went wrong!", "Warning")
    }
}

export function* watchGetAttendances() {
    yield takeEvery(GET_ATTENDANCES, getAttInReport)
}

export function* watchGetStudents() {
    yield takeEvery(GET_STUDENTS_LIST, getStudents)
}

export function* watchCreateAccReport() {
    yield takeEvery(CREATE_ACCIDENT_REPORT, createAccReport)
}

export function* watchGetReportList() {
    yield takeEvery(GET_ACCIDENT_REPORT, getAllAccReports)
}

export function* watchUpdateAccReport() {
    yield takeEvery(UPDATE_ACCIDENT_REPORT, updateAccReport)
}

export function* watchGetOldStudents() {
    yield takeEvery(GET_OLD_STUDENTS_LIST, getOldStudents)
}

function* attendanceSaga() {
    yield all([
        fork(watchGetAttendances),
        fork(watchGetStudents),
        fork(watchCreateAccReport),
        fork(watchGetReportList),
        fork(watchUpdateAccReport),
        fork(watchGetOldStudents)
    ]);
}

export default attendanceSaga;