import {
    GET_REPORT_ACTIVITY_LIST,
    GET_REPORT_ACTIVITY_LIST_SUCCESS,
    GET_REPORT_ACTIVITY_LIST_FAILED
} from './actionTypes';

export const getReportActivityLists = () => {
    return {
        type: GET_REPORT_ACTIVITY_LIST,
    }
}

export const getReportActivityListSuccess = (lists) => {
    return {
        type: GET_REPORT_ACTIVITY_LIST_SUCCESS,
        payload: lists
    }
}

export const getReportActivityListFailed = (error) => {
    return {
        type: GET_REPORT_ACTIVITY_LIST_FAILED,
        payload: error
    }
}



