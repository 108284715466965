export const GET_ATTENDANCES = 'GET_ATTENDANCES';
export const GET_ATTENDANCES_SUCCESS = 'GET_ATTENDANCES_SUCCESS';
export const GET_ATTENDANCES_FAILED = 'GET_ATTENDANCES_FAILED';
export const GET_STUDENTS_LIST = 'GET_STUDENTS_LIST';
export const GET_STUDENTS_LIST_SUCCESS = 'GET_STUDENTS_LIST_SUCCESS';
export const CREATE_ACCIDENT_REPORT = 'CREATE_ACCIDENT_REPORT';
export const CREATE_ACCIDENT_REPORT_SUCCESS = 'CREATE_ACCIDENT_REPORT_SUCCESS';
export const CREATE_ACCIDENT_REPORT_FAILED = 'CREATE_ACCIDENT_REPORT_FAILED';
export const GET_ACCIDENT_REPORT = 'GET_ACCIDENT_REPORT';
export const GET_ACCIDENT_REPORT_SUCCESS = 'GET_ACCIDENT_REPORT_SUCCESS';
export const SET_DEFAULT = 'SET_DEFAULT';
export const UPDATE_ACCIDENT_REPORT = "UPDATE_ACCIDENT_REPORT";
export const UPDATE_ACCIDENT_REPORT_SUCCESS = "UPDATE_ACCIDENT_REPORT_SUCCESS";
export const UPDATE_ACCIDENT_REPORT_FAILED = "UPDATE_ACCIDENT_REPORT_FAILED";
export const EMAIL_SENT = "EMAIL_SENT";
export const GET_OLD_STUDENTS_LIST = "GET_OLD_STUDENTS_LIST";
export const GET_OLD_STUDENTS_LIST_SUCCESS = "GET_OLD_STUDENTS_LIST_SUCCESS";

