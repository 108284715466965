import {
    GET_ALL_GROUPS,
    GET_ALL_GROUPS_SUCCESS,
    GET_ALL_GROUPS_FAILED,
    CREATE_GROUP,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILED,
    UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILED,
    ALL_GROUPS,
    GET_TEACHERS,
    GET_STUDENTS,
    GET_ONE_GROUP,
    GET_ONE_GROUP_SUCCESS,
    GET_ONE_GROUP_FAILED
} from './actionTypes';

export const getOneGroup = (id) => {
    return {
        type: GET_ONE_GROUP,
        payload: id
    }
}

export const getOneGroupSuccess = (oneGroup) => {
    return {
        type: GET_ONE_GROUP_SUCCESS,
        payload: oneGroup
    }
}
export const getOneGroupFailed = (error) => {
    return {
        type: GET_ONE_GROUP_FAILED,
        payload: error
    }
}

export const getAllGroups= (page) => {
    return {
        type: GET_ALL_GROUPS,
        payload: page
    }
}

export const getAllGroupsSuccess = (groups) => {
    return {
        type: GET_ALL_GROUPS_SUCCESS,
        payload: groups,
    }
}

export const getTeachers = (teachers) => {
    return {
        type: GET_TEACHERS,
        payload: teachers
    }
}

export const getStudents = (students) => {
    return {
        type: GET_STUDENTS,
        payload: students
    }
}

export const getAllGroupsFailed = (error) => {
    return {
        type: GET_ALL_GROUPS_FAILED,
        payload: error,
    }
}

export const totalGroups = (total) => {
    return {
        type: ALL_GROUPS,
        payload: total
    }
}

export const createGroup = (group) => {
    return {
        type: CREATE_GROUP,
        payload: group
    }
}

export const createGroupSuccess = () => {
    return {
        type: CREATE_GROUP_SUCCESS,
        payload: {}
    }
}

export const createGroupFailed = (error) => {
    return {
        type: CREATE_GROUP_FAILED,
        payload: error
    }
}

export const updateGroup = (GROUP) => {
    return {
        type: UPDATE_GROUP,
        payload: GROUP
    }
}

export const updateGroupSuccess = () => {
    return {
        type: UPDATE_GROUP_SUCCESS,
        payload: {}
    }
}

export const updateGroupFailed = (error) => {
    return {
        type: UPDATE_GROUP_FAILED,
        payload: error
    }
}





