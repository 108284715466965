import axios from 'axios';

const getAgendaData = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        params : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const checkGroup = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        params : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const submitEmoji = (data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: "/api/v1/agendas/emoji",
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {emojis: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}


const submitPoop = (data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: "/api/v1/agendas/poop",
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {poop: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}


const submitRates = (data, url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {rates: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const submitNapTime = (data, url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {checkStatus: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const releaseReport = (data, url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {release: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const saveTheme = (data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: "/api/v1/agendas/theme",
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {theme: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}


const submitMilkInfo = (data, url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {milk: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}


export { getAgendaData, checkGroup, submitEmoji, submitRates, submitNapTime, releaseReport, saveTheme, submitPoop, submitMilkInfo }