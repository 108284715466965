import {
    GET_ALL_GROUPS,
    GET_ALL_GROUPS_SUCCESS,
    GET_ALL_GROUPS_FAILED,
    CREATE_GROUP,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILED,
    UPDATE_GROUP,
    UPDATE_GROUP_SUCCESS,
    UPDATE_GROUP_FAILED,
    ALL_GROUPS,
    GET_TEACHERS,
    GET_STUDENTS,
    GET_ONE_GROUP,
    GET_ONE_GROUP_SUCCESS,
    GET_ONE_GROUP_FAILED
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    groups: [],
    groupCount: undefined,
    modal: undefined,
    editModal: undefined,
    students: [],
    teachers: [],
    oneGroup: undefined
}

const group = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_GROUP:
            state = {
                ...state,
                loading: true,
                modal: "start"
            }
            break;
        case CREATE_GROUP_SUCCESS:
            state = {
                ...state,
                loading: false,
                modal: "success"
            }
            break;
        case CREATE_GROUP_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                modal: "failed"
            };
            break;
        case GET_ALL_GROUPS:
            state = {
                ...state,
                loading: true
            };
            break;
        case GET_ALL_GROUPS_SUCCESS:
            state = {
                ...state,
                loading: false,
                groups: action.payload
            };
            break;
        case GET_TEACHERS:
            state = {
                ...state,
                teachers: action.payload
            }
        case GET_STUDENTS:
            state = {
                ...state,
                students: action.payload
            }
        case GET_ALL_GROUPS_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            };
            break;
        case ALL_GROUPS:
            state = {
                ...state,
                loading: false,
                groupCount: action.payload,
            };
            break;
        case UPDATE_GROUP:
            state = {
                ...state,
                loading: true,
                editModal: "start"
            };
            break;
        case UPDATE_GROUP_SUCCESS:
            state = {
                ...state,
                loading: false,
                editModal: "success"
            };
            break;
        case UPDATE_GROUP_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                editModal: "failed"
            };
            break;
        case GET_ONE_GROUP:
            state = {
                ...state,
                loading: true,
            }
            break;
        case GET_ONE_GROUP_SUCCESS:
            state = {
                ...state,
                loading: false,
                oneGroup: action.payload
            }
            break;
        case GET_ONE_GROUP_FAILED:
            state = {
                ...state,
                loading: false,
                oneGroup: undefined
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default group;