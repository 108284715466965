import React, { useState, useEffect, Fragment } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {DatePicker, Pagination, TimePicker, Radio, Tooltip} from "antd";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { connect } from "react-redux";
import moment from "moment";
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import toastr from "toastr";
import axios from "axios";
import {callStudents} from "../../apiHelpers/activityApiHelper";
import {
    getGroupList,
    createAccidentReport,
    getAccidentReport,
    setDefault,
    updateAccidentReport,
    emailSent
} from '../../store/actions';

const radioOptions = [
    { label: 'Oui / Yes', value: 1 },
    { label: 'Non / No', value: 2 }
];

const accidentReport = (props) => {
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");

    const [confirm_both_email, setconfirm_both_email] = useState(false);
    const [success_dlg_email, setsuccess_dlg_email] = useState(false);
    const [error_dlg_email, seterror_dlg_email] = useState(false);

    const [modal, setmodal] = useState(false);
    const [current, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const [delData, setDelData] = useState(null);

    const [students, setStudents] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState("Select Student")
    const [studentName, setStudentName] = useState(null);
    const [accidentDate, setAccidentDate] = useState(null);
    const [accidentTime, setAccidentTime] = useState(null);
    const [aid, setAid] = useState(0);
    const [loggedUser, setLoggedUser] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [editStdInfo, setEditStdInfo] = useState(null);
    const [editIndex, setEditIndex] = useState(null);
    const [emailChild, setEmailChild] = useState(null);
    const [sendingEmailIndex, setSendingEmailIndex] = useState(null);
    const [printing, setPrinting] = useState(false);
    const [printingId, setPrintingId] = useState(null);
    const [userRole, setUserRole] = useState(null);

    useEffect(() => {
        props.getAccidentReport({ page: 1, pageSize: pageSize });
        props.getGroupList();
        if(localStorage.getItem('authUser')) {
            setLoggedUser(JSON.parse(localStorage.getItem('authUser')).data.name);
            setUserRole(JSON.parse(localStorage.getItem('authUser')).data.role);
        }
    }, []);

    useEffect(() => {
        if(props.accReportCreating === 1) {
            clear();
            props.setDefault();
        }
    }, [props.accReportCreating])

    useEffect(() => {
        if(props.accReportUpdating === 1) {
            clear();
            props.setDefault();
        }
    }, [props.accReportUpdating])

    const onChange = (page) => {
        props.getAccidentReport({ page: page, pageSize: pageSize });
        setCurrentPage(page);
    };

    function onShowSizeChange(current, pageSize) {
        props.getAccidentReport({ page: current, pageSize: pageSize });
        setPageSize(pageSize);
        setCurrentPage(current);
    }

    const postDelete = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"));
        const config = {
            method: "delete",
            url: `/api/v1/accident_reports/${delData.id}`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            },
        };
        return axios(config)
            .then((response) => {
                if (response.status === 400 || response.status === 500)
                    throw response.data;
                setsuccess_dlg(true);
                // setdynamic_title({props.t("Deleted")});
                setdynamic_title("Deleted");
                setdynamic_description("Accident report has been deleted.");
            })
            .catch((err) => {
                seterror_dlg(true);
                throw err[1];
            });
    };

    const sendEmail = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"));
        const config = {
            method: "post",
            url: `/api/v1/accident_reports/send_email`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            },
            data: {id: emailChild.id}
        };
        return axios(config)
            .then((response) => {
                console.log("response", response);
                if (response.status >= 400 && response.status < 600) {
                    seterror_dlg_email(true);
                    throw response.data;
                }
                props.emailSent(sendingEmailIndex);
                setsuccess_dlg_email(true);
                setdynamic_description("The email has been sent!");
            })
            .catch((err) => {
                setdynamic_description(err.response.data.message);
                seterror_dlg_email(true);
                throw err[1];
            });
    };

    const printPdf = (item) => {
        setPrinting(true);
        setPrintingId(item.id);
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: 'post',
            url: `/api/v1/accident_reports/generate_pdf`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            },
            responseType: 'blob',
            data: {id: item.id}
        }
        axios
            .request(config)
            .then(response => {
                setPrinting(false);
                setPrintingId(null);
                const blob = URL.createObjectURL(response.data);
                window.open(blob, '_blank');
            })
            .catch((err) => {
                setPrinting(false);
                setPrintingId(null)
                toastr.error("Something went wrong!", "Error")
            });
    }


    // Accident Report
    const handleSaveReport = (e, v) => {
        e.persist();
        if(!studentName) {
            toastr.warning("Please select a child");
            return false;
        }
        if(!accidentDate) {
            toastr.warning("Please select the accident date");
            return false;
        }
        if(v.severity === 1) {
            toastr.warning("Please select the severity of injury")
            return false;
        }
        if(aid === 0) {
            toastr.warning("Please answer if the first aid is given or not");
            return false;
        }
        if(aid === 1 && !v.aidDesc) {
            toastr.warning("Please write the description of first aid.");
            return false;
        }
        if(!accidentTime) {
            toastr.warning("Please select the accident time");
            return false;
        }
        v.studentId = selectedStudent;
        v.accDate = accidentDate;
        v.accTime = accidentTime;
        v.aid = aid;
        v.educator = loggedUser;
        v.director = "Althea Scott";
        if(v.aidDesc === " ") {
            v.aidDesc = ''
        }
        if(!isEdit) {
            props.createAccidentReport(v);
            console.log("Creating =====", v);
        } else {
            v.id = editStdInfo.id;
            v.index = editIndex;
            props.updateAccidentReport(v);
            console.log("Editing =====", v);
        }
    }

    const clear = () => {
        setmodal(!modal);
        setIsEdit(false);
        setSelectedStudent("Select Student");
        setStudentName(null);
        setAccidentDate(null);
        setAccidentTime(null);
        setEditStdInfo(null);
        setEditIndex(null);
        setAid(0);
    }

    const options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    const getStudent = (e) => {
        setStudents([])
        callStudents({ group_id: e.target.value })
            .then((result) => {
                if (result.status === 200) {
                    const studentData = JSON.parse(result.data.students);
                    setStudents(studentData)
                    setSelectedStudent(studentData[0].id);
                    setStudentName(studentData[0].firstName + studentData[0].lastName);
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!", "Error")
            })
    }

    const activityOptions = () => {
        if (students.length > 0) {
            return students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const handleDate = (value, dateString) => {
        if(value) {
            setAccidentDate(dateString)
        } else {
            setAccidentDate(null)
        }
    }

    const setAidStatus = (event) => {
        let val = event.target.value;
        setAid(val);
    }

    const handleAccTime = (time, timeString) => {
        setAccidentTime(timeString);
    }

    const handleEdit = (item, index) => {
        setIsEdit(true);
        setStudentName(item.student.firstName + " " + item.student.lastName);
        setSelectedStudent(item.student_id);
        setEditStdInfo(item);
        setAid(item.aid_status);
        setAccidentDate(item.accident_date);
        setAccidentTime(item.accident_time);
        setEditIndex(index);
        setmodal(!modal);
    }

    const handleDel = (val) => {
        setDelData(val);
        setconfirm_both(true);
    };

    const handleSendEmail = (item, index) => {
        if(!item.is_email_sent) {
            setEmailChild(item);
            setSendingEmailIndex(index);
            setconfirm_both_email(true);
        }
    }

    const reportList = () => {
        if(props.accReportList) {
            return props.accReportList.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{item.student.firstName + " " + item.student.lastName}</Td>
                        <Td>{item.contact_name}</Td>
                        <Td>{item.contact_email}</Td>
                        <Td>{item.contact_phone}</Td>
                        <Td>{moment(item.accident_date).format("YYYY-MM-DD")}</Td>
                        <Td>{item.severity === 2 ? "Superficial" : "Serious"}</Td>
                        <Td>{item.educator}</Td>
                        {userRole !== "Parent" && (
                            <Td>
                                {item.is_email_sent ?
                                    <i className="fas fa-check email_status text-success"/> :
                                    <i className="fas fa-times email_status color-red"/>
                                }
                            </Td>
                        )}
                        {userRole !== "Parent" ? (
                            <Td>
                                <Tooltip placement="top" title={"Edit"}>
                                    <i
                                        className="fas fa-pencil-alt actions"
                                        style={{cursor: "pointer"}}
                                        onClick={() => handleEdit(item, index)}
                                    />
                                </Tooltip>
                                <Tooltip placement="top" title={"Send Email"}>
                                    <i
                                        className={`${item.is_email_sent ? 'grey' : ''} fas fa-paper-plane actions`}
                                        style={{cursor: "pointer"}}
                                        onClick={() => handleSendEmail(item, index)}
                                    />
                                </Tooltip>
                                {!printing ? (
                                    <Tooltip placement="top" title={"Print PDF"}>
                                        <i
                                            className="fas fa-print actions"
                                            style={{cursor: "pointer"}}
                                            onClick={() => printPdf(item)}
                                        />
                                    </Tooltip>
                                ) : (
                                    printingId === item.id && (
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                    )
                                )}
                                <Tooltip placement="top" title={"Delete"}>
                                    <i
                                        className="fas fa-trash-alt actions"
                                        style={{cursor: "pointer"}}
                                        onClick={() => handleDel(item)}
                                    />
                                </Tooltip>
                            </Td>
                        ) : (
                            <Td>
                                {!printing ? (
                                    <Tooltip placement="top" title={"Print PDF"}>
                                        <i
                                            className="fas fa-print actions"
                                            style={{cursor: "pointer"}}
                                            onClick={() => printPdf(item)}
                                        />
                                    </Tooltip>
                                ) : (
                                    printingId === item.id && (
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                                    )
                                )}
                            </Td>
                        )}

                    </Tr>
                )
            })
        }
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title={props.t("Accident Report")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    {userRole !== "Parent" && (
                                        <Button
                                            type="button"
                                            color="info"
                                            className="mb-3"
                                            onClick={() => {
                                                setmodal(!modal);
                                            }}
                                        >
                                            <span style={{ fontSize: "15px" }}>
                                              {props.t("New Accident Report")}
                                            </span>
                                        </Button>
                                    )}
                                    <div className="table-rep-plugin">
                                        <div
                                            className="table-responsive mb-0"
                                            data-pattern="priority-columns"
                                        >
                                            <Table
                                                id="tech-companies-1"
                                                className="table table-striped table-bordered"
                                            >
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="1">#</Th>
                                                        <Th data-priority="1">{props.t("Student Name")}</Th>
                                                        <Th data-priority="1">{props.t("Contact Name")}</Th>
                                                        <Th data-priority="1">{props.t("Contact E-mail")}</Th>
                                                        <Th data-priority="1">{props.t("Contact Phone")}</Th>
                                                        <Th data-priority="1">{props.t("Report Date")}</Th>
                                                        <Th data-priority="1">{props.t("Severity")}</Th>
                                                        <Th data-priority="1">{props.t("Updated by")}</Th>
                                                        {userRole !== "Parent" &&
                                                            <Th data-priority="1">{props.t("E-mail sent?")}</Th>
                                                        }
                                                        <Th data-priority="1">{props.t("Actions")}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {reportList()}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <Pagination
                                        className="float-right"
                                        current={current}
                                        defaultPageSize={20}
                                        onShowSizeChange={onShowSizeChange}
                                        onChange={onChange}
                                        total={props.accTotal}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={modal}
                        role="dialog"
                        autoFocus={true}
                        centered={false}
                        className="accident-report-modal"
                        tabIndex="-1"
                        backdrop={"static"}
                        size={'lg'}
                        toggle={() => clear()}
                    >
                        <div className="modal-content">
                            <ModalHeader
                                toggle={() => clear()}
                            >
                                {isEdit ? props.t("Edit Accident Report") :props.t("New Accident Report")}
                            </ModalHeader>
                            <ModalBody>
                                {!isEdit && (
                                    <Row>
                                        <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} >
                                            <Col xs={12} sm={6} md={6}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={"Select Group"}
                                                        name="group"
                                                        validate={{ required: { value: true } }}
                                                        onChange={getStudent}
                                                    >
                                                        <option disabled={true}>Select Group</option>
                                                        {options()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={6}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        // value={"Select Student"}
                                                        value={selectedStudent}
                                                        name="student"
                                                        validate={{ required: { value: true } }}
                                                        onChange={(e) => {
                                                            let index = e.nativeEvent.target.selectedIndex;
                                                            let text = e.nativeEvent.target[index].text;
                                                            setStudentName(text);
                                                            setSelectedStudent(e.target.value)
                                                        }}
                                                    >
                                                        <option disabled={true}>Select Student</option>
                                                        {activityOptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                        </AvForm>
                                    </Row>
                                )}
                                <AvForm className="outer-repeater mt-4" style={{ width: "100%" }} onValidSubmit={(e, v) => { handleSaveReport(e, v) }}>
                                    <Row className="mb-3 width-100">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold">
                                            Nome de l'enfant / Name of child: <span className="color-red">*</span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            {studentName && studentName}
                                        </Col>
                                    </Row>
                                    <Row className="width-100">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold pt-2">
                                            Date de l'accident / Date of accident: <span className="color-red">*</span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            {isEdit ? (
                                                <DatePicker className="form-control form-group" value={moment(accidentDate, "YYYY-MM-DD")}  onChange={handleDate} />
                                            ) : (
                                                <DatePicker className="form-control form-group" onChange={handleDate} />
                                            )}

                                        </Col>
                                    </Row>
                                    <Row className="width-100">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold pt-2">
                                            Gravité de la blessure / Severity of the injury: <span className="color-red">*</span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            <AvField
                                                type="select"
                                                value={isEdit ? editStdInfo && editStdInfo.severity :1}
                                                name="severity"
                                                validate={{ required: { value: true } }}
                                                required
                                            >
                                                <option disabled={true} value={1}>Select</option>
                                                <option value={2}>Superficial</option>
                                                <option value={3}>Serious</option>
                                            </AvField>
                                        </Col>
                                    </Row>
                                    <Row className="width-100 mb-3">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold d-flex align-items-center">
                                            <span>Description bréve de l'accident / Brief descreiption of accident : <span className="color-red">*</span></span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            <AvInput type="textarea" name="accDesc" value={isEdit && editStdInfo && editStdInfo.accident_desc} className="height-unset" rows={5} required />
                                        </Col>
                                    </Row>
                                    <Row className="width-100 mb-3">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold d-flex align-items-center">
                                            <span>Description de la blessure / Description of the injury : <span className="color-red">*</span></span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            <AvInput type="textarea" name="injuryDesc" value={isEdit && editStdInfo && editStdInfo.injury_desc} className="height-unset" rows={5} required />
                                        </Col>
                                    </Row>
                                    <Row className="width-100 mb-3">
                                        <Col xs={12} sm={12} md={6} className={`font-weight-bold pt-2`}>
                                            Les prémieres soins est-ils été donnés? / Was first aid given? : <span className="color-red">*</span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="pt-2">
                                            <Radio.Group options={radioOptions} onChange={(e) => { setAidStatus(e)}} value={aid} />
                                        </Col>
                                    </Row>
                                    <Row className="width-100 mb-3">
                                        <Col xs={12} sm={12} md={6} className={`${aid !== 1 ? "grey" : ""} font-weight-bold d-flex align-items-center`}>
                                            Si oui, faire une description / If yes, describe :
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            {aid === 2 ? (
                                                    <AvInput type="textarea" name="aidDesc" value={' '} disabled={aid !== 1} className="height-unset" rows={5} />
                                                )
                                             : (
                                                    <AvInput type="textarea" name="aidDesc" value={isEdit ? editStdInfo.aid_desc : ""} disabled={aid !== 1} className="height-unset" rows={5} />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="width-100">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold pt-2">
                                            Heure de l'accident / Time of accident: <span className="color-red">*</span>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            {isEdit ? (
                                                <TimePicker
                                                    use12Hours
                                                    format="h:mm A"
                                                    onChange={handleAccTime}
                                                    value={isEdit && moment(accidentTime, "h:mm A")}
                                                    className="form-control form-group"
                                                />
                                            ) : (
                                                <TimePicker
                                                    use12Hours
                                                    format="h:mm A"
                                                    onChange={handleAccTime}
                                                    className="form-control form-group"
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 width-100">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold">
                                            Éducatrice(eur) / Educator :
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            {loggedUser && loggedUser}
                                        </Col>
                                    </Row>
                                    <Row className="mb-3 width-100">
                                        <Col xs={12} sm={12} md={6} className="font-weight-bold">
                                            Personne en charge / Person in charge :
                                        </Col>
                                        <Col xs={12} sm={12} md={6} className="">
                                            Althea Scott
                                        </Col>
                                    </Row>
                                    <FormGroup className="mb-0">
                                        <div style={{padding: "12px"}}>
                                            {!isEdit ? (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-1"
                                                    disabled={props.accReportCreating === 0}
                                                >
                                                    {props.accReportCreating === 0 ? (
                                                        <React.Fragment>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            Saving
                                                        </React.Fragment>
                                                    ) : "Save"}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="submit"
                                                    color="primary"
                                                    className="mr-1"
                                                    disabled={props.accReportUpdating === 0}
                                                >
                                                    {props.accReportUpdating === 0 ? (
                                                        <React.Fragment>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            Updating
                                                        </React.Fragment>
                                                    ) : "Update"}
                                                </Button>
                                            )}
                                        </div>
                                    </FormGroup>
                                </AvForm>
                            </ModalBody>
                        </div>
                    </Modal>

                    {confirm_both ? (
                        <SweetAlert
                            title={props.t("Are you sure?")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both(false);
                                postDelete();
                            }}
                            onCancel={() => {
                                setconfirm_both(false);
                                setsuccess_dlg(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }}
                        >
                            {props.t("You won't be able to revert this!")}
                        </SweetAlert>
                    ) : null}

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={props.t(dynamic_title)}
                            onConfirm={() => {
                                props.getAccidentReport({ page: current, pageSize: pageSize });
                                setsuccess_dlg(false);
                                setDelData(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg ? (
                        <SweetAlert
                            error
                            title={props.t(dynamic_title)}
                            onConfirm={() => {
                                seterror_dlg(false);
                                setDelData(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {/* sweat alert for email sending*/}

                    {confirm_both_email ? (
                        <SweetAlert
                            title={props.t("Are you sure?")}
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both_email(false);
                                sendEmail();
                            }}
                            onCancel={() => {
                                setconfirm_both_email(false);
                                setsuccess_dlg_email(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }}
                        >
                            {props.t(`A message will be send to ${emailChild.contact_email} and ${emailChild.student.fatherEmail}`)}
                        </SweetAlert>
                    ) : null}

                    {success_dlg_email ? (
                        <SweetAlert
                            success
                            title={props.t(dynamic_title)}
                            onConfirm={() => {
                                setsuccess_dlg_email(false);
                                setSendingEmailIndex(null);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg_email ? (
                        <SweetAlert
                            error
                            title={props.t(dynamic_title)}
                            onConfirm={() => {
                                seterror_dlg_email(false);
                            }}
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { groups } = state.ActivityReducer;
    const { accReportList, accTotal, accReportCreating, accReportUpdating } = state.ReportAttReducer
    return { accReportList, groups, accTotal, accReportCreating, accReportUpdating };
};

export default withRouter(
    connect(mapStatetoProps, {
        getGroupList, createAccidentReport, getAccidentReport, setDefault, updateAccidentReport, emailSent
    })(withNamespaces()(accidentReport))
);
