import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, FormGroup, Label } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getOneGroup } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import axios from "axios";




const GroupDetail = (props) => {


    //sweet alert settings
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [delData, setDelData] = useState(undefined)






    useEffect(() => {
        const groupId = props.match.params.id
        props.getOneGroup(groupId)
    }, [])



    const postDelete = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: "delete",
            url: `/api/v1/delete_from_group`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            },
            params: delData
        }
        return axios(config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
            setsuccess_dlg(true);
            setdynamic_title("Deleted");
            setdynamic_description("User has been deleted from group.");
        }).catch(err => {
            seterror_dlg(true)
            throw err[1];
        });
    }

    const handleDel = (val) => {
        setDelData(val)
        setconfirm_both(true)
    }


    const teachers = () => {
        if(props.oneGroup && props.oneGroup.teachers) {
            return props.oneGroup.teachers.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Td>
                            {index + 1}
                        </Td>
                        <Td>
                            {item.name}
                        </Td>
                        <Td>
                            <i className="fas fa-trash-alt actions" onClick={() => handleDel({id: item.id, type: "teacher"})}></i>
                        </Td>

                    </Tr>
                )
            })
        }
    }

    const students = () => {
        if(props.oneGroup && props.oneGroup.students) {
            return props.oneGroup.students.map((item, index) => {
                return (
                    <Tr key={index}>
                        <Td>
                            {index + 1}
                        </Td>
                        <Td>
                            {item.firstName + " " + item.lastName}
                        </Td>
                        <Td>
                            <i className="fas fa-trash-alt actions" onClick={() => handleDel({ id: item.id, type: "student"})}></i>
                        </Td>

                    </Tr>
                )
            })
        }
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Group Detail')} breadcrumbItem={props.t('Group')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={6} className="p-3">
                                            <h5 className="text-center"><strong>Teachers</strong></h5>
                                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="1">No</Th>
                                                        <Th data-priority="1">Name</Th>
                                                        <Th data-priority="1">Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {teachers()}
                                                </Tbody>
                                            </Table>
                                        </Col>
                                        <Col xs={6} className="p-3">
                                            <h5 className="text-center"><strong>Children</strong></h5>
                                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                <Thead>
                                                    <Tr>
                                                        <Th data-priority="1">No</Th>
                                                        <Th data-priority="1">Name</Th>
                                                        <Th data-priority="1">Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {students()}
                                                </Tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {confirm_both ? (
                        <SweetAlert
                            title="Are you sure?"
                            warning
                            showCancel
                            confirmBtnBsStyle="success"
                            cancelBtnBsStyle="danger"
                            onConfirm={() => {
                                setconfirm_both(false);
                                postDelete()

                            }
                            }
                            onCancel={() =>
                            {
                                setconfirm_both(false);
                                setsuccess_dlg(false);
                                setdynamic_title("Cancelled");
                                setdynamic_description("Your imaginary file is safe :)");
                            }
                            }
                        >
                            You won't be able to revert this!
                        </SweetAlert>
                    ) : null}

                    {success_dlg ? (
                        <SweetAlert
                            success
                            title={dynamic_title}
                            onConfirm={() => {
                                const groupId = props.match.params.id
                                props.getOneGroup(groupId)
                                setsuccess_dlg(false);
                                setDelData(null);
                            } }
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}

                    {error_dlg ? (
                        <SweetAlert
                            error
                            title={dynamic_title}
                            onConfirm={() => {
                                seterror_dlg(false);
                                setDelData(null);
                            } }
                        >
                            {dynamic_description}
                        </SweetAlert>
                    ) : null}
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { loading, error, oneGroup } = state.GroupReducer;
    return { loading, error, oneGroup};
}

export default withRouter(connect(mapStateToProps, { getOneGroup })(withNamespaces()(GroupDetail)));
