import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_GALLERY_DATA } from './actionTypes';
import {getGallerySuccess, getGalleryFailed, getTotalGallery} from './actions';
import { getGalleryData } from '../../../apiHelpers/photoGalleryApiHelper';
import toastr from 'toastr'

function* getGallery({payload}) {
    try {
        const response = yield call(getGalleryData, '/api/v1/image_gallery', payload);
        if(response.status === 200) {
            yield put(getGallerySuccess(JSON.parse(response.data.gallery)));
            yield put(getTotalGallery(JSON.parse(response.data.total)));
        } else {
            toastr.warning(response.data.message, "Image Not Found")
            yield put(getGalleryFailed(response.status));
        }

    } catch (error) {
        yield put(getGalleryFailed(error));
    }
}


export function* watchGetGallery() {
    yield takeEvery(GET_GALLERY_DATA, getGallery)
}



function* GallerySaga() {
    yield all([
        fork(watchGetGallery)
    ]);
}

export default GallerySaga;