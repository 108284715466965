import {
    GET_ATTENDANCES,
    GET_ATTENDANCES_SUCCESS,
    GET_ATTENDANCES_FAILED,
    GET_STUDENTS_LIST_SUCCESS,
    CREATE_ACCIDENT_REPORT,
    CREATE_ACCIDENT_REPORT_SUCCESS,
    CREATE_ACCIDENT_REPORT_FAILED, GET_ACCIDENT_REPORT_SUCCESS,
    SET_DEFAULT,
    UPDATE_ACCIDENT_REPORT,
    UPDATE_ACCIDENT_REPORT_SUCCESS,
    UPDATE_ACCIDENT_REPORT_FAILED,
    EMAIL_SENT,
    GET_OLD_STUDENTS_LIST_SUCCESS
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    attendances: undefined,
    students: undefined,
    accReportCreating: -1,
    accReportUpdating: -1,
    accReportList: null,
    accTotal: 0,
    oldStudents: null
}

const attendances = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATTENDANCES:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_ATTENDANCES_SUCCESS:
            state = {
                ...state,
                loading: false,
                attendances: action.payload
            }
            break;
        case GET_ATTENDANCES_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            };
            break;
        case GET_STUDENTS_LIST_SUCCESS:
            state = {
                ...state,
                students: action.payload
            };
            break;
        case CREATE_ACCIDENT_REPORT:
            state = {
                ...state,
                accReportCreating: 0
            };
            break;
        case CREATE_ACCIDENT_REPORT_SUCCESS:
            let newList;
            if(state.accReportList) {
                newList = [action.payload, ...state.accReportList]
            } else {
                newList = [action.payload]
            }
            state = {
                ...state,
                accReportList: newList,
                accReportCreating: 1
            }
            break;
        case CREATE_ACCIDENT_REPORT_FAILED:
            state = {
                ...state,
                accReportCreating: 2
            }
            break;
        case GET_ACCIDENT_REPORT_SUCCESS:
            state = {
                ...state,
                accReportList: action.payload,
                accTotal: action.total
            }
            break;
        case SET_DEFAULT:
            state = {
                ...state,
                accReportCreating: -1,
                accReportUpdating: -1,
            }
            break;
        case UPDATE_ACCIDENT_REPORT:
            state = {
                ...state,
                accReportUpdating: 0
            };
            break;
        case UPDATE_ACCIDENT_REPORT_SUCCESS:
            let list = state.accReportList;
            list[action.index] = action.payload;
            state = {
                ...state,
                accReportList: list,
                accReportUpdating: 1
            }
            break;
        case UPDATE_ACCIDENT_REPORT_FAILED:
            state = {
                ...state,
                accReportUpdating: 2
            }
            break;
        case EMAIL_SENT:
            let updatedAReport = state.accReportList;
            updatedAReport[action.payload].is_email_sent = true
            state = {
                ...state,
                accReportList: updatedAReport
            }
            break;
        case GET_OLD_STUDENTS_LIST_SUCCESS:
            state = {
                ...state,
                oldStudents: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default attendances;