import {
    GET_REPORT_ITEMS,
    GET_REPORT_ITEMS_SUCCESS,
    GET_REPORT_ITEMS_FAILED,
    GET_REPORT_ACTIVITIES,
    GET_REPORT_ACTIVITIES_SUCCESS,
    GET_REPORT_ACTIVITIES_FAILED,
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILED
} from './actionTypes';

export const getGroupList = () => {
    return {
        type: GET_GROUPS
    }
}

export const getGroupListSuccess = (groups) => {
    return {
        type: GET_GROUPS_SUCCESS,
        payload: groups
    }
}

export const getAllReportActivities = () => {
    return {
        type: GET_REPORT_ACTIVITIES
    }
}

export const getAllReportActivitiesSuccess = (workbooks) => {
    return {
        type: GET_REPORT_ACTIVITIES_SUCCESS,
        payload: workbooks
    }
}

export const getAllReportActivitiesFailed = (error) => {
    return {
        type: GET_REPORT_ACTIVITIES_FAILED,
        payload: error
    }
}

export const getAllReportItems = () => {
    return {
        type: GET_REPORT_ITEMS
    }
}


export const getAllReportItemsSuccess = (activities) => {
    return {
        type: GET_REPORT_ITEMS_SUCCESS,
        payload: activities,
    }
}

export const getAllReportItemsFailed = (error) => {
    return {
        type: GET_REPORT_ITEMS_FAILED,
        payload: error
    }
}




