import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card, CardBody, Spinner, FormGroup, Button} from "reactstrap";
import { withRouter } from "react-router-dom";

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import {getAttendances, getGroupList} from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import moment from "moment";
import { DatePicker } from "antd";
import {AvField, AvForm} from "availity-reactstrap-validation";
import toastr from 'toastr';


const { RangePicker } = DatePicker

const ReportAttendances = (props) => {

    const getDate = (value, dateString) => {
        const params = { start: dateString[0], end: dateString[1] };
        if (value) {
            setStart(dateString[0])
            setEnd(dateString[1])
            // props.getAttendances(params)
        }
    }

    const handleFindAttendance = (event, values) => {
        event.persist()
        console.log(values);
        if(values.group === 1000000) {
            toastr.warning("Please select group", "Warning")
            return
        }
        if(start && end) {
            values.start = start
            values.end = end
            // setGroupName()
            props.getAttendances(values)
        } else {
            toastr.warning("Please select date range", "Warning")
        }
    }

    useEffect(() => {
        props.getGroupList();
    }, [])

    const [start, setStart] = useState(undefined)
    const [end, setEnd] = useState(undefined)
    const [groupName, setGroupName] = useState('')

    const tableHeader = () => {
        if (props.attendances && props.attendances.length > 0) {
            return props.attendances[0].attendances.map((item, index) => {
                return (
                    <td key={index}>
                        <p className="font-weight-bold">
                            {moment(item.date).format('MMM Do YY')}
                        </p>
                    </td>
                )
            })
        }
    }

    const tableBody = () => {
        if (props.attendances && props.attendances.length > 0) {
            return props.attendances.map((item, index) => {
                return (
                    <tr key={index}>
                        <td className="font-weight-bold fixed" style={{ cursor: "pointer" }} onClick={() => goToStudent(item)}>
                            {item.name}
                        </td>
                        {tableBodyItems(item.attendances)}
                    </tr>
                )
            })
        }
    }

    const tableBodyItems = (attendances) => {
        if (attendances.length > 0) {
            return attendances.map((item, index) => {
                return (
                    <td key={index}>{symbol(item.child_present)}</td>
                )
            })
        }
    }

    const goToStudent = (info) => {
        window.location.href = `/students/${info.student_id}`
    }

    const symbol = (item) => {
        switch (item) {
            case "Présent(P)":
                return "P";
            case "Absent(A)":
                return "A"
            case "Maladie(M)":
                return "M"
            case "Vacances(V)":
                return "V"
            case "Férié(F)":
                return "F"
            case "Fermeture du SG(X)":
                return "X"
            case "Demi-Jounée(DJ)":
                return "DJ"
            default:
                return ""
        }
    }

    const options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Report-Attendances')} breadcrumbItem={props.t('Report')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} onValidSubmit={(e, v) => { handleFindAttendance(e, v) }}>
                                            <Col xs={12} sm={6} md={4}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={1000000}
                                                        name="group"
                                                        label="Select Group"
                                                        validate={{ required: { value: true } }}
                                                        onChange={(e) => {
                                                            let index = e.nativeEvent.target.selectedIndex;
                                                            let text = e.nativeEvent.target[index].text;
                                                            setGroupName(text);
                                                        }}
                                                    >
                                                        <option disabled={true} value={1000000}>Select Group</option>
                                                        {options()}
                                                        <option value={1000001}>All groups</option>
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={4}>
                                                <div className="form-group">
                                                    <label>Select start and end date:</label>
                                                    <div className="mb-3">
                                                        <RangePicker style={{padding: '6px 12px'}} onChange={getDate} />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={6} md={4} style={{paddingTop: '27px'}}>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button
                                                            type="submit"
                                                            color="primary"
                                                            className="mr-1"
                                                            style={{ width: "100%" }}
                                                            disabled={!!props.loading}
                                                        >
                                                            {props.loading ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ) : "Find"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </AvForm>
                                    </Row>
                                    <Row className="mt-lg-3 mt-3">
                                        <Col xs={12} sm={6} className="float-right pt-3">
                                            <h4>
                                                {groupName}
                                            </h4>
                                        </Col>
                                        <Col xs={12} sm={6} className="float-right">
                                            <p className="comment p-2" style={{ float: "right", marginRight: "0", background: "#3b7ad9", color: "white" }}>
                                                (P)Présent, (A)Absent, (M)Maladie, (V)Vacances, (F)Férié, (DJ)Demi-Journée
                                            </p>
                                        </Col>
                                    </Row>
                                    {!props.loading ? (
                                        <Row className="mt-4 overflow-auto report-wrapper search-table-outter">
                                            <table id="tech-companies-1" className="table-striped search-table inner">
                                                <thead className="report-thead">
                                                    <tr>
                                                        {props.attendances && (
                                                            <td>
                                                                <p className="font-weight-bold">Child Name</p>
                                                            </td>
                                                        )}
                                                        {tableHeader()}
                                                    </tr>
                                                </thead>
                                                <tbody className="report-tbody">
                                                    {tableBody()}
                                                </tbody>
                                            </table>
                                        </Row>
                                    ) :
                                        <Row style={{marginLeft: "50%"}}>
                                            <Spinner type="grow" className="text-center" color="primary" />
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { groups } = state.ActivityReducer;
    const { attendances, loading } = state.ReportAttReducer;
    return { attendances, loading, groups };
}

export default withRouter(connect(mapStateToProps, { getAttendances, getGroupList })(withNamespaces()(ReportAttendances)));
