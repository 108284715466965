import {
    GET_GALLERY_DATA,
    GET_GALLERY_DATA_SUCCESS,
    GET_GALLERY_DATA_FAILED,
    TOTAL_GALLERY
} from './actionTypes';

export const getGalleryData = (params) => {
    return {
        type: GET_GALLERY_DATA,
        payload: params
    }
}

export const getGallerySuccess = (data) => {
    return {
        type: GET_GALLERY_DATA_SUCCESS,
        payload: data
    }
}

export const getTotalGallery = (total) => {
    return {
        type: TOTAL_GALLERY,
        payload: total
    }
}

export const getGalleryFailed = (error) => {
    return {
        type: GET_GALLERY_DATA_FAILED,
        payload: error
    }
}