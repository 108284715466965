import {
    GET_REPORT_ITEMS,
    GET_REPORT_ITEMS_SUCCESS,
    GET_REPORT_ITEMS_FAILED,
    GET_REPORT_ACTIVITIES,
    GET_REPORT_ACTIVITIES_SUCCESS,
    GET_REPORT_ACTIVITIES_FAILED,
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILED
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    reportItems: undefined,
    activities: undefined,
    groups: []
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT_ACTIVITIES:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_REPORT_ACTIVITIES_SUCCESS:
            state = {
                ...state,
                loading: false,
                activities: action.payload
            }
            break;
        case GET_REPORT_ACTIVITIES_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case GET_REPORT_ITEMS:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_REPORT_ITEMS_SUCCESS:
            state = {
                ...state,
                loading: false,
                reportItems: action.payload
            }
            break;
        case GET_REPORT_ITEMS_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case GET_GROUPS:
            state = {
                ...state
            }
            break;
        case GET_GROUPS_SUCCESS:
            state = {
                ...state,
                groups: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;