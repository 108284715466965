import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_REPORT_ACTIVITY_LIST } from './actionTypes';
import { getReportActivityListSuccess, getReportActivityListFailed } from './actions';
import { getAllActivities } from '../../../apiHelpers/schoolReportApiHelper';
import toastr from 'toastr';
import { getStoreGroups, getStoreTotal } from './selectors';

function* getReActivityLists() {
    try {
        const response = yield call(getAllActivities, '/api/v1/report_activity_lists');
        if(response.status === 200) {
            yield put(getReportActivityListSuccess(JSON.parse(response.data.lists)));
        } else {
            yield put(getReportActivityListFailed(response.status));
            // toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getReportActivityListFailed(error));
    }
}


export function* watchGetReportActivityLists() {
    yield takeEvery(GET_REPORT_ACTIVITY_LIST, getReActivityLists)
}



function* getReportActivityListsSaga() {
    yield all([
        fork(watchGetReportActivityLists)
    ]);
}

export default getReportActivityListsSaga;