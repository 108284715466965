import {
    CREATE_USER,
    CREATE_USER_SUCCESS,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    ALL_USERS,
    USER_API_ERROR,
    EDIT_USER_FAILED
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    users: [],
    totalUsers: undefined,
    modal: undefined,
    editModal: undefined,
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USER:
            state = {
                ...state,
                loading: true,
                modal: "start"
            }
            break;
        case CREATE_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                modal: "success"
            }
            break;
        case GET_ALL_USERS:
            state = {
                ...state,
                loading: true
            };
            break;
        case GET_ALL_USERS_SUCCESS:
            state = {
                ...state,
                loading: false,
                users: action.payload
            };
            break;
        case ALL_USERS:
            state = {
                ...state,
                loading: false,
                totalUsers: action.payload,
            };
            break;
        case UPDATE_USER:
            state = {
                ...state,
                loading: true,
                editModal: "start"
            };
            break;
        case UPDATE_USER_SUCCESS:
            state = { ...state, loading: false, editModal: "success" };
            break;
        case DELETE_USER:
            state = { ...state, loading: true };
            break;
        case DELETE_USER_SUCCESS:
            state = { ...state, loading: false };
            break;
        case USER_API_ERROR:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                modal: "failed"
            };
            break;
        case EDIT_USER_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                editModal: "failed"
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;