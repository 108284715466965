import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATION_COUNT_SUCCESS
} from './actionTypes';

const initialState = {
    notifications: undefined,
    notificationCounts: undefined
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            state = {
                ...state
            }
            break;
        case GET_NOTIFICATIONS_SUCCESS:
            state = {
                ...state,
                notifications: action.payload
            }
            break;
        case GET_NOTIFICATION_COUNT_SUCCESS:
            state = {
                ...state,
                notificationCounts: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;