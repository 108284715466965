import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import * as firebase from "firebase";
import 'firebase/storage';
import '../src/i18n';
import store from "../src/store";
import App from '../src/App'
import {firebaseConfig} from "../src/config";
firebase.initializeApp(firebaseConfig);
document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.body.appendChild(document.createElement('div')),
  )
})
