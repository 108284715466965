import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_ATT_STUDENTS, CREATE_ATTENDANCE } from './actionTypes';
import {
    getAttStudentsSuccess,
    getAttStudentsFailed,
    createAttendanceSuccess,
    createAttendanceFailed,
    getAttStudents
} from './actions';
import { getAttendances, createAttendance, updateAttendance } from '../../../apiHelpers/attendanceApiHelper';
import toastr from 'toastr';
import { getStoreAttendances } from './selectors';
import {getStoreStudents} from "../students/selectors";

function* attStudents({payload}) {
    try {
        const response = yield call(getAttendances, '/api/v1/attendance', payload);
        if(response.status === 200) {
            yield put(getAttStudentsSuccess(JSON.parse(response.data.attendances)));
        } else {
            yield put(getAttStudentsFailed(response.status));
            // toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getAttStudentsFailed(error));
    }
}

function* createAtt({payload}) {
    try {
        const group_id = payload.group_id
        delete payload.group_id
        const response = yield call(createAttendance, '/api/v1/attendance', payload);
        if(response.status === 200) {
            yield put(createAttendanceSuccess());
            yield put(getAttStudents({date: payload.date, group_id: group_id}))
            toastr.success(response.data.message, "Success")
        } else {
            yield  put(createAttendanceFailed(response.status))
            toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield  put(createAttendanceFailed(response.status))
        toastr.error("Something went wrong","Error")
    }
}


export function* watchGetAttStu() {
    yield takeEvery(GET_ATT_STUDENTS, attStudents)
}

export function* watchCreateAtt() {
    yield takeEvery(CREATE_ATTENDANCE, createAtt)
}



function* attenSaga() {
    yield all([
        fork(watchGetAttStu),
        fork(watchCreateAtt)
    ]);
}

export default attenSaga;