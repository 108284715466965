import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Input, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, CardTitle, FormGroup, Label, Form } from "reactstrap";
import { Link, Redirect, withRouter } from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getGroupList } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { callStudents, findActivities, updateStudentActivity} from "../../apiHelpers/activityApiHelper";
import toastr from "toastr";



// const date = Date.now()
const ActivityDetail = (props) => {


    useEffect(() => {
        props.getGroupList()
    }, [])

    const [rows1, setrows1] = useState([]);

    const [students, setStudents] = useState([]);


    const [allActivities, setAllActivities] = useState([]);

    const [modal, setmodal] = useState(false);

    //////// updating values ////////////////
    const [accomplished, setAccomplished] = useState("")
    const [acquired, setAcquired] = useState(undefined)
    const [notes, setNotes] = useState(undefined)
    const [completed, setCompleted] = useState(undefined)
    const [id, setId] = useState(undefined)
    const [index, setIndex] = useState(undefined)

    const [actSaveState, setActSaveState] = useState(false)

    const [findingState, setFindingState] = useState(false)

    const handleFindActivity = (event, values) => {
        event.persist()
        if (values.group === "Select Group") {
            toastr.info("Please select a group", "Info")
            return false
        }

        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }

        if (values.workbook === "Select Workbook") {
            toastr.info("Please select a workbook", "Info")
            return false
        }

        delete values.group
        setFindingState(true)
        findActivities({student_id: values.student, workbook_id: values.workbook})
            .then((result) => {
                setFindingState(false)
                if(result.status === 200) {
                    setAllActivities(JSON.parse(result.data.activities))
                } else {
                    toastr.error(result.data.message,"Error")
                }
            })
            .catch((error) => {
                setFindingState(false)
                toastr.error("Something went wrong!","Error")
            })
    }

    const getStudent = (e) => {
        setStudents([])
        callStudents({group_id: e.target.value})
            .then((result) => {
                if (result.status === 200) {
                    setStudents(JSON.parse(result.data.students))
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!", "Error")
            })
    }

    const options = () => {
        if(props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    const workbookoptions = () => {
        if(props.workbooks) {
            return props.workbooks.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.title}
                    </option>
                )
            })
        }
    }

    const activityOptions = () => {
        if(students) {
            return students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const activities = () => {
        if(allActivities.length !== 0) {
            return allActivities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        {index === 0 || item.sub_activity.activity.name !== allActivities[index - 1].sub_activity.activity.name ? (
                            <>
                            <Tr>
                                <Td colSpan={8} className={"text-left"} style={{backgroundColor: "#3B78D8", color: "white"}}>
                                    <strong>{item.sub_activity.activity.name}</strong>
                                </Td>
                            </Tr>
                            <Tr>
                                <Td className="text-left">{item.sub_activity.name}</Td>
                                <Td>
                                    {item.accomplished ? (item.accomplished).substring(0, 10) : ""}
                                </Td>
                                <Td>
                                    {item.acquired && item.acquired !== "Select" ? item.acquired : ""}
                                </Td>
                                <Td>
                                    {item.notes ? item.notes : ""}
                                </Td>
                                <Td>
                                    {item.completed && item.completed !== "Select" ? item.completed : ""}
                                </Td>
                                <Td>
                                    {item.teacher === null ? "" : item.teacher}
                                </Td>
                                <Td>
                                    {item.last_updated ? (item.last_updated).substring(0, 10) : ""}
                                </Td>
                                <Td>
                                    <i className="fas fa-pencil-alt" style={{cursor: "pointer"}} onClick={() => handleUpdate(item, index)}></i>
                                </Td>
                            </Tr>
                            </>
                        ):(
                        <Tr>
                            <Td className="text-left">{item.sub_activity.name}</Td>
                            <Td>
                                {item.accomplished ? (item.accomplished).substring(0, 10) : ""}
                            </Td>
                            <Td>
                                {item.acquired && item.acquired !== "Select" ? item.acquired : ""}
                            </Td>
                            <Td>
                                {item.notes ? item.notes : ""}
                            </Td>
                            <Td>
                                {item.completed && item.completed !== "Select" ? item.completed : ""}
                            </Td>
                            <Td>
                                {item.teacher === null ? "" : item.teacher}
                            </Td>
                            <Td>
                                {item.last_updated ? (item.last_updated).substring(0, 10) : ""}
                            </Td>
                            <Td>
                                <i className="fas fa-pencil-alt" style={{cursor: "pointer"}} onClick={() => handleUpdate(item, index)}></i>
                            </Td>
                        </Tr>)}
                    </Fragment>
                )
            })
        }
    }

    const clear = () => {
        setmodal(false)
        setAccomplished(undefined)
        setAcquired(undefined)
        setNotes(undefined)
        setCompleted(undefined)
        setId(undefined)
        setIndex(undefined)
    }

    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleUpdate = (item, index) => {
        setmodal(true)
        setAccomplished(item.accomplished ? (item.accomplished).substring(0, 10) : formatDate(Date.now()))
        setAcquired(item.acquired)
        setNotes(item.notes)
        setId(item.id)
        setIndex(index)
        setCompleted(item.completed)
    }

    const handleEdit = (event, values) => {
        if (values.acquired === "Select") {
            toastr.info("Please select one of 'Acquired'", "Info")
            return false
        }

        if (values.completed === "Select") {
            toastr.info("Please select one of 'Completed'", "Info")
            return false
        }
        setActSaveState(true)
        updateStudentActivity({id: id, ...values})
            .then((result) => {
                setActSaveState(false)
                if(result.status === 200) {
                    let repo = allActivities
                    repo.splice(index, 1, JSON.parse(result.data.data))
                    setAllActivities(repo)
                    clear()
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                setActSaveState(false)
                toastr.error("Something went wrong", "Error")
            })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Activity-Detail')} breadcrumbItem={props.t('Activity')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm  className="outer-repeater row" style={{width: "100%"}} onValidSubmit={(e,v) => {handleFindActivity(e,v) }}>
                                            <Col sm={3} xs={6}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={"Select Group"}
                                                        name="group"
                                                        validate={{ required: { value: true } }}
                                                        onChange={getStudent}
                                                    >
                                                        <option disabled={true}>Select Group</option>
                                                        {options()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={3} xs={6}>
                                                <div className="form-group">
                                                    <AvField type="select" value={"Select Student"} name="student" validate={{ required: { value: true } }}>
                                                        <option disabled={true}>Select Student</option>
                                                        {activityOptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={3} xs={6}>
                                                <div className="form-group">
                                                    <AvField type="select" value={"Select Workbook"} name="workbook" validate={{ required: { value: true } }}>
                                                        <option disabled={true}>Select Workbook</option>
                                                        {workbookoptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={3} xs={6} className={"mr-0"}>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button disabled={findingState} type="submit" color="primary" className="mr-1" style={{width: "100%"}}>
                                                            {findingState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ): "Find"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </AvForm>
                                    </Row>

                                    <Row>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr>
                                                    <Th className="text-left" data-priority="1">Activity Name</Th>
                                                    <Th data-priority="1">Date Accomplished</Th>
                                                    <Th data-priority="1">Acquired</Th>
                                                    <Th data-priority="1">Notes</Th>
                                                    <Th data-priority="1">Completed</Th>
                                                    <Th data-priority="1">Teacher</Th>
                                                    <Th data-priority="1">Last Updated</Th>
                                                    <Th data-priority="1">Action</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {activities()}
                                            </Tbody>
                                        </Table>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal size='md' backdrop={"static"} isOpen={modal} role="dialog" autoFocus={true} centered={true} className="myModal" tabIndex="-1" toggle={() => { setmodal(!modal); }}>
                        <div className="modal-content">
                            <ModalHeader toggle={() => { clear() }}>
                                Edit Activity Detail
                            </ModalHeader >
                            <ModalBody>
                                <Col sm="12">
                                    <Card>
                                        <CardBody>
                                            <AvForm onValidSubmit={(e,v) => { handleEdit(e,v) }}>
                                                <Label htmlFor="validationCustom04">{props.t('Accomplished Date')}</Label>
                                                <AvField
                                                    name="accomplished"
                                                    type="date"
                                                    errorMessage="Please provide a valid date."
                                                    className="form-control"
                                                    id="validationCustom04"
                                                    value={accomplished ? accomplished : ""}
                                                />
                                                <AvField type="select" value={acquired ? acquired : "Select"}  name="acquired" label="Acquired">
                                                    <option disabled={true}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </AvField>
                                                <AvField
                                                    name="notes"
                                                    label="Notes"
                                                    value={notes ? notes : ""}
                                                    placeholder="Enter comments"
                                                    type="textarea"
                                                    errorMessage="Invalid Email"
                                                />
                                                <AvField type="select" value={completed ? completed : "Select"} name="completed" label="Completed">
                                                    <option disabled={true}>Select</option>
                                                    <option>Yes</option>
                                                    <option>No</option>
                                                </AvField>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1" disabled={actSaveState}>
                                                            {actSaveState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Updating...
                                                                </React.Fragment>
                                                            ): "Update"}
                                                        </Button>{" "}
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </ModalBody>
                        </div>
                    </Modal>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { groups, workbooks} = state.ActivityReducer;
    return { groups, workbooks };
}

export default withRouter(connect(mapStateToProps, { getGroupList })(withNamespaces()(ActivityDetail)));
