import React from "react";
import {Redirect, Route} from "react-router-dom";

const Authmiddleware = ({
    component: Component,
    layout: Layout,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => {
            return (
                <Layout>
                    {localStorage.getItem("authUser") ? <Component {...props} /> : <Redirect to='/login' />}
                </Layout>
            );
        }}
    />
);

export default Authmiddleware;
