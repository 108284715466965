import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_ALL_USERS, CREATE_USER, UPDATE_USER, DELETE_USER } from './actionTypes';
import { getAllUsers, getAllUsersSuccess, editUserError, createUserSuccess, updateUserSuccess, deleteUserSuccess, userApiError, totalUsers } from './actions';
import { addNewUser, allUsers, editUser, delUser } from '../../../apiHelpers/userApiHelper';
// import { postJwtLogin  } from '../../../apiHelpers/authHelper';
import toastr from 'toastr';
import { getStoreUsers, getStoreTotal } from './selectors';

function* getUsers({ payload: { page, pageSize, search } }) {
    try {
        const response = yield call(allUsers, '/api/v1/manage_users', {page, pageSize, search});
        if (response.status === 200) {
            yield put(getAllUsersSuccess(JSON.parse(response.data.users)));
            yield put(totalUsers(response.data.total))
        }
        if (response.status === 401) {
            window.location.href = "/report-attendances"
        }
    } catch (error) {
        yield put(userApiError(error));
    }
}

function* createUser({ payload: { user } }) {
     try {
        if (user) {
            const response = yield call(addNewUser, '/api/v1/auth', {...user});
            if (response.status !== "error") {
                yield put(getAllUsers({page: 1, pageSize: 20}))
                yield put(createUserSuccess())
                toastr.success("The user has been saved!")
            } else {
                yield put(userApiError(response.status));
                toastr.error("It seems the email informed already exists","Error")
            }
        }
    } catch (error) {
        yield put(userApiError(error));
    }
}

function* editUserInfo({ payload: { newUser } }) {
    try {
        const index = newUser.index
        const id = newUser.id
        delete newUser.id
        delete newUser.index
        const response = yield call(editUser, `/api/v1/manage_users/${id}`, {...newUser});
        if (!response.error) {
            const newUsers = yield select(getStoreUsers)
            newUsers.splice(index, 1, JSON.parse(response.users))
            yield put(getAllUsersSuccess(newUsers));
            yield put(updateUserSuccess())
            toastr.success("The user has been updated!")
        } else {
            toastr.error("It seems the email informed already exists","Error")
            yield put(editUserError(response.status));
        }
    } catch (error) {
        yield put(editUserError(error));
    }
}

function* deleteUser({ payload: { value } }) {
    try {
        const response = yield call(delUser, `/api/v1/manage_users/${value.id}`);
    } catch (error) {
        yield put(userApiError(error));
    }
}


export function* watchGetUsers() {
    yield takeEvery(GET_ALL_USERS, getUsers)
}

export function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUser)
}

export function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, editUserInfo)
}

export function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUser)
}

function* userSaga() {
    yield all([
        fork(watchGetUsers),
        fork(watchCreateUser),
        fork(watchUpdateUser),
        fork(watchDeleteUser)
    ]);
}

export default userSaga;