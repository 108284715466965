import {
    GET_ATT_STUDENTS,
    GET_ATT_STUDENTS_SUCCESS,
    GET_ATT_STUDENTS_FAILED,
    CREATE_ATTENDANCE,
    CREATE_ATTENDANCE_SUCCESS,
    CREATE_ATTENDANCE_FAILED
} from './actionTypes';
import {act} from "@testing-library/react";

const initialState = {
    error: "",
    loading: false,
    attendances: [],
    modal: undefined,
    editModal: undefined,
}

const attendances = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATT_STUDENTS:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_ATT_STUDENTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                attendances: action.payload
            }
            break;
        case GET_ATT_STUDENTS_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case CREATE_ATTENDANCE:
            state = {
                ...state,
                loading: true,
                modal: "start"
            }
            break;
        case CREATE_ATTENDANCE_SUCCESS:
            state = {
                ...state,
                loading: false,
                modal: "success"
            }
            break;
        case CREATE_ATTENDANCE_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                modal: "failed"
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default attendances;