import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, UncontrolledAlert } from "reactstrap";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getGalleryData, getStudentsList } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import toastr from "toastr";
import { Pagination, Tooltip } from "antd";
import moment from "moment";
import ImgsViewer from 'react-images-viewer'

const TeacherGallery = (props) => {


    useEffect(() => {
        props.getStudentsList()
    }, [])

    const [studentId, setStudentId] = useState(undefined);



    const [current, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(120);

    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const [imgs, setImgs] = useState([])
    const [currentImg, setCurrentImg] = useState(undefined)
    const [imageIndex, setImageIndex] = useState(undefined)



    const onShowSizeChange = (current, pageSize) => {
        props.getGalleryData({ studentId: studentId, page: current, pageSize: pageSize })
        setPageSize(pageSize)
        setCurrentPage(current)
    }

    const onChange = (page) => {
        props.getGalleryData({ studentId: studentId, page: page, pageSize: pageSize })
        setCurrentPage(page)
    }

    const FindPhotos = (event, values) => {
        event.persist()
        if (values.group === "Select Group") {
            toastr.info("Please select a group", "Info")
            return false
        }

        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }

        setStudentId(values.student)
        props.getGalleryData({ studentId: values.student, page: current, pageSize: pageSize })
        delete values.group
    }


    const activityOptions = () => {
        if (props.students) {
            return props.students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const images = () => {
        if (props.gallery) {
            return props.gallery.map((item, index) => {
                return (
                    <Fragment key={index}>
                        {index === 0 || moment(item.created_at).format('l') !== moment(props.gallery[index - 1].created_at).format('l') ?
                            <Col xs={12} className="image-header pt-3">
                                <h3 style={{ position: "relative", color: "#383838", top: "25px", left: "25px", zIndex: "1" }}>{moment(item.created_at).format("YYYY-MM-DD")}</h3>
                            </Col> : ""
                        }
                        <div className="pl-3 pt-4 pb-4 pr-3 " style={{ position: "relative", left: "12px", backgroundColor: "#f5f5f5", borderRadius: "3px" }}>
                            <div><img src={item.thumbnail_url} onClick={() => handleCurrent(item, index)} /></div>
                        </div>
                    </Fragment>
                )
            })
        }
    }


    const handleCurrent = (img, num) => {
        if (props.gallery) {
            let images = []
            let total = 0
            props.gallery.forEach((item, index) => {
                if (moment(item.created_at).format('l') === moment(img.created_at).format('l')) {
                    images.push({ src: item.image_url });
                    if (num === index) {
                        setCurrentImg(total)
                        setImageIndex(total)
                    }
                    total++;
                }
            })
            setImgs(images)
        }
        setViewerIsOpen(true)
    }

    const clearViewer = () => {
        setViewerIsOpen(false)
        setCurrentImg(undefined)
        setImageIndex(undefined)
    }

    const handleViewerOpen = (value) => {
        // setViewerIsOpen(value)
        clearViewer()
    }

    const gotoPrevious = () => {
        setCurrentImg(imageIndex - 1)
        setImageIndex(imageIndex - 1)
    }

    const gotoNext = () => {
        setCurrentImg(imageIndex + 1)
        setImageIndex(imageIndex + 1)
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Photo Gallery')} breadcrumbItem={props.t('photo gallery')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} onValidSubmit={(e, v) => { FindPhotos(e, v) }}>
                                            <Col sm={6} xs={12}>
                                                <div className="form-group">
                                                    <AvField type="select" value={"Select Student"} name="student" validate={{ required: { value: true } }}>
                                                        <option disabled={true}>Select Student</option>
                                                        {activityOptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={6} xs={12} className={"mr-0"}>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <button type="submit" className="btn btn-primary mr-1" style={{ width: "100%" }} disabled={props.loading}>
                                                            {props.loading ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ) : "Find"}
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </AvForm>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <UncontrolledAlert color="info" className="alert-dismissible fade show mb-0 mt-2" role="alert">
                                                <i className="fa fa-info-circle mr-2 ml-0" aria-hidden="true"></i>
                                                We have improved Photo Gallery!
                                                Loading pictures when your child is selected is now faster!
                                            </UncontrolledAlert>
                                        </Col>
                                    </Row> */}

                                    <Row className="mt-3">
                                        {imgs.length > 0 && (
                                            <ImgsViewer
                                                imgs={imgs}
                                                currImg={currentImg}
                                                isOpen={viewerIsOpen}
                                                closeBtnTitle="Close"
                                                leftArrowTitle={'Previous'}
                                                rightArrowTitle={'Next'}
                                                onClickPrev={gotoPrevious}
                                                onClickNext={gotoNext}
                                                onClose={() => handleViewerOpen(false)}
                                            />
                                        )}
                                        {images()}
                                    </Row>

                                    {props.total && (
                                        <Pagination className="float-right mt-3" current={current} defaultPageSize={120} onShowSizeChange={onShowSizeChange} onChange={onChange} total={props.total ? props.total : 0} />
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = state => {
    const { groups } = state.ActivityReducer;
    const { gallery, total, loading } = state.GalleryReducer;
    const { students } = state.ReportAttReducer;
    return { groups, gallery, total, students, loading };
}

export default withRouter(connect(mapStateToProps, { getStudentsList, getGalleryData })(withNamespaces()(TeacherGallery)));