import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Card, CardBody, FormGroup} from "reactstrap";
import { withRouter } from "react-router-dom";
import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getStudentsList } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { findOrCreateReport, saveReportActivity, saveComment, releaseNote, getYearDurations } from "../../apiHelpers/schoolReportApiHelper";
import toastr from "toastr";
import moment from "moment";
import axios from "axios";



const ActivityDetail = (props) => {


    useEffect(() => {
        props.getStudentsList()
    }, [])

    const [allActivities, setAllActivities] =  useState(undefined)
    const [report, setReport] = useState(undefined)
    const [studentInfo, setStudentInfo] = useState(undefined)
    const [teachers, setTeachers] = useState(undefined)
    const [absent1, setAbsent1] = useState(undefined)
    const [absent2, setAbsent2] = useState(undefined)
    const [absent3, setAbsent3] = useState(undefined)

    const [firstComment, setFirstComment] = useState(undefined)
    const [secondComment, setSecondComment] = useState(undefined)
    const [thirdComment, setThirdComment] = useState(undefined)

    const [terms, setTerms] = useState(undefined)
    const [headerTerm, setHeaderTerm] = useState(undefined)

    const [findingState, setFindingState] = useState(false)

    const [saveState1, setSaveState1] = useState(false)
    const [releaseState1, setReleaseState1] = useState(false)

    const [saveState2, setSaveState2] = useState(false)
    const [releaseState2, setReleaseState2] = useState(false)
    const [printing, setPrinting] = useState(false)
    const [reportId, setReportId] = useState(undefined)

    const handleFindActivity = (event, values) => {
        event.persist()
        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }

        if (values.grade === "Select Year Duration") {
            toastr.info("Please select a Year Duration", "Info")
            return false
        }

        setReport(undefined)
        setAllActivities(undefined)
        setFindingState(true)
        findOrCreateReport(values)
            .then((result) => {
                setFindingState(false)
                if(result.status === 200) {
                    setAllActivities(JSON.parse(result.data.activities))
                    setReportId(JSON.parse(result.data.report).id)
                    setReport(JSON.parse(result.data.report))
                    setStudentInfo(JSON.parse(result.data.student))
                    setTeachers(JSON.parse(result.data.teachers))
                    setAbsent1(result.data.term1_absent)
                    setAbsent2(result.data.term2_absent)
                    setAbsent3(result.data.term3_absent)
                    let report = JSON.parse(result.data.report)
                    setFirstComment(report.first_p_comment && report.first_p_comment)
                    setSecondComment(report.second_p_comment && report.second_p_comment)
                } else {
                    toastr.error(result.data.error,"Error")
                }
            })
            .catch((error) => {
                setFindingState(false)
                toastr.error("Something went wrong!","Error")
            })
    }

    const grades = () => {
        if(terms){
            return terms.map((item, index) => {
                return (
                    <option key={index} value={item.duration}>
                        {item.duration}
                    </option>
                )
            })
        }
    }

    const studentLists = () => {
        if(props.students) {
            return props.students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }


    const reportItems = () => {
        if(allActivities) {
            return allActivities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr style={{backgroundColor: "#CCCCCC"}}>
                            <td colSpan={3}>
                                <strong>
                                    <h4 style={{marginBottom: "unset"}}>
                                        {item.title}
                                    </h4>
                                </strong>
                            </td>
                        </tr>
                        {reportActivities(item.report_activities)}
                    </Fragment>
                )
            })
        }
    }

    const reportActivities = (activities) => {
        if(activities) {
            return activities.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr style={{backgroundColor: "#C9DAF8"}}>
                            <td colSpan={3} style={{textAlign: "left"}}>
                                <h5 style={{marginBottom: "unset"}}>
                                    {item.name}
                                </h5>
                            </td>
                        </tr>
                        {reportSubActivities(item.report_sub_activities)}
                    </Fragment>
                )
            })
        }
    }

    const reportSubActivities = (subs) => {
        if(subs) {
            return subs.map((item, index) => {
                return (
                    <Fragment key={index}>
                        <tr>
                            <td style={{textAlign: "left"}}>
                                {item.name}
                            </td>
                            <td>
                                { report && report.is_first_release && item.report_subs[0].first_result && item.report_subs[0].first_result }
                            </td>
                            <td>
                                { report && report.is_second_release && item.report_subs[0].second_result && item.report_subs[0].second_result }
                            </td>
                        </tr>
                    </Fragment>
                )
            })
        }
    }



    const handleFirstComment = () => {
        if(!firstComment) {
            toastr.info("Please input your comments", "Info")
            return false
        }
        const params = {comment: firstComment, report_id: report.id, type: 1}
        setSaveState1(true)
        commentSave(params)
    }

    const handleSecondComment = () => {
        if(!secondComment) {
            toastr.info("Please input your comments", "Info")
            return false
        }
        const params = {comment: secondComment, report_id: report.id, type: 2}
        setSaveState2(true)
        commentSave(params)
    }

    const clearSaveState = () => {
        setSaveState1(false)
        setSaveState2(false)
    }

    const commentSave = (params) => {
        saveComment(params)
            .then((result) => {
                clearSaveState()
                if(result.status === 200) {
                    setReport(JSON.parse(result.data.report))
                    toastr.success(result.data.success, "Success")

                } else {
                    toastr.error(result.data.error,"Error")
                }
            })
            .catch((error) => {
                clearSaveState()
                toastr.error("Something went wrong!","Error")
            })
    }

    const handleFirstRelease = () => {
        const params = {type: 1, report_id: report.id}
        setReleaseState1(true)
        submitRelease(params)
    }

    const handleSecondRelease = () => {
        const params = {type: 2, report_id: report.id}
        setReleaseState2(true)
        submitRelease(params)
    }

    const clearReleaseState = () => {
        setReleaseState1(false)
        setReleaseState2(false)
    }

    const submitRelease = (params) => {
        releaseNote(params)
            .then((result) => {
                clearReleaseState()
                if(result.status === 200) {
                    setReport(JSON.parse(result.data.report))
                    toastr.success(result.data.success, "Success")

                } else {
                    toastr.error(result.data.error,"Error")
                }
            })
            .catch((error) => {
                clearReleaseState()
                toastr.error("Something went wrong!","Error")
            })
    }

    const getTerms = (id) => {
        getYearDurations({student_id: id})
            .then((result) => {
                if(result.status === 200) {
                    setTerms(JSON.parse(result.data.durations))
                } else {
                    toastr.error(result.data.error,"Info")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!","Error")
            })
    }

    const setHeaderYear = (year) => {
        setHeaderTerm(year)
    }

    const teachersList = () => {
        if(teachers) {
            return teachers.map((item, index) => {
                return (
                    <li key={index}>
                        {item.name}
                    </li>
                )
            })
        }
    }

    const generateReport = () => {
        if(reportId) {
            setPrinting(true);
            const headers = JSON.parse(localStorage.getItem("authHeaders"))
            const config = {
                method: 'post',
                url: `/api/v1/generate_pdf`,
                headers: {
                    accesstoken: headers.accesstoken,
                    client: headers.client,
                    uid: headers.uid,
                },
                responseType: 'blob',
                data: {report_id: reportId}
            }
            axios
                .request(config)
                .then(response => {
                    setPrinting(false);
                    const blob = URL.createObjectURL(response.data);
                    window.open(blob, '_blank');
                    // var link = document.createElement('a');
                    // link.href = blob;
                    // link.download= studentName + "(" + duration + ")" + ".pdf";
                    // link.click();
                    // setTimeout(function(){
                    //     window.URL.revokeObjectURL(blob);}
                    // , 100)
                })
                .catch((err) => {
                    setPrinting(false);
                    toastr.error("Something went wrong!", "Error")
                });
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('School Report')} breadcrumbItem={props.t('Report')} />
                    <Row>
                        <Col>
                            <h5>
                                Appreciation of child development
                            </h5>
                            <p>
                                Year Term: {headerTerm}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm  className="outer-repeater row m-auto" style={{width: "100%"}} onValidSubmit={(e,v) => {handleFindActivity(e,v) }}>
                                            <Col xs={12} sm={4}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={"Select Student"}
                                                        name="student"
                                                        validate={{ required: { value: true } }}
                                                        onChange={(e) => getTerms(e.target.value)}
                                                    >
                                                        <option disabled={true}>Select Student</option>
                                                        {studentLists()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={"Select Year Duration"}
                                                        name="grade"
                                                        validate={{ required: { value: true } }}
                                                        onChange={(e) => setHeaderYear(e.target.value)}
                                                    >
                                                        <option value={'Select Year Duration'} disabled={true}>Select Year Duration</option>
                                                        {grades()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col xs={12} sm={4}>
                                                <FormGroup className="">
                                                    <div>
                                                        <Button disabled={findingState} type="submit" color="primary" className="mr-1" style={{width: "100%"}}>
                                                            {findingState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ): "Find"}
                                                        </Button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col xs={12}>
                                                {!printing ? (
                                                    <button type="button" className="mt-sm-1 float-right btn btn-success waves-effect waves-light w-sm" disabled={!report} onClick={generateReport}>
                                                        <i className="fa fa-print d-block font-size-16"></i>
                                                        Print
                                                    </button> ) : (
                                                    <button type="button" className="mt-sm-1 float-right btn btn-success waves-effect waves-light w-sm" disabled={true}>
                                                        <i className="fa fa-print d-block font-size-16"></i>
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                        Generating Report...
                                                    </button> )}
                                            </Col>
                                        </AvForm>
                                    </Row>
                                    {report && studentInfo && (
                                    <Fragment>
                                        <hr />
                                    <Row className='mt-3'>
                                        <Col xs={12} sm={4}>
                                            <p><b>Name:</b> {studentInfo.firstName + " " + studentInfo.lastName}</p>
                                            <p><b>Mother/Tutor:</b> {studentInfo.motherName}</p>
                                            <p><b>Address:</b> {studentInfo.address}</p>
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            <p><b>Birth:</b> {moment(studentInfo.birthday).format('LL')}</p>
                                            <p><b>Father/Tutor:</b> {studentInfo.fatherName}</p>
                                        </Col>
                                        <Col xs={12} sm={4}>
                                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                <Thead>
                                                    <Tr style={{backgroundColor: "#6EA8DD", color: "white"}}>
                                                        <Th data-priority="1" colSpan={2}>Communication Dates</Th>
                                                        <Th data-priority="1">Absent days</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr>
                                                        <Td>Release 1</Td>
                                                        <Td>{`${report.duration.slice(0,4)}/11/30`}</Td>
                                                        <Td>{report && report.is_first_release && absent1 && absent1}</Td>
                                                    </Tr>
                                                    <Tr>
                                                        <Td>Release 2</Td>
                                                        <Td>{`${report.duration.slice(-4)}/05/31`}</Td>
                                                        <Td>{report && report.is_second_release && absent2 && absent2}</Td>
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row className='mt-4 mb-3'>
                                        <Col>
                                            <h5><b>Observed by:</b></h5>
                                            <ul>
                                                {teachersList()}
                                            </ul>
                                        </Col>
                                    </Row>

                                    <Row>
                                    <Col xs={12} className="float-right pl-0">
                                        <p className="comment p-2" style={{float: "left", background: "#e1e1e1", color: "black"}}>
                                            Légende: <br />
                                            1: Non-observé à ce jour 2: À travailler 3: Progrès 4: Évolution continue 5: Bonne maitrise
                                        </p>
                                    </Col>
                                    </Row>
                                    <Row>
                                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                                            <Thead>
                                                <Tr style={{backgroundColor: "#3B78D8", color: "white"}}>
                                                    <Th data-priority="1" style={{width: "40%"}}>Étapes</Th>
                                                    <Th data-priority="1">1</Th>
                                                    <Th data-priority="1">2</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {reportItems()}
                                            </Tbody>
                                        </Table>


                                            <Fragment>
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release 1 notes</Th>
                                                            <Th data-priority="1">Action</Th>
                                                            <Th>Parents Acceptance</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.first_p_comment} onChange={(e) => setFirstComment(e.target.value)} className="form-control" name="" id="" cols="30" rows="3">
                                                            </textarea>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <button
                                                                    className="btn btn-primary mr-1"
                                                                    onClick={handleFirstComment}
                                                                    disabled={!report.is_first_checked && !saveState1 ? true : false}
                                                                >
                                                                    {report.first_p_comment ?
                                                                        saveState1 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Updating
                                                                            </React.Fragment>
                                                                        ): "Update" :
                                                                        saveState1 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Saving
                                                                            </React.Fragment>
                                                                        ): "Save"
                                                                    }
                                                                </button>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <p>
                                                                    Parents must accept this School report. <br />
                                                                    In order to do this please click the button below.
                                                                </p>
                                                                <button
                                                                    className="btn btn-success"
                                                                    onClick={handleFirstRelease}
                                                                    disabled={ report.is_first_release && !report.is_first_checked && !saveState1 ? false : true}
                                                                >
                                                                    {releaseState1 ? (
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Accepting
                                                                        </React.Fragment>
                                                                    ): "I Accept"}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b></td>
                                                            <td colSpan={3} className="text-left" style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.first_t_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>

                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr style={{backgroundColor: "#D9D9D9"}}>
                                                            <Th className="text-left" data-priority="1" colSpan={2}>Release 2 notes</Th>
                                                            <Th data-priority="1">Action</Th>
                                                            <Th>Parents Acceptance</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Parents</b></td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                            <textarea defaultValue={report.second_p_comment} onChange={(e) => setSecondComment(e.target.value)} className="form-control" name="" id="" cols="30" rows="3">
                                                            </textarea>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <button
                                                                    className="btn btn-primary mr-1"
                                                                    disabled={report.is_second_checked && !saveState2 ? false : true}
                                                                    onClick={handleSecondComment}
                                                                >
                                                                    {report.second_p_comment ?
                                                                        saveState2 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Updating
                                                                            </React.Fragment>
                                                                        ): "Update" :
                                                                        saveState2 ? (
                                                                            <React.Fragment>
                                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                Saving
                                                                            </React.Fragment>
                                                                        ): "Save"
                                                                    }
                                                                </button>
                                                            </td>
                                                            <td style={{backgroundColor: "#D9D9D9"}}>
                                                                <p>
                                                                    Parents must accept this School report. <br />
                                                                    In order to do this please click the button below.
                                                                </p>
                                                                <button
                                                                    onClick={handleSecondRelease}
                                                                    className="btn btn-success"
                                                                    disabled={report.is_second_release && !report.is_second_checked && !releaseState2 ? false : true}
                                                                >
                                                                    {releaseState2 ? (
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Accepting
                                                                        </React.Fragment>
                                                                    ): "I Accept"}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{backgroundColor: "#D9D9D9"}}><b>Teachers</b></td>
                                                            <td colSpan={3} className="text-left" style={{backgroundColor: "#CFE2F3"}}>
                                                                {report.second_t_comment}
                                                            </td>
                                                        </tr>
                                                    </Tbody>
                                                </Table>


                                            </Fragment>

                                    </Row>
                                    </Fragment>
                                            )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { students } = state.ReportAttReducer;
    return { students };
}

export default withRouter(connect(mapStateToProps, { getStudentsList })(withNamespaces()(ActivityDetail)));
