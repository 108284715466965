import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAILED,
    GET_TOTAL_VALUES
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    dashboardData: undefined,
    total: undefined
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_DASHBOARD_DATA:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_DASHBOARD_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                dashboardData: action.payload
            }
            break;
        case GET_DASHBOARD_DATA_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case GET_TOTAL_VALUES:
            state = {
                ...state,
                total: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;