import React from 'react';
import { Row, Col, BreadcrumbItem } from "reactstrap";

const Breadcrumb = (props) => {
  return (
     <Row>
        <Col xs="12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">{props.title}</h4>
                <div className="page-title-right">
                </div>
            </div>
        </Col>
    </Row>
  );
}

export default Breadcrumb;