import {
    GET_NOTIFICATIONS,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATION_COUNT_SUCCESS
} from './actionTypes';

export const getNotifications = () => {
    return {
        type: GET_NOTIFICATIONS,
    }
}

export const getNotificationsSuccess = (notifications) => {
    return {
        type: GET_NOTIFICATIONS_SUCCESS,
        payload: notifications
    }
}

export const getNotificationCount = (count) => {
    return {
        type: GET_NOTIFICATION_COUNT_SUCCESS,
        payload: count
    }
}



