import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Card, CardBody, FormGroup } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getStudentsList, getAgendaData } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { EmoticonRating, StarRating } from 'baseui/rating';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { Checkbox, DatePicker, Rate } from 'antd';
import moment from "moment";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { AvForm, AvField } from "availity-reactstrap-validation";
import { submitEmoji, submitRates, submitNapTime, releaseReport, checkGroup } from "../../apiHelpers/agendaApiHelper";
import toastr from "toastr";
import { callStudents, findActivities } from "../../apiHelpers/activityApiHelper";


const engine = new Styletron();

const customIcons = {
    1: <i className="far fa-angry" />,
    2: <i className="far fa-frown" />,
    3: <i className="far fa-meh" />,
    4: <i className="far fa-smile" />,
    5: <i className="far fa-laugh-beam" />,
};

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

const loopValue = [0, 1, 2, 3, 4]


const TeacherAgenda = (props) => {


    useEffect(() => {
        props.getStudentsList()
    }, [])

    useEffect(() => {
        if (props.agenda && props.agenda.length !== 0) {
            props.agenda.forEach((item, index) => {
                let localTime = moment.utc(item.submitted_at).format("ll")
                let diff = moment(localTime).diff(startDate, 'day') - 1
                switch (diff) {
                    case 0:
                        if (item.mood) {
                            setEmoji1(item.mood)
                        }
                        if (item.participation) {
                            setParti1(item.participation)
                        }
                        if (item.respect) {
                            setRespect1(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite1(item.appetite)
                        }
                        if (item.poop) {
                            setPoop1(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign1(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash1(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom1(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo1(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom1(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo1(item.baby_nap_end)
                        }
                        if (item.parent_note) {
                            setNotes1(item.parent_note)
                            setBtnLetter1("Update")
                        }
                        if (item.teacher_note) {
                            setTNotes1(item.teacher_note)
                        }
                        if (item.isSaved) {
                            setBtn1(false)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                        }
                        if (item.teacher_name) {
                            setName1(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime1(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime1(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime1(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity1(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity1(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity1(item.third_milk_quantity)
                        }
                        break;
                    case 1:
                        if (item.mood) {
                            setEmoji2(item.mood)
                        }
                        if (item.participation) {
                            setParti2(item.participation)
                        }
                        if (item.respect) {
                            setRespect2(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite2(item.appetite)
                        }
                        if (item.poop) {
                            setPoop2(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign2(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash2(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom2(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo2(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom2(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo2(item.baby_nap_end)
                        }
                        if (item.parent_note) {
                            setNotes2(item.parent_note)
                            setBtnLetter2("Update")
                        }
                        if (item.teacher_note) {
                            setTNotes2(item.teacher_note)
                        }
                        if (item.isSaved) {
                            setBtn2(false)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                        }
                        if (item.teacher_name) {
                            setName2(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime2(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime2(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime2(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity2(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity2(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity2(item.third_milk_quantity)
                        }
                        break;
                    case 2:
                        if (item.mood) {
                            setEmoji3(item.mood)
                        }
                        if (item.participation) {
                            setParti3(item.participation)
                        }
                        if (item.respect) {
                            setRespect3(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite3(item.appetite)
                        }
                        if (item.poop) {
                            setPoop3(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign3(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash3(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom3(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo3(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom3(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo3(item.baby_nap_end)
                        }
                        if (item.parent_note) {
                            setNotes3(item.parent_note)
                            setBtnLetter3("Update")
                        }
                        if (item.teacher_note) {
                            setTNotes3(item.teacher_note)
                        }
                        if (item.isSaved) {
                            setBtn3(false)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                        }
                        if (item.teacher_name) {
                            setName3(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime3(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime3(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime3(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity3(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity3(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity3(item.third_milk_quantity)
                        }
                        break;
                    case 3:
                        if (item.mood) {
                            setEmoji4(item.mood)
                        }
                        if (item.participation) {
                            setParti4(item.participation)
                        }
                        if (item.respect) {
                            setRespect4(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite4(item.appetite)
                        }
                        if (item.poop) {
                            setPoop4(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign4(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash4(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom4(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo4(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom4(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo4(item.baby_nap_end)
                        }
                        if (item.parent_note) {
                            setNotes4(item.parent_note)
                            setBtnLetter4("Update")
                        }
                        if (item.teacher_note) {
                            setTNotes4(item.teacher_note)
                        }
                        if (item.isSaved) {
                            setBtn4(false)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                        }
                        if (item.teacher_name) {
                            setName4(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime4(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime4(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime4(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity4(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity4(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity4(item.third_milk_quantity)
                        }
                        break;
                    case 4:
                        if (item.mood) {
                            setEmoji5(item.mood)
                        }
                        if (item.participation) {
                            setParti5(item.participation)
                        }
                        if (item.respect) {
                            setRespect5(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite5(item.appetite)
                        }
                        if (item.poop) {
                            setPoop5(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign5(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash5(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom5(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo5(item.nap_end)
                        }
                        if (item.nap_start) {
                            setFrom5(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo5(item.nap_end)
                        }
                        if (item.parent_note) {
                            setNotes5(item.parent_note)
                            setBtnLetter5("Update")
                        }
                        if (item.teacher_note) {
                            setTNotes5(item.teacher_note)
                        }
                        if (item.isSaved) {
                            setBtn5(false)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                        }
                        if (item.teacher_name) {
                            setName5(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime5(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime5(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime5(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity5(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity5(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity5(item.third_milk_quantity)
                        }
                        break;
                    default:
                        break;
                }
            })
        }

        const today = moment();
        const diff = today.diff(startDate, 'day')
        setDayDiff(diff)
    }, [props.agenda])


    const [dayDiff, setDayDiff] = useState(undefined)
    const [dateValid, setDateValid] = useState(false)
    // States of Emojis
    const [emoji1, setEmoji1] = useState(undefined)
    const [emoji2, setEmoji2] = useState(undefined)
    const [emoji3, setEmoji3] = useState(undefined)
    const [emoji4, setEmoji4] = useState(undefined)
    const [emoji5, setEmoji5] = useState(undefined)
    // States of Participation
    const [parti1, setParti1] = useState(undefined)
    const [parti2, setParti2] = useState(undefined)
    const [parti3, setParti3] = useState(undefined)
    const [parti4, setParti4] = useState(undefined)
    const [parti5, setParti5] = useState(undefined)
    // States of Respect
    const [respect1, setRespect1] = useState(undefined)
    const [respect2, setRespect2] = useState(undefined)
    const [respect3, setRespect3] = useState(undefined)
    const [respect4, setRespect4] = useState(undefined)
    const [respect5, setRespect5] = useState(undefined)
    // States of Appetite
    const [appetite1, setAppetite1] = useState(undefined)
    const [appetite2, setAppetite2] = useState(undefined)
    const [appetite3, setAppetite3] = useState(undefined)
    const [appetite4, setAppetite4] = useState(undefined)
    const [appetite5, setAppetite5] = useState(undefined)
    // States of Poop
    const [poop1, setPoop1] = useState(0)
    const [poop2, setPoop2] = useState(0)
    const [poop3, setPoop3] = useState(0)
    const [poop4, setPoop4] = useState(0)
    const [poop5, setPoop5] = useState(0)
    // States of to notes
    const [sign1, setSign1] = useState(false)
    const [sign2, setSign2] = useState(false)
    const [sign3, setSign3] = useState(false)
    const [sign4, setSign4] = useState(false)
    const [sign5, setSign5] = useState(false)

    const [wash1, setWash1] = useState(false)
    const [wash2, setWash2] = useState(false)
    const [wash3, setWash3] = useState(false)
    const [wash4, setWash4] = useState(false)
    const [wash5, setWash5] = useState(false)

    // States of Nap time
    const [from1, setFrom1] = useState("")
    const [from2, setFrom2] = useState("")
    const [from3, setFrom3] = useState("")
    const [from4, setFrom4] = useState("")
    const [from5, setFrom5] = useState("")

    const [to1, setTo1] = useState("")
    const [to2, setTo2] = useState("")
    const [to3, setTo3] = useState("")
    const [to4, setTo4] = useState("")
    const [to5, setTo5] = useState("")

    // States of Baby Nap time
    const [babyFrom1, setBabyFrom1] = useState("")
    const [babyFrom2, setBabyFrom2] = useState("")
    const [babyFrom3, setBabyFrom3] = useState("")
    const [babyFrom4, setBabyFrom4] = useState("")
    const [babyFrom5, setBabyFrom5] = useState("")

    // Baby Nap Ending time
    const [babyTo1, setBabyTo1] = useState("")
    const [babyTo2, setBabyTo2] = useState("")
    const [babyTo3, setBabyTo3] = useState("")
    const [babyTo4, setBabyTo4] = useState("")
    const [babyTo5, setBabyTo5] = useState("")

    // States of Parent Notes
    const [notes1, setNotes1] = useState(undefined)
    const [notes2, setNotes2] = useState(undefined)
    const [notes3, setNotes3] = useState(undefined)
    const [notes4, setNotes4] = useState(undefined)
    const [notes5, setNotes5] = useState(undefined)
    // State of Milk
    const [firstMilkTime1, setFirstMilkTime1] = useState("no milk")
    const [firstMilkTime2, setFirstMilkTime2] = useState("no milk")
    const [firstMilkTime3, setFirstMilkTime3] = useState("no milk")
    const [firstMilkTime4, setFirstMilkTime4] = useState("no milk")
    const [firstMilkTime5, setFirstMilkTime5] = useState("no milk")

    const [secondMilkTime1, setSecondMilkTime1] = useState("no milk")
    const [secondMilkTime2, setSecondMilkTime2] = useState("no milk")
    const [secondMilkTime3, setSecondMilkTime3] = useState("no milk")
    const [secondMilkTime4, setSecondMilkTime4] = useState("no milk")
    const [secondMilkTime5, setSecondMilkTime5] = useState("no milk")

    const [thirdMilkTime1, setThirdMilkTime1] = useState("no milk")
    const [thirdMilkTime2, setThirdMilkTime2] = useState("no milk")
    const [thirdMilkTime3, setThirdMilkTime3] = useState("no milk")
    const [thirdMilkTime4, setThirdMilkTime4] = useState("no milk")
    const [thirdMilkTime5, setThirdMilkTime5] = useState("no milk")

    const [firstMilkQuantity1, setFirstMilkQuantity1] = useState("no milk")
    const [firstMilkQuantity2, setFirstMilkQuantity2] = useState("no milk")
    const [firstMilkQuantity3, setFirstMilkQuantity3] = useState("no milk")
    const [firstMilkQuantity4, setFirstMilkQuantity4] = useState("no milk")
    const [firstMilkQuantity5, setFirstMilkQuantity5] = useState("no milk")

    const [secondMilkQuantity1, setSecondMilkQuantity1] = useState("no milk")
    const [secondMilkQuantity2, setSecondMilkQuantity2] = useState("no milk")
    const [secondMilkQuantity3, setSecondMilkQuantity3] = useState("no milk")
    const [secondMilkQuantity4, setSecondMilkQuantity4] = useState("no milk")
    const [secondMilkQuantity5, setSecondMilkQuantity5] = useState("no milk")

    const [thirdMilkQuantity1, setThirdMilkQuantity1] = useState("no milk")
    const [thirdMilkQuantity2, setThirdMilkQuantity2] = useState("no milk")
    const [thirdMilkQuantity3, setThirdMilkQuantity3] = useState("no milk")
    const [thirdMilkQuantity4, setThirdMilkQuantity4] = useState("no milk")
    const [thirdMilkQuantity5, setThirdMilkQuantity5] = useState("no milk")

    // States of Teacher Notes
    const [tnotes1, setTNotes1] = useState('')
    const [tnotes2, setTNotes2] = useState('')
    const [tnotes3, setTNotes3] = useState('')
    const [tnotes4, setTNotes4] = useState('')
    const [tnotes5, setTNotes5] = useState('')

    // State of save buttons
    const [btn1, setBtn1] = useState(true)
    const [btn2, setBtn2] = useState(true)
    const [btn3, setBtn3] = useState(true)
    const [btn4, setBtn4] = useState(true)
    const [btn5, setBtn5] = useState(true)
    // States of teacher's name
    const [name1, setName1] = useState('')
    const [name2, setName2] = useState('')
    const [name3, setName3] = useState('')
    const [name4, setName4] = useState('')
    const [name5, setName5] = useState('')

    // State of Save or Update Button
    const [btnLetter1, setBtnLetter1] = useState("Save")
    const [btnLetter2, setBtnLetter2] = useState("Save")
    const [btnLetter3, setBtnLetter3] = useState("Save")
    const [btnLetter4, setBtnLetter4] = useState("Save")
    const [btnLetter5, setBtnLetter5] = useState("Save")

    const [btnState1, setBtnState1] = useState(false)
    const [btnState2, setBtnState2] = useState(false)
    const [btnState3, setBtnState3] = useState(false)
    const [btnState4, setBtnState4] = useState(false)
    const [btnState5, setBtnState5] = useState(false)




    const [hoverRange, setHoverRange] = useState(undefined)
    const [selectedDays, setSelectedDays] = useState([])
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [students, setStudents] = useState([])
    const [studentId, setStudentId] = useState(undefined)
    const [theme, setTheme] = useState('')
    const [babyClass, setBabyClass] = useState(false)
    const options = [
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00"
    ]
    const selectOptions = () => {
        return options.map((time, index) => {
            return (
                <option key={index}>
                    {time}
                </option>
            )
        })
    }

    const clearState = () => {
        setEmoji1(0)
        setEmoji2(0)
        setEmoji3(0)
        setEmoji4(0)
        setEmoji5(0)
        setParti1(0)
        setParti2(0)
        setParti3(0)
        setParti4(0)
        setParti5(0)
        setRespect1(0)
        setRespect2(0)
        setRespect3(0)
        setRespect4(0)
        setRespect5(0)
        setAppetite1(0)
        setAppetite2(0)
        setAppetite3(0)
        setAppetite4(0)
        setAppetite5(0)
        setSign1(false)
        setSign2(false)
        setSign3(false)
        setSign4(false)
        setSign5(false)
        setWash1(false)
        setWash2(false)
        setWash3(false)
        setWash4(false)
        setWash5(false)
        setFrom1("")
        setFrom2("")
        setFrom3("")
        setFrom4("")
        setFrom5("")
        setTo1("")
        setTo2("")
        setTo3("")
        setTo4("")
        setTo5("")
        setBabyFrom1("")
        setBabyFrom2("")
        setBabyFrom3("")
        setBabyFrom4("")
        setBabyFrom5("")
        setBabyTo1("")
        setBabyTo2("")
        setBabyTo3("")
        setBabyTo4("")
        setBabyTo5("")
        setNotes1("")
        setNotes2("")
        setNotes3('')
        setNotes4('')
        setNotes5('')
        setTNotes1("")
        setTNotes2("")
        setTNotes3('')
        setTNotes4('')
        setTNotes5('')
        setBtn1(true)
        setBtn2(true)
        setBtn3(true)
        setBtn4(true)
        setBtn5(true)
        setName1('')
        setName2('')
        setName3('')
        setName4('')
        setName5('')
        setDayDiff(undefined)
        setTheme('')
        setFirstMilkTime1("no milk")
        setFirstMilkTime2("no milk")
        setFirstMilkTime3("no milk")
        setFirstMilkTime4("no milk")
        setFirstMilkTime5("no milk")
        setSecondMilkTime1("no milk")
        setSecondMilkTime2("no milk")
        setSecondMilkTime3("no milk")
        setSecondMilkTime4("no milk")
        setSecondMilkTime5("no milk")
        setThirdMilkTime1("no milk")
        setThirdMilkTime2("no milk")
        setThirdMilkTime3("no milk")
        setThirdMilkTime4("no milk")
        setThirdMilkTime5("no milk")
        setFirstMilkQuantity1("no milk")
        setFirstMilkQuantity2("no milk")
        setFirstMilkQuantity3("no milk")
        setFirstMilkQuantity4("no milk")
        setFirstMilkQuantity5("no milk")
        setSecondMilkQuantity1("no milk")
        setSecondMilkQuantity2("no milk")
        setSecondMilkQuantity3("no milk")
        setSecondMilkQuantity4("no milk")
        setSecondMilkQuantity5("no milk")
        setThirdMilkQuantity1("no milk")
        setThirdMilkQuantity2("no milk")
        setThirdMilkQuantity3("no milk")
        setThirdMilkQuantity4("no milk")
        setThirdMilkQuantity5("no milk")
    }


    const handleDayChange = date => {
        if (moment() < getWeekRange(date).from) {
            toastr.warning("Please select valid week", "Date validation warning!")
            setDateValid(false)
            clearState()
            return false
        }
        setSelectedDays(getWeekDays(getWeekRange(date).from))
        setStartDate(getWeekRange(date).from)
        setEndDate(getWeekRange(date).to)
    };

    const handleDayEnter = date => {
        setHoverRange(getWeekRange(date))
    };

    const handleDayLeave = () => {
        setHoverRange(undefined)
    };

    const handleWeekClick = (weekNumber, days, e) => {
        setSelectedDays(days)
    };

    const daysAreSelected = selectedDays.length > 0;
    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const handleFindAgenda = (event, values) => {
        event.persist()
        if (values.group === "Select Group") {
            toastr.info("Please select a group", "Info")
            return false
        }

        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }

        if (!startDate) {
            toastr.info("Please select the week", "Info")
            return false
        }

        if (moment() < startDate) {
            toastr.warning("Please select valid date", "Date validation warning!")
            setDateValid(false)
            clearState()
            return false
        }

        setDateValid(true)
        setStudentId(values.student)
        delete values.group
        clearState()
        props.getAgendaData({ studentId: values.student, startDate: startDate.toString(), endDate: endDate.toString() })
    }


    const activityOptions = () => {
        if (props.students) {
            return props.students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const nap = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    {babyClass && (
                        <Fragment>
                            <span className="font-size-11">From: &nbsp;
                                {index === 0 && !btn1 ? babyFrom1 : index === 1 && !btn2 ? babyFrom2 : index === 2 && !btn3 ? babyFrom3 : index === 3 && !btn4 ? babyFrom4 : !btn5 && babyFrom5}
                            </span>&nbsp;-&nbsp;
                            <span className="font-size-11">To: &nbsp;
                                {index === 0 && !btn1 ? babyTo1 : index === 1 && !btn2 ? babyTo2 : index === 2 && !btn3 ? babyTo3 : index === 3 && !btn4 ? babyTo4 : !btn5 && babyTo5}
                            </span>
                            <br />
                        </Fragment>
                    )}
                    <span className="font-size-11">From: &nbsp;
                        {index === 0 && !btn1 ? from1 : index === 1 && !btn2 ? from2 : index === 2 && !btn3 ? from3 : index === 3 && !btn4 ? from4 : !btn5 && from5}
                    </span>&nbsp;-&nbsp;
                    <span className="font-size-11">To: &nbsp;
                        {index === 0 && !btn1 ? to1 : index === 1 && !btn2 ? to2 : index === 2 && !btn3 ? to3 : index === 3 && !btn4 ? to4 : !btn5 && to5}
                    </span>
                </Td>
            )
        })
    }



    const checkValidation = () => {
        if (!startDate) {
            toastr.info("Please select the week", "Info")
            return false
        } else if (!studentId) {
            toastr.info("Please select a student", "Info")
            return false
        } else {
            return true
        }
    }

    const activityChecks = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Row style={{ paddingLeft: "10px" }}>
                        <Checkbox
                            className={"standard-font"}
                            checked={index === 0 && !btn1 ? sign1 : index === 1 && !btn2 ? sign2 : index === 2 && !btn3 ? sign3 : index === 3 && !btn4 ? sign4 : !btn5 && sign5}
                            disabled={true}
                        >
                            Document to sign
                        </Checkbox>
                    </Row>
                    <Row style={{ paddingLeft: "10px" }}>
                        <Checkbox
                            className={"standard-font"}
                            checked={index === 0 && !btn1 ? wash1 : index === 1 && !btn2 ? wash2 : index === 2 && !btn3 ? wash3 : index === 3 && !btn4 ? wash4 : !btn5 && wash5}
                            disabled={true}
                        >
                            Clothes to wash
                        </Checkbox>
                    </Row>
                </Td>
            )
        })
    }

    const teacherNotes = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <textarea
                        value={index === 0 ? tnotes1 : index === 1 ? tnotes2 : index === 2 ? tnotes3 : index === 3 ? tnotes4 : tnotes5}
                        disabled={true}
                        className="form-control" name="" id="" cols="10" rows="6"
                    >
                    </textarea>
                </Td>
            )
        })
    }

    const parentNotes = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <textarea
                        value={index === 0 ? notes1 : index === 1 ? notes2 : index === 2 ? notes3 : index === 3 ? notes4 : notes5}
                        onChange={(e) => handleNotes(e.target.value, item)}
                        className="form-control" name="" id="" cols="10" rows="6"
                        disabled={index === 0 ? btn1 : index === 1 ? btn2 : index === 2 ? btn3 : index === 3 ? btn4 : btn5}
                    >
                    </textarea>
                </Td>
            )
        })
    }

    const handleNotes = (text, item) => {
        switch (item) {
            case 0:
                setNotes1(text)
                break;
            case 1:
                setNotes2(text)
                break;
            case 2:
                setNotes3(text)
                break;
            case 3:
                setNotes4(text)
                break;
            case 4:
                setNotes5(text)
                break;
            default:
                break;
        }
    }

    const teacherName = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <span
                    >
                        {index === 0 ? name1 : index === 1 ? name2 : index === 2 ? name3 : index === 3 ? name4 : name5}
                    </span>
                </Td>
            )
        })
    }


    const saveBtn = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <button
                        className="btn btn-success"
                        onClick={() => handleSave(item)}
                        disabled={index === 0 ? btn1 : index === 1 ? btn2 : index === 2 ? btn3 : index === 3 ? btn4 : btn5}
                    >
                        {index === 0 ? (
                            btnState1 ? <React.Fragment>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving...
                                </React.Fragment> : btnLetter1
                        ) :
                            index === 1 ? (
                                btnState2 ? <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                        Saving...
                                    </React.Fragment> : btnLetter2
                            ) :
                                index === 2 ? (
                                    btnState3 ? <React.Fragment>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            Saving...
                                        </React.Fragment> : btnLetter3
                                ) :
                                    index === 3 ? (
                                        btnState4 ? <React.Fragment>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                Saving...
                                            </React.Fragment> : btnLetter4
                                    ) :
                                        (btnState5 ? <React.Fragment>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            Saving...
                                        </React.Fragment> : btnLetter5)}
                    </button>
                </Td>
            )
        })
    }

    const handleSave = (order) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        let params = {
            order: order + 1,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        switch (order) {
            case 0:
                params.note = notes1
                setBtnState1(true)
                break;
            case 1:
                params.note = notes2
                setBtnState2(true)
                break;
            case 2:
                params.note = notes3
                setBtnState3(true)
                break;
            case 3:
                params.note = notes4
                setBtnState4(true)
                break;
            case 4:
                params.note = notes5
                setBtnState5(true)
                break;
            default:
                break;
        }
        const url = "/api/v1/agendas/release"
        releaseReport(params, url)
            .then((result) => {
                clearBtnState()
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                    props.getAgendaData({ studentId: studentId, startDate: startDate.toString(), endDate: endDate.toString() })
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                clearBtnState()
                toastr.error("Something went wrong", "Error")
            })
    }

    const clearBtnState = () => {
        setBtnState1(false)
        setBtnState2(false)
        setBtnState3(false)
        setBtnState4(false)
        setBtnState5(false)
    }

    const partiRate = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        size={20}
                        disabled={true}
                        value={index === 0 && !btn1 ? parti1 : index === 1 && !btn2 ? parti2 : index === 2 && !btn3 ? parti3 : index === 3 && !btn4 ? parti4 : !btn5 && parti5}
                    />
                </Td>
            )
        })
    }

    const respectTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        size={20}
                        disabled={true}
                        value={index === 0 && !btn1 ? respect1 : index === 1 && !btn2 ? respect2 : index === 2 && !btn3 ? respect3 : index === 3 && !btn4 ? respect4 : !btn5 && respect5}
                    />
                </Td>
            )
        })
    }

    const appetiteTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        size={20}
                        disabled={true}
                        value={index === 0 && !btn1 ? appetite1 : index === 1 && !btn2 ? appetite2 : index === 2 && !btn3 ? appetite3 : index === 3 && !btn4 ? appetite4 : !btn5 && appetite5}
                    />
                </Td>
            )
        })
    }

    const emojiTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    {index === 0 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry emoji-disabled ${emoji1 === 1 && !btn1 && "checked"}`}

                            >
                            </span>
                            <span
                                className={`fas fa-frown emoji-disabled ${emoji1 === 2 && !btn1 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-meh emoji-disabled ${emoji1 === 3 && !btn1 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-smile emoji-disabled ${emoji1 === 4 && !btn1 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh emoji-disabled ${emoji1 === 5 && !btn1 && "checked"}`}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 1 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry emoji-disabled ${emoji2 === 1 && !btn2 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-frown emoji-disabled ${emoji2 === 2 && !btn2 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-meh emoji-disabled ${emoji2 === 3 && !btn2 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-smile emoji-disabled ${emoji2 === 4 && !btn2 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh emoji-disabled ${emoji2 === 5 && !btn2 && "checked"}`}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 2 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry emoji-disabled ${emoji3 === 1 && !btn3 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-frown emoji-disabled ${emoji3 === 2 && !btn3 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-meh emoji-disabled ${emoji3 === 3 && !btn3 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-smile emoji-disabled ${emoji3 === 4 && !btn3 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh emoji-disabled ${emoji3 === 5 && !btn3 && "checked"}`}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 3 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry emoji-disabled ${emoji4 === 1 && !btn4 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-frown emoji-disabled ${emoji4 === 2 && !btn4 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-meh emoji-disabled ${emoji4 === 3 && !btn4 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-smile emoji-disabled ${emoji4 === 4 && !btn4 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh emoji-disabled ${emoji4 === 5 && !btn4 && "checked"}`}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 4 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry emoji-disabled ${emoji5 === 1 && !btn5 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-frown emoji-disabled ${emoji5 === 2 && !btn5 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-meh emoji-disabled ${emoji5 === 3 && !btn5 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-smile emoji-disabled ${emoji5 === 4 && !btn5 && "checked"}`}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh emoji-disabled ${emoji5 === 5 && !btn5 && "checked"}`}
                            >
                            </span>
                        </Fragment>
                    )}
                </Td>
            )
        })
    }

    const poopTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    {index === 0 && (
                        <Fragment>
                            <span
                                className={`fas fa-baby poop-disabled ${poop1 >= 1 && !btn1 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop1 >= 2 && !btn1 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop1 === 3 && !btn1 && "checked"}`}
                            />
                        </Fragment>
                    )}
                    {index === 1 && (
                        <Fragment>
                            <span
                                className={`fas fa-baby poop-disabled ${poop2 >= 1 && !btn2 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop2 >= 2 && !btn2 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop2 === 3 && !btn2 && "checked"}`}
                            />
                        </Fragment>
                    )}
                    {index === 2 && (
                        <Fragment>
                            <span
                                className={`fas fa-baby poop-disabled ${poop3 >= 1 && !btn3 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop3 >= 2 && !btn3 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop3 === 3 && !btn3 && "checked"}`}
                            />
                        </Fragment>
                    )}
                    {index === 3 && (
                        <Fragment>
                            <span
                                className={`fas fa-baby poop-disabled ${poop4 >= 1 && !btn4 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop4 >= 2 && !btn4 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop4 === 3 && !btn4 && "checked"}`}
                            />
                        </Fragment>
                    )}
                    {index === 4 && (
                        <Fragment>
                            <span
                                className={`fas fa-baby poop-disabled ${poop5 >= 1 && !btn5 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop5 >= 2 && !btn5 && "checked"}`}
                            />
                            <span
                                className={`fas fa-baby poop-disabled ${poop5 === 3 && !btn5 && "checked"}`}
                            />
                        </Fragment>
                    )}
                </Td>
            )
        })
    }

    const milkTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={item}>
                    <span className="milk">
                        {props.t('At')}:&nbsp;
                                {index === 0 && !btn1 ? firstMilkTime1 : index === 1 && !btn2 ? firstMilkTime2 : index === 2 && !btn3 ? firstMilkTime3 : index === 3 && !btn4 ? firstMilkTime4 : !btn5 && firstMilkTime5}
                                :&nbsp;=&nbsp;
                                {index === 0 && !btn1 ? firstMilkQuantity1 : index === 1 && !btn2 ? firstMilkQuantity2 : index === 2 && !btn3 ? firstMilkQuantity3 : index === 3 && !btn4 ? firstMilkQuantity4 : !btn5 && firstMilkQuantity5}
                    </span>
                    <br />
                    <span className="milk">
                        {props.t('At')}:&nbsp;
                                {index === 0 && !btn1 ? secondMilkTime1 : index === 1 && !btn2 ? secondMilkTime2 : index === 2 && !btn3 ? secondMilkTime3 : index === 3 && !btn4 ? secondMilkTime4 : !btn5 && secondMilkTime5}
                                :&nbsp;=&nbsp;
                                {index === 0 && !btn1 ? secondMilkQuantity1 : index === 1 && !btn2 ? secondMilkQuantity2 : index === 2 && !btn3 ? secondMilkQuantity3 : index === 3 && !btn4 ? secondMilkQuantity4 : !btn5 && secondMilkQuantity5}
                    </span>
                    <br />
                    <span className="milk">
                        {props.t('At')}:&nbsp;
                                {index === 0 && !btn1 ? thirdMilkTime1 : index === 1 && !btn2 ? thirdMilkTime2 : index === 2 && !btn3 ? thirdMilkTime3 : index === 3 && !btn4 ? thirdMilkTime4 : !btn5 && thirdMilkTime5}
                                :&nbsp;=&nbsp;
                                {index === 0 && !btn1 ? thirdMilkQuantity1 : index === 1 && !btn2 ? thirdMilkQuantity2 : index === 2 && !btn3 ? thirdMilkQuantity3 : index === 3 && !btn4 ? thirdMilkQuantity4 : !btn5 && thirdMilkQuantity5}
                    </span>
                </Td>
            )
        })
    }


    const getGroup = (e) => {
        const url = "/api/v1/agendas/check_group"
        checkGroup(url, { student_id: parseInt(e.target.value) })
            .then((result) => {
                if (result.status === 200) {
                    setBabyClass(result.data.is_baby)
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Daily Activities Communication Agenda')} breadcrumbItem={props.t('Agenda')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <StyletronProvider value={engine}>
                                        <BaseProvider theme={LightTheme}>
                                            <Row className={"mb-3"}>
                                                <Col xs={12} sm={6} className="SelectedWeekExample">
                                                    <DayPicker
                                                        selectedDays={selectedDays}
                                                        showWeekNumbers
                                                        showOutsideDays
                                                        modifiers={modifiers}
                                                        onDayClick={handleDayChange}
                                                        onDayMouseEnter={handleDayEnter}
                                                        onDayMouseLeave={handleDayLeave}
                                                        onWeekClick={handleWeekClick}
                                                    />
                                                </Col>
                                                <Col xs={12} sm={6}>
                                                    <Row>
                                                        <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} onValidSubmit={(e, v) => { handleFindAgenda(e, v) }}>
                                                            <Col xs={12}>
                                                                <div className="form-group">
                                                                    <label>Select Your Child:</label>
                                                                    <AvField type="select" value={"Select Student"} name="student" validate={{ required: { value: true } }} onChange={(e) => getGroup(e)}>
                                                                        <option disabled={true}>Select Student</option>
                                                                        {activityOptions()}
                                                                    </AvField>
                                                                </div>
                                                            </Col>

                                                            <Col xs={12} className={"mr-0"}>
                                                                <FormGroup className="mb-0">
                                                                    <div>
                                                                        <Button type="submit" color="primary" className="mr-1" style={{ width: "100%" }} disabled={props.loading}>
                                                                            {props.loading ? (
                                                                                <React.Fragment>
                                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                                    Finding
                                                                                </React.Fragment>
                                                                            ) : "Find"}
                                                                        </Button>
                                                                    </div>
                                                                </FormGroup>
                                                            </Col>
                                                        </AvForm>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {props.agenda && dateValid && (
                                                <Row>
                                                    <Col className="text-center">
                                                        <h4>Theme of the week: <b style={{ color: "blue" }}>{theme}</b></h4>
                                                    </Col>
                                                    <Table id="tech-companies-1" className="mt-3 table table-striped table-bordered">
                                                        <Thead>
                                                            <Tr style={{ backgroundColor: "#A4C2F4", color: "Black" }} className={"table-header"}>
                                                                <Th data-priority="1" style={{ backgroundColor: "white", width: "150px" }}>Week From:
                                                                    {selectedDays.length === 7 && (
                                                                        <div>
                                                                            {moment(selectedDays[0]).format('MM-DD-YYYY')} :{' '} <br />
                                                                            {moment(selectedDays[6]).format('MM-DD-YYYY')}
                                                                        </div>
                                                                    )}
                                                                </Th>
                                                                <Th className={dayDiff === 1 ? "diff-background" : ""} data-priority="1">{props.t('Monday')}</Th>
                                                                <Th className={dayDiff === 2 ? "diff-background" : ""} data-priority="1">{props.t('Tuesday')}</Th>
                                                                <Th className={dayDiff === 3 ? "diff-background" : ""} data-priority="1">{props.t('Wednesday')}</Th>
                                                                <Th className={dayDiff === 4 ? "diff-background" : ""} data-priority="1">{props.t('Thursday')}</Th>
                                                                <Th className={dayDiff === 5 ? "diff-background" : ""} data-priority="1">{props.t('Friday')}</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td className={"text-left"}><strong>{props.t('My Mood')}</strong></Td>
                                                                {emojiTd()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className={"text-left"}><strong>{props.t('My participation')}</strong></Td>
                                                                {partiRate()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className={"text-left"}><strong>{props.t('My respect for the instructions')}</strong></Td>
                                                                {respectTd()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('My appetite')}</strong></Td>
                                                                {appetiteTd()}
                                                            </Tr>
                                                            {babyClass && (
                                                                <Fragment>
                                                                    <Tr>
                                                                        <Td className="text-left"><strong>{props.t('My poop')}</strong></Td>
                                                                        {poopTd()}
                                                                    </Tr>
                                                                    <Tr>
                                                                        <Td className="text-left"><strong>{props.t('Milk')}</strong></Td>
                                                                        {milkTd()}
                                                                    </Tr>
                                                                </Fragment>
                                                            )}
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('Nap')}</strong></Td>
                                                                {nap()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left">
                                                                    <strong>{props.t('To note')}</strong>
                                                                </Td>
                                                                {activityChecks()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('Note from the educator')}</strong></Td>
                                                                {teacherNotes()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('Parents initials or note')}</strong></Td>
                                                                {parentNotes()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('Name of educator')}</strong></Td>
                                                                {teacherName()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('Save or Update')}</strong></Td>
                                                                {saveBtn()}
                                                            </Tr>
                                                        </Tbody>
                                                    </Table>
                                                </Row>
                                            )}
                                        </BaseProvider>
                                    </StyletronProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { students } = state.ReportAttReducer;
    const { agenda, loading } = state.AgendaReducer;
    return { students, agenda, loading };
}

export default withRouter(connect(mapStateToProps, { getStudentsList, getAgendaData })(withNamespaces()(TeacherAgenda)));
