import React from "react";
import {Button, Card, CardBody, Col, FormGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";

const ChangePasswordModal = (props) => {
  function handleChangePassword(event, values) {
    event.persist();
    props.changePassword(values);
  }
  return(
    <Modal
      isOpen={props.isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      tabIndex="-1"
      toggle={() => {
        props.close(!props.isOpen);
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            props.close(!props.isOpen);
          }}
        >
          Change Password
        </ModalHeader>
        <ModalBody>
          <Col sm="12">
            <Card>
              <CardBody className="mb-0">
                <AvForm
                  onValidSubmit={(e, v) => {
                    handleChangePassword(e, v);
                  }}
                >
                  <AvField
                    name="password"
                    label="New Password"
                    type="password"
                    placeholder="New Password"
                    errorMessage="Enter password"
                    validate={{
                      required: { value: true },
                      minLength: {value: 6, errorMessage: 'Password must be at least 6 chars'},
                    }}
                  />
                  <AvField
                    name="password_confirmation"
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm Password"
                    errorMessage="Enter Correct Re-password"
                    validate={{
                      required: { value: true },
                      match: { value: "password" },
                    }}
                  />
                  <FormGroup className="mb-0 mt-5">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="mr-1"
                        disabled={props.loading}
                      >
                        {props.loading ? (
                          <React.Fragment>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                            Saving...
                          </React.Fragment>
                        ) : "Set new password"}
                      </Button>{" "}
                      <Button
                        type="button"
                        color="primary"
                        className="float-right"
                        onClick={() => props.close(!props.isOpen)}
                      >Cancel</Button>
                    </div>
                  </FormGroup>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </ModalBody>
      </div>
    </Modal>
  )
}

export default ChangePasswordModal;