import {
    GET_REPORT_ACTIVITY_LIST,
    GET_REPORT_ACTIVITY_LIST_SUCCESS,
    GET_REPORT_ACTIVITY_LIST_FAILED
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    allLists: undefined
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_REPORT_ACTIVITY_LIST:
            state = {
                ...state,
                loading: true,
                allLists: undefined
            }
            break;
        case GET_REPORT_ACTIVITY_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                allLists: action.payload
            }
            break;
        case GET_REPORT_ACTIVITY_LIST_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                allLists: undefined
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;