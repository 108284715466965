import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, CardBody, FormGroup, Label } from "reactstrap";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getAllReportItems, getGroupList } from '../../store/actions';
import Select from "react-select";
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from "availity-reactstrap-validation";
import {createReportItem, createActivity, callActivity, saveSubactivity} from "../../apiHelpers/schoolReportApiHelper";
import toastr from "toastr";




const CreateSchoolReport = (props) => {


    useEffect(() => {
        props.getAllReportItems();
        props.getGroupList();
    }, [])

    const [rows1, setrows1] = useState([]);

    const [activities, setActivities] = useState([]);
    const [reportItemId, setReportItemId] = useState(undefined);
    const [subAct, setSubAct] = useState("Select");
    const [subList, SetSubList] = useState([]);

    const [cws, setCws] = useState(false);
    const [cas, setCas] = useState(false);
    const [csas, setCsas] = useState(false);

    const [selectedActivity, setSelectedActivity] = useState('Select');

    function handleAddRowNested(){
        const item1 = { name1: "" };
        setrows1([...rows1, item1]);
    };

    function  handleRemoveRowNested(e, idx)
    {
        document.getElementById("nested" + idx).remove();
    };


    const handleReportItem = (event, values) => {
        event.persist();
        setCws(true);
        // if(values.group === "Select Group") {
        //     toastr.warning("Please select the group", "Warning");
        //     return false;
        // }
        createReportItem(values).then((result) => {
            setCws(false)
            if(result.status === 200) {
                props.getAllReportItems()
                toastr.success(result.data.message, "Success")
            } else {
                toastr.error("It seems that this title informed already exists","Error")
            }
        })
        .catch(error => {
            setCws(false)
           toastr.error("Failed to create a report item!", "Error")
        })
    }

    const handleActivity = (event, values) => {
        event.persist()
        if (values.report_item === "Select") {
            toastr.warning("Please select a Report Item", "Warning")
            return false
        }
        setCas(true)
        createActivity(values)
            .then((result) => {
                setCas(false)
                if(result.status === 200) {
                    props.getAllReportItems()
                    if(reportItemId) {
                        callingActivity(reportItemId)
                    }
                    toastr.success(result.data.message, "Success")
                    refreshPage()
                } else {
                    toastr.error("It seems the Activity name informed already exists","Error")
                }
            })
            .catch(error => {
                setCas(false)
                toastr.error("Failed to create a workbook!", "Error")
            })
    }

    const handleSubActivity = (event, values) => {
        event.persist()
        if (values.workbook === "Select") {
            toastr.info("Please select a report item", "Info")
            return false
        }
        if (values.activity === "Select") {
            toastr.info("Please select an activity", "Info")
            return false
        }


        const activity_id = values.activity
        delete values.workbook
        delete values.activity
        let sub_activities = Object.keys(values).map((key) => values[key])
        sub_activities = sub_activities.filter(Boolean)
        const sub_hook = sub_activities
        sub_activities = sub_activities.filter(val => !subList.includes(val));
        if(sub_activities.length === 0) {
            toastr.info("These values are already saved. Please input others!", "Info")
            return false
        }
        setCsas(true)
        saveSubactivity({activity_id: activity_id, subs: sub_activities})
            .then((result) => {
                setCsas(false)
                if(result.status === 200) {
                    // props.getAllReportItems()
                    SetSubList(sub_hook)
                    toastr.success(result.data.message, "Success")
                    refreshPage()
                } else {
                    toastr.error(`It seems the sub-activity name: ${result.data.data} already exists`,"Error")
                    refreshPage()
                }
            })
            .catch((error) => {
                setCsas(false)
                toastr.error("Something went wrong!", "Error")
            })
    }

    const refreshPage = () => {
        setTimeout(() => {
            window.location.reload();
        }, 1000)
    }

    const getActivity = (e) => {
        setActivities([])
        setReportItemId(e.target.value)
        callActivity({report_item_id: e.target.value})
            .then((result) => {
                if (result.status === 200) {
                    setActivities(JSON.parse(result.data.activities))
                    setSelectedActivity(JSON.parse(result.data.activities)[0].id)
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.warning("Something went wrong", "Error")
            })
    }

    const callingActivity = (id) => {
        setActivities([])
        callActivity({report_item_id: id})
            .then((result) => {
                if (result.status === 200) {
                    setActivities(JSON.parse(result.data.activities))
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.warning("Something went wrong", "Error")
            })
    }

    const options = () => {
        if(props.reportItems) {
            return props.reportItems.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.title}
                    </option>
                )
            })
        }
    }

    const activityOptions = () => {
        if(activities) {
            return activities.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    const groupOptions = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('School Report - Create')} breadcrumbItem={props.t('School-Report')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={12} sm={6}>
                                            <h5 className="mb-3">1. Create School Report</h5>
                                            <AvForm onValidSubmit={(e,v) => { handleReportItem(e,v)}}>
                                                <AvField
                                                    name="title"
                                                    label="Title"
                                                    placeholder="Title"
                                                    type="text"
                                                    errorMessage="Enter Title"
                                                    value=""
                                                    validate={{ required: { value: true } }}
                                                />
                                                <FormGroup className="mb-0">
                                                    <div className="form-group row">
                                                        <Col sm={12} md={8}>
                                                            <AvField
                                                                type="select"
                                                                value={"Select Group"}
                                                                name="group"
                                                                validate={{ required: { value: true } }}
                                                                label="This workbook belongs to:"
                                                            >
                                                                <option disabled={true}>Select Group</option>
                                                                {groupOptions()}
                                                            </AvField>
                                                        </Col>
                                                        <Col sm={12} md={4} className="report-item-save">
                                                            <Button type="submit" color="primary" className="mr-1 float-right" disabled={cws}>
                                                                {cws ? (
                                                                    <React.Fragment>
                                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                        Saving
                                                                    </React.Fragment>
                                                                ): "Save"}
                                                            </Button>{" "}
                                                        </Col>
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                            <h5 className="mb-3" style={{marginTop: "70px"}}>2. Create School Report Item</h5>

                                            <AvForm onValidSubmit={(e,v) => {handleActivity(e,v) }}>
                                                <AvField type="select" value={"Select"} name="report_item" label="Select School Report" validate={{ required: { value: true } }}>
                                                    <option disabled={true}>Select</option>
                                                    {options()}
                                                </AvField>
                                                <AvField
                                                    name="name"
                                                    label="Activity Title"
                                                    placeholder="Activity Name"
                                                    type="text"
                                                    errorMessage="Enter Name"
                                                    value=""
                                                    validate={{ required: { value: true } }}
                                                />
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <Button type="submit" color="primary" className="mr-1 float-right" disabled={cas}>
                                                            {cas ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Saving
                                                                </React.Fragment>
                                                            ): "Save"}
                                                        </Button>{" "}
                                                    </div>
                                                </FormGroup>
                                            </AvForm>
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <h5>3. Create School Report Sub-Acitivity</h5>
                                                <AvForm  className="outer-repeater" onValidSubmit={(e,v) => {handleSubActivity(e,v) }}>
                                                <div data-repeater-list="outer-group" className="outer">
                                                    <div data-repeater-item className="outer">
                                                        <div className="form-group">
                                                            <AvField
                                                                 type="select"
                                                                 value={subAct}
                                                                 name="workbook"
                                                                 label="Select School Report"
                                                                 onChange={getActivity}
                                                            >
                                                                <option disabled={true}>Select</option>
                                                                {options()}
                                                            </AvField>
                                                        </div>

                                                        <div className="form-group">
                                                            <AvField type="select" value={selectedActivity} name="activity" label="Select Activity" validate={{ required: { value: true } }}>
                                                                <option disabled={true}>Select</option>
                                                                {activityOptions()}
                                                            </AvField>
                                                        </div>

                                                        <div className="inner-repeater mb-4">
                                                            <Label>Sub-Activity Title:</Label>
                                                            <table style={{ width: "100%" }}>
                                                                <tbody>
                                                                <tr id="addrMain" key="">
                                                                    <td>
                                                                        <Row className="mb-2">
                                                                            <Col md="9">
                                                                                <AvField
                                                                                    name="title0"
                                                                                    placeholder="Add sub-activity"
                                                                                    type="text"
                                                                                    errorMessage="Enter Title"
                                                                                    value=""
                                                                                    validate={{ required: { value: true } }}
                                                                                />
                                                                            </Col>
                                                                            <Col md="3">
                                                                                <Button
                                                                                    color="danger"
                                                                                    // className="btn-block inner"
                                                                                    // style={{ width: "100%" }}
                                                                                    onClick={(e) => { e.preventDefault() } }
                                                                                >
                                                                                    <i className="fas fa-minus"></i>
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>

                                                                {rows1.map((item1, idx) => (
                                                                    <tr id={"nested" + idx} key={idx}>
                                                                        <td>
                                                                            <Row className="mb-2">
                                                                                <Col md="9">
                                                                                    <AvField
                                                                                        name={`title` + (idx + 1)}
                                                                                        placeholder="Add sub-activity"
                                                                                        type="text"
                                                                                        errorMessage="Enter Title"
                                                                                        value=""
                                                                                        // onChange={(e) => {setName(e.value)}}
                                                                                        // validate={{ required: { value: true } }}
                                                                                    />
                                                                                </Col>
                                                                                <Col md="3">
                                                                                    <Button
                                                                                        onClick={(e) => { handleRemoveRowNested(e, idx) }
                                                                                        }
                                                                                        color="danger"
                                                                                        // className="btn-block inner"
                                                                                        // style={{ width: "100%" }}
                                                                                    >
                                                                                        <i className="fas fa-minus"></i>
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>
                                                            <Button
                                                                onClick={() => { handleAddRowNested() }}
                                                                color="success"
                                                                className="mt-1"
                                                            >
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </div>
                                                        <Button type="submit" color="primary" className="float-right" disabled={csas}>
                                                            {csas ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Saving
                                                                </React.Fragment>
                                                            ): "Save"}
                                                        </Button>
                                                    </div>
                                                </div>
                                                </AvForm>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { groups } = state.ActivityReducer;
    const { loading, error, reportItems, activities} = state.SchoolReportReducer;
    return { loading, error, reportItems, activities, groups };
}

export default withRouter(connect(mapStateToProps, { getAllReportItems, getGroupList })(withNamespaces()(CreateSchoolReport)));
