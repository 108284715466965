import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { RESET_PWD } from './actionTypes';
import { resetPwdError, resetPwdSuccess } from './actions';
import { passwordReset  } from '../../../apiHelpers/authHelper';
import toastr from "toastr";

function* resetPwd({ payload: { password, history } }) {
    try {
        const response = yield call(passwordReset, '/api/v1/auth/password', {password: password.password, password_confirmation: password.password_confirmation});
        yield put(resetPwdSuccess(response.data))
        if (response.status === 200) {
            toastr.success(response.data.message, "Success")
            setTimeout(() => {
                window.location.href = "/login"
            }, 2000)
        }

    } catch (error) {
        yield put(resetPwdError(error));
    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PWD, resetPwd)
}

function* resetPwdSaga() {
    yield all([
        fork(watchResetPassword)
    ]);
}

export default resetPwdSaga;