export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';
export const GET_ALL_GROUPS = 'GET_ALL_GROUPS';
export const GET_ALL_GROUPS_SUCCESS = 'GET_ALL_GROUPS_SUCCESS';
export const GET_ALL_GROUPS_FAILED = 'GET_ALL_GROUPS_FAILED';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';
export const ALL_GROUPS = 'ALL_GROUPS';
export const GET_TEACHERS = 'GET_TEACHERS';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_ONE_GROUP = 'GET_ONE_GROUP';
export const GET_ONE_GROUP_SUCCESS = 'GET_ONE_GROUP_SUCCESS';
export const GET_ONE_GROUP_FAILED = 'GET_ONE_GROUP_FAILED';

