import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_WORKBOOK, GET_REPORT_ITEMS, GET_GROUPS } from './actionTypes';
import {getAllReportItemsSuccess, getAllReportItemsFailed, getAllReportActivitiesSuccess, getAllReportActivitiesFailed, getGroupListSuccess} from './actions';
import { getAllActivities } from '../../../apiHelpers/schoolReportApiHelper';
import toastr from 'toastr';
import { getStoreGroups, getStoreTotal } from './selectors';

function* getReportsItems() {
    try {
        const response = yield call(getAllActivities, '/api/v1/report_items');
        if(response.status === 200) {
            yield put(getAllReportItemsSuccess(JSON.parse(response.data.report_items)));
            yield put(getAllReportActivitiesSuccess(JSON.parse(response.data.activities)));
        } else {
            yield put(getAllReportItemsFailed(response.status));
        }

    } catch (error) {
        yield put(getAllReportItemsFailed(error));
    }
}

// function* getGroups() {
//     try {
//         const response = yield call(getAllActivities, '/api/v1/get_groups');
//         if(response.status === 200) {
//             yield put(getGroupListSuccess(JSON.parse(response.data.groups)));
//             yield put(getAllWorkbookSuccess(JSON.parse(response.data.workbooks)));
//         } else {
//             // toastr.error("Something went wrong","Error")
//         }
//
//     } catch (error) {
//         toastr.error("Something went wrong","Error")
//     }
// }


export function* watchGetWorkbooks() {
    yield takeEvery(GET_REPORT_ITEMS, getReportsItems)
}

// export function* watchGetGroups() {
//     yield takeEvery(GET_GROUPS, getGroups)
// }



function* activitySaga() {
    yield all([
        fork(watchGetWorkbooks),
        // fork(watchGetGroups)
    ]);
}

export default activitySaga;