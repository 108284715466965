import {
    GET_ACTIVITY_LIST,
    GET_ACTIVITY_LIST_SUCCESS,
    GET_ACTIVITY_LIST_FAILED
} from './actionTypes';

export const getAllActivityLists = () => {
    return {
        type: GET_ACTIVITY_LIST,
    }
}

export const getActivityListSuccess = (lists) => {
    return {
        type: GET_ACTIVITY_LIST_SUCCESS,
        payload: lists
    }
}

export const getActivityListFailed = (error) => {
    return {
        type: GET_ACTIVITY_LIST_FAILED,
        payload: error
    }
}



