import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { GET_NOTIFICATIONS } from './actionTypes';
import { getNotificationsSuccess, getNotificationCount } from './actions';
import { getNotifications } from '../../../apiHelpers/notificationApiHelper';

function* getAllNotifications() {
    try {
        const response = yield call(getNotifications);
        if(response.status === 200) {
            yield put(getNotificationsSuccess(JSON.parse(response.data.notifications)));
            yield put(getNotificationCount(response.data.count));
        }
    } catch (error) {
        throw error.response
    }
}

export function* watchGetAllNotifications() {
    yield takeEvery(GET_NOTIFICATIONS, getAllNotifications)
}

function* getNotificationsSaga() {
    yield all([
        fork(watchGetAllNotifications)
    ]);
}

export default getNotificationsSaga;