import { RESET_PWD, RESET_PWD_SUCCESS, RESET_PWD_ERROR } from './actionTypes';

export const resetPwd = (password, history) => {
    return {
        type: RESET_PWD,
        payload: { password, history }
    }
}

export const resetPwdSuccess = (user) => {
    return {
        type: RESET_PWD_SUCCESS,
        payload: user
    }
}

export const resetPwdError = (error) => {
    return {
        type: RESET_PWD_ERROR,
        payload: error
    }
}
