import {
    GET_ATT_STUDENTS,
    GET_ATT_STUDENTS_SUCCESS,
    GET_ATT_STUDENTS_FAILED,
    CREATE_ATTENDANCE,
    CREATE_ATTENDANCE_SUCCESS,
    CREATE_ATTENDANCE_FAILED,
    UPDATE_ATTENDANCE,
    UPDATE_ATTENDANCE_SUCCESS,
    UPDATE_ATTENDANCE_FAILED
} from './actionTypes';

export const getAttStudents = (data) => {
    return {
        type: GET_ATT_STUDENTS,
        payload: data
    }
}

export const getAttStudentsSuccess = (students) => {
    return {
        type: GET_ATT_STUDENTS_SUCCESS,
        payload: students
    }
}

export const getAttStudentsFailed = (error) => {
    return {
        type: GET_ATT_STUDENTS_FAILED,
        payload: error
    }
}

export const createAttendance = (data) => {
    return {
        type: CREATE_ATTENDANCE,
        payload: data
    }
}

export const createAttendanceSuccess = () => {
    return {
        type: CREATE_ATTENDANCE_SUCCESS
    }
}

export const createAttendanceFailed = (error) => {
    return {
        type: CREATE_ATTENDANCE_FAILED,
        payload: error
    }
}




