import {
    GET_ALL_STUDENTS,
    GET_ALL_STUDENTS_SUCCESS,
    GET_ALL_STUDENTS_FAILED,
    CREATE_STUDENT,
    CREATE_STUDENT_SUCCESS,
    CREATE_STUDENT_FAILED,
    UPDATE_STUDENT,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAILED,
    ALL_STUDENTS
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    students: [],
    studentsCount: undefined,
    modal: undefined,
    editModal: undefined,
}

const students = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_STUDENT:
            state = {
                ...state,
                loading: true,
                modal: "start"
            }
            break;
        case CREATE_STUDENT_SUCCESS:
            state = {
                ...state,
                loading: false,
                modal: "success"
            }
            break;
        case CREATE_STUDENT_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                modal: "failed"
            };
            break;
        case GET_ALL_STUDENTS:
            state = {
                ...state,
                loading: true
            };
            break;
        case GET_ALL_STUDENTS_SUCCESS:
            state = {
                ...state,
                loading: false,
                students: action.payload
            };
            break;
        case GET_ALL_STUDENTS_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            };
            break;
        case ALL_STUDENTS:
            state = {
                ...state,
                loading: false,
                studentsCount: action.payload,
            };
            break;
        case UPDATE_STUDENT:
            state = {
                ...state,
                loading: true,
                editModal: "start"
            };
            break;
        case UPDATE_STUDENT_SUCCESS:
            state = {
                ...state,
                loading: false,
                editModal: "success" };
            break;
        case UPDATE_STUDENT_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload,
                editModal: "failed"
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default students;