const firebaseConfig = {
    apiKey: "AIzaSyCB0tIDTjmi9QAfZGX8mtZal4Hgm1t9-Yo",
    authDomain: "lile-des-petits.firebaseapp.com",
    databaseURL: "https://lile-des-petits.firebaseio.com",
    projectId: "lile-des-petits",
    storageBucket: "lile-des-petits.appspot.com",
    messagingSenderId: "1005578463346",
    appId: "1:1005578463346:web:07ef17b90b8a14ca767aeb"
};

export { firebaseConfig }