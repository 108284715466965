import {
    CREATE_USER,
    CREATE_USER_SUCCESS,
    GET_ALL_USERS,
    GET_ALL_USERS_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    ALL_USERS,
    USER_API_ERROR,
    EDIT_USER_FAILED
} from './actionTypes';
import {API_ERROR} from "../../auth/login/actionTypes";

export const getAllUsers = (page) => {
    return {
        type: GET_ALL_USERS,
        payload: page
    }
}

export const getAllUsersSuccess = (users) => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload: users,
    }
}

export const totalUsers = (total) => {
    return {
        type: ALL_USERS,
        payload: total
    }
}

export const createUser = (user) => {
    return {
        type: CREATE_USER,
        payload: { user }
    }
}

export const createUserSuccess = () => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: {}
    }
}

export const editUserInfo = (newUser) => {
    return {
        type: UPDATE_USER,
        payload: { newUser }
    }
}

export const updateUserSuccess = () => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: {}
    }
}

export const deleteUser = (value) => {
    return {
        type: DELETE_USER,
        payload: {value}
    }
}

export const deleteUserSuccess = () => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: {}
    }
}

export const userApiError = (error) => {
    return {
        type: USER_API_ERROR,
        payload: error
    }
}

export const editUserError = (error) => {
    return {
        type: EDIT_USER_FAILED,
        payload: error
    }
}

