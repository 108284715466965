import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Button, Card, CardBody, FormGroup } from "reactstrap";
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';
import diaperY from '../../assets/images/diaper-yellow.png';
import diaperG from '../../assets/images/diaper-gray.png';


import { getStudentsList, getGroupList, getAgendaData } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { EmoticonRating, StarRating } from 'baseui/rating';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { LightTheme, BaseProvider } from 'baseui';
import { Checkbox, DatePicker, Rate, Input } from 'antd';
import moment from "moment";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { AvForm, AvField } from "availity-reactstrap-validation";
import { submitEmoji, submitPoop, submitRates, submitNapTime, releaseReport, saveTheme, submitMilkInfo } from "../../apiHelpers/agendaApiHelper";
import toastr from "toastr";
import { callStudents, findActivities } from "../../apiHelpers/activityApiHelper";
import { warning } from "@ant-design/icons/es/utils";


const engine = new Styletron();
const { RangePicker } = DatePicker;

const customIcons = {
    1: <i className="fas fa-baby"></i>,
    2: <i className="fas fa-baby"></i>,
    3: <i className="fas fa-baby"></i>
};

function getWeekDays(weekStart) {
    const days = [weekStart];
    for (let i = 1; i < 7; i += 1) {
        days.push(
            moment(weekStart)
                .add(i, 'days')
                .toDate()
        );
    }
    return days;
}

function getWeekRange(date) {
    return {
        from: moment(date)
            .startOf('week')
            .toDate(),
        to: moment(date)
            .endOf('week')
            .toDate(),
    };
}

const loopValue = [0, 1, 2, 3, 4]


const TeacherAgenda = (props) => {


    useEffect(() => {
        props.getStudentsList()
        props.getGroupList()
    }, [])

    useEffect(() => {
        if (props.agenda && props.agenda.length !== 0) {
            props.agenda.forEach((item, index) => {
                let localTime = moment.utc(item.submitted_at).format("ll")
                let diff = moment(localTime).diff(startDate, 'day') - 1
                switch (diff) {
                    case 0:
                        if (item.mood) {
                            setEmoji1(item.mood)
                        }
                        if (item.participation) {
                            setParti1(item.participation)
                        }
                        if (item.respect) {
                            setRespect1(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite1(item.appetite)
                        }
                        if (item.poop) {
                            setPoop1(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign1(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash1(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom1(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo1(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom1(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo1(item.baby_nap_end)
                        }
                        if (item.teacher_note) {
                            setNotes1(item.teacher_note)
                            setBtnLetter1("Update")
                        }
                        if (item.parent_note) {
                            setTNotes1(item.parent_note)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                            setISSave(false)
                        }
                        if (item.teacher_name) {
                            setName1(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime1(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime1(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime1(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity1(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity1(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity1(item.third_milk_quantity)
                        }
                        break;
                    case 1:
                        if (item.mood) {
                            setEmoji2(item.mood)
                        }
                        if (item.participation) {
                            setParti2(item.participation)
                        }
                        if (item.respect) {
                            setRespect2(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite2(item.appetite)
                        }
                        if (item.poop) {
                            setPoop2(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign2(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash2(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom2(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo2(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom2(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo2(item.baby_nap_end)
                        }
                        if (item.teacher_note) {
                            setNotes2(item.teacher_note)
                            setBtnLetter2("Update")
                        }
                        if (item.parent_note) {
                            setTNotes2(item.parent_note)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                            setISSave(false)
                        }
                        if (item.teacher_name) {
                            setName2(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime2(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime2(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime2(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity2(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity2(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity2(item.third_milk_quantity)
                        }
                        break;
                    case 2:
                        if (item.mood) {
                            setEmoji3(item.mood)
                        }
                        if (item.participation) {
                            setParti3(item.participation)
                        }
                        if (item.respect) {
                            setRespect3(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite3(item.appetite)
                        }
                        if (item.poop) {
                            setPoop3(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign3(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash3(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom3(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo3(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom3(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo3(item.baby_nap_end)
                        }
                        if (item.teacher_note) {
                            setNotes3(item.teacher_note)
                            setBtnLetter3("Update")
                        }
                        if (item.parent_note) {
                            setTNotes3(item.parent_note)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                            setISSave(false)
                        }
                        if (item.teacher_name) {
                            setName3(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime3(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime3(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime3(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity3(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity3(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity3(item.third_milk_quantity)
                        }
                        break;
                    case 3:
                        if (item.mood) {
                            setEmoji4(item.mood)
                        }
                        if (item.participation) {
                            setParti4(item.participation)
                        }
                        if (item.respect) {
                            setRespect4(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite4(item.appetite)
                        }
                        if (item.poop) {
                            setPoop4(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign4(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash4(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom4(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo4(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom4(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo4(item.baby_nap_end)
                        }
                        if (item.teacher_note) {
                            setNotes4(item.teacher_note)
                            setBtnLetter4("Update")
                        }
                        if (item.parent_note) {
                            setTNotes4(item.parent_note)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                            setISSave(false)
                        }
                        if (item.teacher_name) {
                            setName4(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime4(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime4(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime4(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity4(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity4(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity4(item.third_milk_quantity)
                        }
                        break;
                    case 4:
                        if (item.mood) {
                            setEmoji5(item.mood)
                        }
                        if (item.participation) {
                            setParti5(item.participation)
                        }
                        if (item.respect) {
                            setRespect5(item.respect)
                        }
                        if (item.appetite) {
                            setAppetite5(item.appetite)
                        }
                        if (item.poop) {
                            setPoop5(item.poop)
                        }
                        if (item.doc_sign) {
                            setSign5(item.doc_sign)
                        }
                        if (item.wash) {
                            setWash5(item.wash)
                        }
                        if (item.nap_start) {
                            setFrom5(item.nap_start)
                        }
                        if (item.nap_end) {
                            setTo5(item.nap_end)
                        }
                        if (item.baby_nap_start) {
                            setBabyFrom5(item.baby_nap_start)
                        }
                        if (item.baby_nap_end) {
                            setBabyTo5(item.baby_nap_end)
                        }
                        if (item.teacher_note) {
                            setNotes5(item.teacher_note)
                            setBtnLetter5("Update")
                        }
                        if (item.parent_note) {
                            setTNotes5(item.parent_note)
                        }
                        if (item.theme_of_week) {
                            setTheme(item.theme_of_week)
                            setISSave(false)
                        }
                        if (item.teacher_name) {
                            setName5(item.teacher_name)
                        }
                        if (item.first_milk_time) {
                            setFirstMilkTime5(item.first_milk_time)
                        }
                        if (item.second_milk_time) {
                            setSecondMilkTime5(item.second_milk_time)
                        }
                        if (item.third_milk_time) {
                            setThirdMilkTime5(item.third_milk_time)
                        }
                        if (item.first_milk_quantity) {
                            setFirstMilkQuantity5(item.first_milk_quantity)
                        }
                        if (item.second_milk_quantity) {
                            setSecondMilkQuantity5(item.second_milk_quantity)
                        }
                        if (item.third_milk_quantity) {
                            setThirdMilkQuantity5(item.third_milk_quantity)
                        }
                        break;
                    default:
                        break;
                }
            })
        }

        const today = moment()
        const diff = today.diff(startDate, 'day')
        setDayDiff(diff)
    }, [props.agenda])

    const [dayDiff, setDayDiff] = useState(undefined)
    // States of Emojis
    const [emoji1, setEmoji1] = useState(0)
    const [emoji2, setEmoji2] = useState(0)
    const [emoji3, setEmoji3] = useState(0)
    const [emoji4, setEmoji4] = useState(0)
    const [emoji5, setEmoji5] = useState(0)
    // States of Participation
    const [parti1, setParti1] = useState(0)
    const [parti2, setParti2] = useState(0)
    const [parti3, setParti3] = useState(0)
    const [parti4, setParti4] = useState(0)
    const [parti5, setParti5] = useState(0)
    // States of Respect
    const [respect1, setRespect1] = useState(0)
    const [respect2, setRespect2] = useState(0)
    const [respect3, setRespect3] = useState(0)
    const [respect4, setRespect4] = useState(0)
    const [respect5, setRespect5] = useState(0)
    // States of Poop
    const [poop1, setPoop1] = useState(0)
    const [poop2, setPoop2] = useState(0)
    const [poop3, setPoop3] = useState(0)
    const [poop4, setPoop4] = useState(0)
    const [poop5, setPoop5] = useState(0)
    // States of Appetite
    const [appetite1, setAppetite1] = useState(0)
    const [appetite2, setAppetite2] = useState(0)
    const [appetite3, setAppetite3] = useState(0)
    const [appetite4, setAppetite4] = useState(0)
    const [appetite5, setAppetite5] = useState(0)
    // States of to notes
    const [sign1, setSign1] = useState(false)
    const [sign2, setSign2] = useState(false)
    const [sign3, setSign3] = useState(false)
    const [sign4, setSign4] = useState(false)
    const [sign5, setSign5] = useState(false)
    // States of teacher's name
    const [name1, setName1] = useState('')
    const [name2, setName2] = useState('')
    const [name3, setName3] = useState('')
    const [name4, setName4] = useState('')
    const [name5, setName5] = useState('')

    const [wash1, setWash1] = useState(false)
    const [wash2, setWash2] = useState(false)
    const [wash3, setWash3] = useState(false)
    const [wash4, setWash4] = useState(false)
    const [wash5, setWash5] = useState(false)

    // States of Nap time
    const [from1, setFrom1] = useState("SELECT")
    const [from2, setFrom2] = useState("SELECT")
    const [from3, setFrom3] = useState("SELECT")
    const [from4, setFrom4] = useState("SELECT")
    const [from5, setFrom5] = useState("SELECT")
    // States of Baby Nap time
    const [babyFrom1, setBabyFrom1] = useState("SELECT")
    const [babyFrom2, setBabyFrom2] = useState("SELECT")
    const [babyFrom3, setBabyFrom3] = useState("SELECT")
    const [babyFrom4, setBabyFrom4] = useState("SELECT")
    const [babyFrom5, setBabyFrom5] = useState("SELECT")
    // State of Milk
    const [firstMilkTime1, setFirstMilkTime1] = useState("SELECT")
    const [firstMilkTime2, setFirstMilkTime2] = useState("SELECT")
    const [firstMilkTime3, setFirstMilkTime3] = useState("SELECT")
    const [firstMilkTime4, setFirstMilkTime4] = useState("SELECT")
    const [firstMilkTime5, setFirstMilkTime5] = useState("SELECT")

    const [secondMilkTime1, setSecondMilkTime1] = useState("SELECT")
    const [secondMilkTime2, setSecondMilkTime2] = useState("SELECT")
    const [secondMilkTime3, setSecondMilkTime3] = useState("SELECT")
    const [secondMilkTime4, setSecondMilkTime4] = useState("SELECT")
    const [secondMilkTime5, setSecondMilkTime5] = useState("SELECT")

    const [thirdMilkTime1, setThirdMilkTime1] = useState("SELECT")
    const [thirdMilkTime2, setThirdMilkTime2] = useState("SELECT")
    const [thirdMilkTime3, setThirdMilkTime3] = useState("SELECT")
    const [thirdMilkTime4, setThirdMilkTime4] = useState("SELECT")
    const [thirdMilkTime5, setThirdMilkTime5] = useState("SELECT")

    const [firstMilkQuantity1, setFirstMilkQuantity1] = useState("SELECT")
    const [firstMilkQuantity2, setFirstMilkQuantity2] = useState("SELECT")
    const [firstMilkQuantity3, setFirstMilkQuantity3] = useState("SELECT")
    const [firstMilkQuantity4, setFirstMilkQuantity4] = useState("SELECT")
    const [firstMilkQuantity5, setFirstMilkQuantity5] = useState("SELECT")

    const [secondMilkQuantity1, setSecondMilkQuantity1] = useState("SELECT")
    const [secondMilkQuantity2, setSecondMilkQuantity2] = useState("SELECT")
    const [secondMilkQuantity3, setSecondMilkQuantity3] = useState("SELECT")
    const [secondMilkQuantity4, setSecondMilkQuantity4] = useState("SELECT")
    const [secondMilkQuantity5, setSecondMilkQuantity5] = useState("SELECT")

    const [thirdMilkQuantity1, setThirdMilkQuantity1] = useState("SELECT")
    const [thirdMilkQuantity2, setThirdMilkQuantity2] = useState("SELECT")
    const [thirdMilkQuantity3, setThirdMilkQuantity3] = useState("SELECT")
    const [thirdMilkQuantity4, setThirdMilkQuantity4] = useState("SELECT")
    const [thirdMilkQuantity5, setThirdMilkQuantity5] = useState("SELECT")

    // Nap Ending time
    const [to1, setTo1] = useState("SELECT")
    const [to2, setTo2] = useState("SELECT")
    const [to3, setTo3] = useState("SELECT")
    const [to4, setTo4] = useState("SELECT")
    const [to5, setTo5] = useState("SELECT")

    // Baby Nap Ending time
    const [babyTo1, setBabyTo1] = useState("SELECT")
    const [babyTo2, setBabyTo2] = useState("SELECT")
    const [babyTo3, setBabyTo3] = useState("SELECT")
    const [babyTo4, setBabyTo4] = useState("SELECT")
    const [babyTo5, setBabyTo5] = useState("SELECT")


    // States of Notes
    const [notes1, setNotes1] = useState(undefined)
    const [notes2, setNotes2] = useState(undefined)
    const [notes3, setNotes3] = useState(undefined)
    const [notes4, setNotes4] = useState(undefined)
    const [notes5, setNotes5] = useState(undefined)

    // States of Parent Notes
    const [tnotes1, setTNotes1] = useState(undefined)
    const [tnotes2, setTNotes2] = useState(undefined)
    const [tnotes3, setTNotes3] = useState(undefined)
    const [tnotes4, setTNotes4] = useState(undefined)
    const [tnotes5, setTNotes5] = useState(undefined)

    // State of Save or Update Button
    const [btnLetter1, setBtnLetter1] = useState("Save")
    const [btnLetter2, setBtnLetter2] = useState("Save")
    const [btnLetter3, setBtnLetter3] = useState("Save")
    const [btnLetter4, setBtnLetter4] = useState("Save")
    const [btnLetter5, setBtnLetter5] = useState("Save")



    const [hoverRange, setHoverRange] = useState(undefined)
    const [selectedDays, setSelectedDays] = useState([])
    const [startDate, setStartDate] = useState(undefined)
    const [endDate, setEndDate] = useState(undefined)
    const [students, setStudents] = useState([])
    const [studentId, setStudentId] = useState(undefined)

    const [btnState1, setBtnState1] = useState(false)
    const [btnState2, setBtnState2] = useState(false)
    const [btnState3, setBtnState3] = useState(false)
    const [btnState4, setBtnState4] = useState(false)
    const [btnState5, setBtnState5] = useState(false)


    const [dateValid, setDateValid] = useState(false)
    const [theme, setTheme] = useState('')
    const [loadingState, setLoadingState] = useState(false)
    const [isSave, setISSave] = useState(true)

    const [babyClass, setBabyClass] = useState(false)

    const options = [
        "No Nap",
        "8:00",
        "8:15",
        "8:30",
        "8:45",
        "9:00",
        "9:15",
        "9:30",
        "9:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00"
    ]
    const babyOptions = [
        "No Nap",
        "8:00",
        "8:15",
        "8:30",
        "8:45",
        "9:00",
        "9:15",
        "9:30",
        "9:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
        "15:45",
        "16:00"
    ]
    const selectOptions = () => {
        return options.map((time, index) => {
            return (
                <option key={index}>
                    {time}
                </option>
            )
        })
    }

    const bayNapOptions = () => {
        return babyOptions.map((time, index) => {
            return (
                <option key={index}>
                    {time}
                </option>
            )
        })
    }

    const miltTimes = [
        "No milk",
        "09:00",
        "12:00",
        "15:00"
    ]

    const milkTimeOptions = () => {
        return miltTimes.map((time, index) => {
            return (
                <option key={index}>
                    {time}
                </option>
            )
        })
    }

    const milkQuantity = [
        "1 oz",
        "2 oz",
        "3 oz",
        "4 oz",
        "5 oz",
        "6 oz",
        "7 oz",
        "8 oz"
    ]

    const milkQuantityOptions = () => {
        return milkQuantity.map((quantity, index) => {
            return (
                <option key={index}>
                    {quantity}
                </option>
            )
        })
    }

    const clearState = () => {
        setEmoji1(0)
        setEmoji2(0)
        setEmoji3(0)
        setEmoji4(0)
        setEmoji5(0)
        setParti1(0)
        setParti2(0)
        setParti3(0)
        setParti4(0)
        setParti5(0)
        setRespect1(0)
        setRespect2(0)
        setRespect3(0)
        setRespect4(0)
        setRespect5(0)
        setAppetite1(0)
        setAppetite2(0)
        setAppetite3(0)
        setAppetite4(0)
        setAppetite5(0)
        setPoop1(0)
        setPoop2(0)
        setPoop3(0)
        setPoop4(0)
        setPoop5(0)
        setSign1(false)
        setSign2(false)
        setSign3(false)
        setSign4(false)
        setSign5(false)
        setWash1(false)
        setWash2(false)
        setWash3(false)
        setWash4(false)
        setWash5(false)
        setFrom1("SELECT")
        setFrom2("SELECT")
        setFrom3("SELECT")
        setFrom4("SELECT")
        setFrom5("SELECT")
        setTo1("SELECT")
        setTo2("SELECT")
        setTo3("SELECT")
        setTo4("SELECT")
        setTo5("SELECT")
        setBabyFrom1("SELECT")
        setBabyFrom2("SELECT")
        setBabyFrom3("SELECT")
        setBabyFrom4("SELECT")
        setBabyFrom5("SELECT")
        setBabyTo1("SELECT")
        setBabyTo2("SELECT")
        setBabyTo3("SELECT")
        setBabyTo4("SELECT")
        setBabyTo5("SELECT")
        setNotes1("")
        setNotes2("")
        setNotes3('')
        setNotes4('')
        setNotes5('')
        setTNotes1("")
        setTNotes2("")
        setTNotes3('')
        setTNotes4('')
        setTNotes5('')
        setName1('')
        setName2('')
        setName3('')
        setName4('')
        setName5('')
        setDayDiff(undefined)
        setISSave(true)
        setLoadingState(false)
        setTheme('')
        setFirstMilkTime1("SELECT")
        setFirstMilkTime2("SELECT")
        setFirstMilkTime3("SELECT")
        setFirstMilkTime4("SELECT")
        setFirstMilkTime5("SELECT")
        setSecondMilkTime1("SELECT")
        setSecondMilkTime2("SELECT")
        setSecondMilkTime3("SELECT")
        setSecondMilkTime4("SELECT")
        setSecondMilkTime5("SELECT")
        setThirdMilkTime1("SELECT")
        setThirdMilkTime2("SELECT")
        setThirdMilkTime3("SELECT")
        setThirdMilkTime4("SELECT")
        setThirdMilkTime5("SELECT")
        setFirstMilkQuantity1('SELECT')
        setFirstMilkQuantity2('SELECT')
        setFirstMilkQuantity3('SELECT')
        setFirstMilkQuantity4('SELECT')
        setFirstMilkQuantity5('SELECT')
        setSecondMilkQuantity1('SELECT')
        setSecondMilkQuantity2('SELECT')
        setSecondMilkQuantity3('SELECT')
        setSecondMilkQuantity4('SELECT')
        setSecondMilkQuantity5('SELECT')
        setThirdMilkQuantity1('SELECT')
        setThirdMilkQuantity2('SELECT')
        setThirdMilkQuantity3('SELECT')
        setThirdMilkQuantity4('SELECT')
        setThirdMilkQuantity5('SELECT')
    }


    const handleDayChange = date => {
        if (moment() < getWeekRange(date).from) {
            toastr.warning("Please select valid week", "Date validation warning!")
            setDateValid(false)
            clearState()
            return false
        }
        setSelectedDays(getWeekDays(getWeekRange(date).from))
        setStartDate(getWeekRange(date).from)
        setEndDate(getWeekRange(date).to)
    };

    const handleDayEnter = date => {
        setHoverRange(getWeekRange(date))
    };

    const handleDayLeave = () => {
        setHoverRange(undefined)
    };

    const handleWeekClick = (weekNumber, days, e) => {
        setSelectedDays(days)
    };

    const daysAreSelected = selectedDays.length > 0;
    const modifiers = {
        hoverRange,
        selectedRange: daysAreSelected && {
            from: selectedDays[0],
            to: selectedDays[6],
        },
        hoverRangeStart: hoverRange && hoverRange.from,
        hoverRangeEnd: hoverRange && hoverRange.to,
        selectedRangeStart: daysAreSelected && selectedDays[0],
        selectedRangeEnd: daysAreSelected && selectedDays[6],
    };

    const handleFindAgenda = (event, values) => {
        event.persist()
        if (values.group === "Select Group") {
            toastr.info("Please select a group", "Info")
            return false
        }

        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }

        if (!startDate) {
            toastr.info("Please select the week", "Info")
            return false
        }
        if (moment() < startDate) {
            toastr.warning("Please select valid date", "Date validation warning!")
            setDateValid(false)
            clearState()
            return false
        }

        setDateValid(true)
        setStudentId(values.student)
        delete values.group
        clearState()
        props.getAgendaData({ studentId: values.student, startDate: startDate.toString(), endDate: endDate.toString() })
    }

    const group_options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    const getStudent = (e) => {
        setStudents([])
        if (props.groups) {
            props.groups.forEach((group) => {
                if (group.id === parseInt(e.target.value)) {
                    setBabyClass(group.is4baby)
                }
            })
        }
        callStudents({ group_id: e.target.value })
            .then((result) => {
                if (result.status === 200) {
                    setStudents(JSON.parse(result.data.students))
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!", "Error")
            })
    }

    const activityOptions = () => {
        if (students) {
            return students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const nap = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    {babyClass && (
                        <span className="milk">
                            <span className="milk-at">{props.t('From')}:&nbsp;</span>
                            <select
                                value={index === 0 ? babyFrom1 : index === 1 ? babyFrom2 : index === 2 ? babyFrom3 : index === 3 ? babyFrom4 : babyFrom5}
                                className={"form-control milk-select form-control-sm"}
                                onChange={
                                    (event) =>
                                        setBabyNap(event.target.value, item, 1)
                                }
                                disabled={
                                    moment() < moment(startDate).add(index + 1, 'days') && true
                                }
                            >
                                <option disabled={true}>SELECT</option>
                                {bayNapOptions()}
                            </select>
                                &nbsp;
                            <span className="milk-at">{props.t('To')}:&nbsp;</span>
                            <select
                                className={"form-control milk-select form-control-sm"}
                                value={index === 0 ? babyTo1 : index === 1 ? babyTo2 : index === 2 ? babyTo3 : index === 3 ? babyTo4 : babyTo5}
                                onChange={(event) =>
                                    setBabyNap(event.target.value, item, 2)
                                }
                                disabled={
                                    moment() < moment(startDate).add(index + 1, 'days') || (
                                        index === 0 && babyFrom1 === "No Nap" ||
                                        index === 1 && babyFrom2 === "No Nap" ||
                                        index === 2 && babyFrom3 === "No Nap" ||
                                        index === 3 && babyFrom4 === "No Nap" ||
                                        index === 4 && babyFrom5 === "No Nap"
                                    ) && true
                                }
                            >
                                <option disabled={true}>SELECT</option>
                                {bayNapOptions()}
                            </select>
                        </span>
                    )}
                    <span className="milk">
                        <span className="milk-at">{props.t('From')}:&nbsp;</span>
                        <select
                            value={index === 0 ? from1 : index === 1 ? from2 : index === 2 ? from3 : index === 3 ? from4 : from5}
                            className={"form-control milk-select form-control-sm"}
                            onChange={
                                (event) =>
                                    selectOrCheckResult(event.target.value, item, 1, 1)
                            }
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') && true
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {selectOptions()}
                        </select>
                            &nbsp;
                            <span className="milk-at">{props.t('To')}:&nbsp;</span>
                        <select
                            className={"form-control milk-select form-control-sm"}
                            value={index === 0 ? to1 : index === 1 ? to2 : index === 2 ? to3 : index === 3 ? to4 : to5}
                            onChange={(event) =>
                                selectOrCheckResult(event.target.value, item, 2, 1)
                            }
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') || (
                                    index === 0 && from1 === "No Nap" ||
                                    index === 1 && from2 === "No Nap" ||
                                    index === 2 && from3 === "No Nap" ||
                                    index === 3 && from4 === "No Nap" ||
                                    index === 4 && from5 === "No Nap"
                                ) && true
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {selectOptions()}
                        </select>
                    </span>
                </Td >
            )
        })
    }

    const setBabyNap = (value, index, type) => {
        const params = {}
        switch (index) {
            case 0:
                if (type === 1) {
                    setBabyFrom1(value)
                    if (value === "No Nap") {
                        setBabyTo1(value)
                    }
                } else {
                    setBabyTo1(value)
                }
                break;
            case 1:
                if (type === 1) {
                    setBabyFrom2(value)
                    if (value === "No Nap") {
                        setBabyTo2(value)
                    }
                } else {
                    setBabyTo2(value)
                }
                break;
            case 2:
                if (type === 1) {
                    setBabyFrom3(value)
                    if (value === "No Nap") {
                        setBabyTo3(value)
                    }
                } else {
                    setBabyTo3(value)
                }
                break;
            case 3:
                if (type === 1) {
                    setBabyFrom4(value)
                    if (value === "No Nap") {
                        setBabyTo4(value)
                    }
                } else {
                    setBabyTo4(value)
                }
                break;
            case 4:
                if (type === 1) {
                    setBabyFrom5(value)
                    if (value === "No Nap") {
                        setBabyTo5(value)
                    }
                } else {
                    setBabyTo5(value)
                }
                break;
            default:
                break;
        }
        params.time = value
        params.order = index
        saveBabyNap(value, index, type)
    }

    const saveBabyNap = (value, index, type) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        const params = {
            checkStatus: value,
            order: index + 1,
            type: type,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        const url = "/api/v1/agendas/baby_nap_time"
        submitNapTime(params, url)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }

    const handleEmoji = (value, index) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        switch (index) {
            case 1:
                setEmoji1(value)
                break;
            case 2:
                setEmoji2(value)
                break;
            case 3:
                setEmoji3(value)
                break;
            case 4:
                setEmoji4(value)
                break;
            case 5:
                setEmoji5(value)
                break;
            default:
                break;
        }
        emojiSave(value, index)
    }

    const handlePoop = (value, index) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        switch (index) {
            case 1:
                setPoop1(value)
                break;
            case 2:
                setPoop2(value)
                break;
            case 3:
                setPoop3(value)
                break;
            case 4:
                setPoop4(value)
                break;
            case 5:
                setPoop5(value)
                break;
            default:
                break;
        }
        poopSave(value, index)
    }

    const emojiSave = (value, order) => {
        const params = {
            mood: value,
            order: order,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        submitEmoji(params)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }

    const poopSave = (value, order) => {
        const params = {
            poop: value,
            order: order,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        submitPoop(params)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }

    const checkValidation = () => {
        if (!startDate) {
            toastr.info("Please select the week", "Info")
            return false
        } else if (!studentId) {
            toastr.info("Please select a student", "Info")
            return false
        } else {
            return true
        }
    }

    const handleParticipation = (value, index) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        switch (index) {
            case 1:
                setParti1(value)
                break;
            case 2:
                setParti2(value)
                break;
            case 3:
                setParti3(value)
                break;
            case 4:
                setParti4(value)
                break;
            case 5:
                setParti5(value)
                break;
            default:
                break;
        }

        saveRates(value, index, 1)
    }

    const handleRespect = (value, index) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        switch (index) {
            case 1:
                setRespect1(value)
                break;
            case 2:
                setRespect2(value)
                break;
            case 3:
                setRespect3(value)
                break;
            case 4:
                setRespect4(value)
                break;
            case 5:
                setRespect5(value)
                break;
            default:
                break;
        }
        saveRates(value, index, 2)
    }

    const handleAppetite = (value, index) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        switch (index) {
            case 1:
                setAppetite1(value)
                break;
            case 2:
                setAppetite2(value)
                break;
            case 3:
                setAppetite3(value)
                break;
            case 4:
                setAppetite4(value)
                break;
            case 5:
                setAppetite5(value)
                break;
            default:
                break;
        }

        saveRates(value, index, 3)
    }

    const saveRates = (value, index, type) => {
        const params = {
            rates: value,
            order: index,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        let url
        switch (type) {
            case 1:
                url = "/api/v1/agendas/participation"
                break
            case 2:
                url = "/api/v1/agendas/respect"
                break
            case 3:
                url = "/api/v1/agendas/appetite"
                break
            default:
                break
        }
        submitRates(params, url)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }

    const selectOrCheckResult = (value, index, type, checkOrSelect) => {
        let params = { type: type }
        switch (index) {
            case 0:
                if (checkOrSelect === 2) {
                    if (type === 1) {
                        setSign1(value)
                    } else {
                        setWash1(value)
                    }
                } else {
                    if (type === 1) {
                        setFrom1(value)
                        if (value === "No Nap") {
                            setTo1(value)
                        }
                    } else {
                        setTo1(value)
                    }
                }
                params.time = value
                params.order = index
                break;
            case 1:
                if (checkOrSelect === 2) {
                    if (type === 1) {
                        setSign2(value)
                    } else {
                        setWash2(value)
                    }
                } else {
                    if (type === 1) {
                        setFrom2(value)
                        if (value === "No Nap") {
                            setTo2(value)
                        }
                    } else {
                        setTo2(value)
                    }
                }
                params.time = value
                params.order = index
                break;
            case 2:
                if (checkOrSelect === 2) {
                    if (type === 1) {
                        setSign3(value)
                    } else {
                        setWash3(value)
                    }
                } else {
                    if (type === 1) {
                        setFrom3(value)
                        if (value === "No Nap") {
                            setTo3(value)
                        }
                    } else {
                        setTo3(value)
                    }
                }
                params.time = value
                params.order = index
                break;
            case 3:
                if (checkOrSelect === 2) {
                    if (type === 1) {
                        setSign4(value)
                    } else {
                        setWash4(value)
                    }
                } else {
                    if (type === 1) {
                        setFrom4(value)
                        if (value === "No Nap") {
                            setTo4(value)
                        }
                    } else {
                        setTo4(value)
                    }
                }
                params.time = value
                params.order = index
                break;
            case 4:
                if (checkOrSelect === 2) {
                    if (type === 1) {
                        setSign5(value)
                    } else {
                        setWash5(value)
                    }
                } else {
                    if (type === 1) {
                        setFrom5(value)
                        if (value === "No Nap") {
                            setTo5(value)
                        }
                    } else {
                        setTo5(value)
                    }
                }
                params.time = value
                params.order = index
                break;
            default:
                break;
        }
        saveNapTimeOrWash(value, index, type, checkOrSelect)
    }

    const saveNapTimeOrWash = (value, index, type, checkOrSelect) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        const params = {
            checkStatus: value,
            order: index + 1,
            type: type,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        let url
        switch (checkOrSelect) {
            case 1:
                url = "/api/v1/agendas/nap_time"
                break
            case 2:
                url = "/api/v1/agendas/to_note"
                break
            default:
                break
        }
        submitNapTime(params, url)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }



    const activityChecks = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Row style={{ paddingLeft: "10px" }}>
                        <Checkbox
                            className={"standard-font"}
                            checked={index === 0 ? sign1 : index === 1 ? sign2 : index === 2 ? sign3 : index === 3 ? sign4 : sign5}
                            onChange={e => selectOrCheckResult(e.target.checked, item, 1, 2)}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') && true
                            }
                        >
                            {props.t('Document to sign')}
                        </Checkbox>
                    </Row>
                    <Row style={{ paddingLeft: "10px" }}>
                        <Checkbox
                            className={"standard-font"}
                            checked={index === 0 ? wash1 : index === 1 ? wash2 : index === 2 ? wash3 : index === 3 ? wash4 : wash5}
                            onChange={e => selectOrCheckResult(e.target.checked, item, 2, 2)}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') && true
                            }
                        >
                            {props.t('Clothes to wash')}
                        </Checkbox>
                    </Row>
                </Td>
            )
        })
    }

    const teacherNotes = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <textarea
                        value={index === 0 ? notes1 : index === 1 ? notes2 : index === 2 ? notes3 : index === 3 ? notes4 : notes5}
                        onChange={(e) => handleNotes(e.target.value, item)}
                        className="form-control" name="" id="" cols="10" rows="6"
                        disabled={
                            moment() < moment(startDate).add(index + 1, 'days') && true
                        }
                    >
                    </textarea>
                </Td>
            )
        })
    }


    const parentNote = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <textarea
                        value={index === 0 ? tnotes1 : index === 1 ? tnotes2 : index === 2 ? tnotes3 : index === 3 ? tnotes4 : tnotes5}
                        disabled={true}
                        className="form-control" name="" id="" cols="10" rows="6"
                    >
                    </textarea>
                </Td>
            )
        })
    }

    const handleNotes = (text, item) => {
        switch (item) {
            case 0:
                setNotes1(text)
                break;
            case 1:
                setNotes2(text)
                break;
            case 2:
                setNotes3(text)
                break;
            case 3:
                setNotes4(text)
                break;
            case 4:
                setNotes5(text)
                break;
            default:
                break;
        }
    }


    const saveBtn = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <button
                        className="btn btn-success"
                        onClick={() => handleSave(item)}
                        disabled={
                            moment() < moment(startDate).add(index + 1, 'days') && true
                        }
                    >
                        {index === 0 ? (
                            btnState1 ? <React.Fragment>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving...
                            </React.Fragment> : btnLetter1
                        ) :
                            index === 1 ? (
                                btnState2 ? <React.Fragment>
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                     Saving...
                                 </React.Fragment> : btnLetter2
                            ) :
                                index === 2 ? (
                                    btnState3 ? <React.Fragment>
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                     Saving...
                                 </React.Fragment> : btnLetter3
                                ) :
                                    index === 3 ? (
                                        btnState4 ? <React.Fragment>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                     Saving...
                                 </React.Fragment> : btnLetter4
                                    ) :
                                        (btnState5 ? <React.Fragment>
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                 Saving...
                             </React.Fragment> : btnLetter5)}
                    </button>
                </Td>
            )
        })
    }

    const teacherName = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <span
                    >
                        {index === 0 ? name1 : index === 1 ? name2 : index === 2 ? name3 : index === 3 ? name4 : name5}
                    </span>
                </Td>
            )
        })
    }

    const handleSave = (order) => {
        const isValid = checkValidation()
        if (!isValid) {
            return false
        }
        let params = {
            order: order + 1,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        switch (order) {
            case 0:
                params.note = notes1
                setBtnState1(true)
                break;
            case 1:
                params.note = notes2
                setBtnState2(true)
                break;
            case 2:
                params.note = notes3
                setBtnState3(true)
                break;
            case 3:
                params.note = notes4
                setBtnState4(true)
                break;
            case 4:
                params.note = notes5
                setBtnState5(true)
                break;
            default:
                break;
        }
        const url = "/api/v1/agendas/release"
        releaseReport(params, url)
            .then((result) => {
                clearBtnState()
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                    props.getAgendaData({ studentId: studentId, startDate: startDate.toString(), endDate: endDate.toString() })
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                clearBtnState()
                toastr.error("Something went wrong", "Error")
            })
    }

    const clearBtnState = () => {
        setBtnState1(false)
        setBtnState2(false)
        setBtnState3(false)
        setBtnState4(false)
        setBtnState5(false)
    }


    const partiRate = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        onChange={(value) => handleParticipation(value, index + 1)}
                        size={20}
                        disabled={
                            moment() < moment(startDate).add(index + 1, 'days') && true
                        }
                        value={index === 0 ? parti1 : index === 1 ? parti2 : index === 2 ? parti3 : index === 3 ? parti4 : parti5}
                    />
                </Td>
            )
        })
    }

    const respectTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        onChange={(value) => handleRespect(value, index + 1)}
                        size={20}
                        disabled={
                            moment() < moment(startDate).add(index + 1, 'days') && true
                        }
                        value={index === 0 ? respect1 : index === 1 ? respect2 : index === 2 ? respect3 : index === 3 ? respect4 : respect5}
                    />
                </Td>
            )
        })
    }

    const appetiteTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        onChange={(value) => handleAppetite(value, index + 1)}
                        size={20}
                        disabled={
                            moment() < moment(startDate).add(index + 1, 'days') && true
                        }
                        value={index === 0 ? appetite1 : index === 1 ? appetite2 : index === 2 ? appetite3 : index === 3 ? appetite4 : appetite5}
                    />
                </Td>
            )
        })
    }

    const emojiTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    {index === 0 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji1 === 1 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(1, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-frown ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"}  ${emoji1 === 2 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(2, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-meh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji1 === 3 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(3, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-smile ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji1 === 4 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(4, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji1 === 5 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(5, index + 1)
                                    }
                                }}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 1 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji2 === 1 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(1, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-frown ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"}  ${emoji2 === 2 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(2, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-meh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji2 === 3 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(3, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-smile ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji2 === 4 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(4, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji2 === 5 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(5, index + 1)
                                    }
                                }}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 2 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji3 === 1 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(1, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-frown ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"}  ${emoji3 === 2 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(2, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-meh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji3 === 3 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(3, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-smile ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji3 === 4 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(4, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji3 === 5 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(5, index + 1)
                                    }
                                }}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 3 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji4 === 1 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(1, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-frown ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"}  ${emoji4 === 2 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(2, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-meh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji4 === 3 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(3, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-smile ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji4 === 4 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(4, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji4 === 5 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(5, index + 1)
                                    }
                                }}
                            >
                            </span>
                        </Fragment>
                    )}
                    {index === 4 && (
                        <Fragment>
                            <span
                                className={`fas fa-angry ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji5 === 1 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(1, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-frown ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"}  ${emoji5 === 2 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(2, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-meh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji5 === 3 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(3, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-smile ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji5 === 4 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(4, index + 1)
                                    }
                                }}
                            >
                            </span>
                            <span
                                className={`fas fa-laugh ${moment() < moment(startDate).add(index + 1, 'days') ? 'emoji-disabled' : "emoji"} ${emoji5 === 5 && "checked"}`}
                                onClick={() => {
                                    if (moment() >= moment(startDate).add(index + 1, 'days')) {
                                        handleEmoji(5, index + 1)
                                    }
                                }}
                            >
                            </span>
                        </Fragment>
                    )}
                </Td>
            )
        })
    }


    const poopTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={index}>
                    <Rate
                        count={3}
                        onChange={(value) => handlePoop(value, index + 1)}
                        size={20}
                        className="poop-image"
                        character={({ index }) => {
                            return customIcons[index + 1];
                        }}
                        disabled={
                            moment() < moment(startDate).add(index + 1, 'days') && true
                        }
                        value={index === 0 ? poop1 : index === 1 ? poop2 : index === 2 ? poop3 : index === 3 ? poop4 : poop5}
                    />
                </Td>
            )
        })
    }

    const handleTheme = () => {
        if (!theme) {
            toastr.info("Please input the theme of the week", "Info")
            return false
        }
        setLoadingState(true)
        const params = {
            theme: theme,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        saveTheme(params)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
                setLoadingState(false)
                setISSave(false)
            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
                setLoadingState(false)
            })
    }

    const setValues = (text) => {
        setTheme(text)
    }


    const milkTd = () => {
        return loopValue.map((item, index) => {
            return (
                <Td key={item}>
                    <span className="milk">
                        <span className="milk-at">{props.t('At')}:&nbsp;</span>
                        <select
                            className="form-control milk-select form-control-sm"
                            value={index === 0 ? firstMilkTime1 : index === 1 ? firstMilkTime2 : index === 2 ? firstMilkTime3 : index === 3 ? firstMilkTime4 : firstMilkTime5}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') && true
                            }
                            onChange={
                                (event) =>
                                    milkTimeInfo(event.target.value, index, 1)
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {milkTimeOptions()}
                        </select>
                        <span className="milk-at">&nbsp;=&nbsp;</span>
                        <select
                            className="form-control milk-select form-control-sm"
                            value={index === 0 ? firstMilkQuantity1 : index === 1 ? firstMilkQuantity2 : index === 2 ? firstMilkQuantity3 : index === 3 ? firstMilkQuantity4 : firstMilkQuantity5}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') || (
                                    index === 0 && firstMilkTime1 === 'No milk' ||
                                    index === 1 && firstMilkTime2 === 'No milk' ||
                                    index === 2 && firstMilkTime3 === 'No milk' ||
                                    index === 3 && firstMilkTime4 === 'No milk' ||
                                    index === 4 && firstMilkTime5 === 'No milk'
                                ) && true
                            }
                            onChange={
                                (event) =>
                                    milkQuantityInfo(event.target.value, index, 1)
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {milkQuantityOptions()}
                        </select>
                    </span>
                    <br />
                    <span className="milk">
                        <span className="milk-at">{props.t('At')}:&nbsp;</span>
                        <select
                            className="form-control milk-select form-control-sm"
                            value={index === 0 ? secondMilkTime1 : index === 1 ? secondMilkTime2 : index === 2 ? secondMilkTime3 : index === 3 ? secondMilkTime4 : secondMilkTime5}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') && true
                            }
                            onChange={
                                (event) =>
                                    milkTimeInfo(event.target.value, index, 2)
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {milkTimeOptions()}
                        </select>
                        <span className="milk-at">&nbsp;=&nbsp;</span>
                        <select
                            className="form-control milk-select form-control-sm"
                            value={index === 0 ? secondMilkQuantity1 : index === 1 ? secondMilkQuantity2 : index === 2 ? secondMilkQuantity3 : index === 3 ? secondMilkQuantity4 : secondMilkQuantity5}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') || (
                                    index === 0 && secondMilkTime1 === 'No milk' ||
                                    index === 1 && secondMilkTime2 === 'No milk' ||
                                    index === 2 && secondMilkTime3 === 'No milk' ||
                                    index === 3 && secondMilkTime4 === 'No milk' ||
                                    index === 4 && secondMilkTime5 === 'No milk'
                                ) && true
                            }
                            onChange={
                                (event) =>
                                    milkQuantityInfo(event.target.value, index, 2)
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {milkQuantityOptions()}
                        </select>
                    </span>
                    <br />
                    <span className="milk">
                        <span className="milk-at">{props.t('At')}:&nbsp;</span>
                        <select
                            className="form-control milk-select form-control-sm"
                            value={index === 0 ? thirdMilkTime1 : index === 1 ? thirdMilkTime2 : index === 2 ? thirdMilkTime3 : index === 3 ? thirdMilkTime4 : thirdMilkTime5}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') && true
                            }
                            onChange={
                                (event) =>
                                    milkTimeInfo(event.target.value, index, 3)
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {milkTimeOptions()}
                        </select>
                        <span className="milk-at">&nbsp;=&nbsp;</span>
                        <select
                            className="form-control milk-select form-control-sm"
                            value={index === 0 ? thirdMilkQuantity1 : index === 1 ? thirdMilkQuantity2 : index === 2 ? thirdMilkQuantity3 : index === 3 ? thirdMilkQuantity4 : thirdMilkQuantity5}
                            disabled={
                                moment() < moment(startDate).add(index + 1, 'days') || (
                                    index === 0 && thirdMilkTime1 === 'No milk' ||
                                    index === 1 && thirdMilkTime2 === 'No milk' ||
                                    index === 2 && thirdMilkTime3 === 'No milk' ||
                                    index === 3 && thirdMilkTime4 === 'No milk' ||
                                    index === 4 && thirdMilkTime5 === 'No milk'
                                ) && true
                            }
                            onChange={
                                (event) =>
                                    milkQuantityInfo(event.target.value, index, 3)
                            }
                        >
                            <option disabled={true}>SELECT</option>
                            {milkQuantityOptions()}
                        </select>
                    </span>
                </Td>
            )
        })
    }


    const milkTimeInfo = (value, index, no) => {
        let params = { no: no, order: index + 1, value: value, type: 1 }
        switch (index) {
            case 0:
                if (no === 1) {
                    setFirstMilkTime1(value)
                    if (value === "No milk") {
                        setFirstMilkQuantity1("SELECT")
                    }
                } else if (no === 2) {
                    setSecondMilkTime1(value)
                    if (value === "No milk") {
                        setSecondMilkQuantity1("SELECT")
                    }
                } else {
                    setThirdMilkTime1(value)
                    if (value === "No milk") {
                        setThirdMilkQuantity1("SELECT")
                    }
                }
                break;
            case 1:
                if (no === 1) {
                    setFirstMilkTime2(value)
                    if (value === "No milk") {
                        setFirstMilkQuantity2("SELECT")
                    }
                } else if (no === 2) {
                    setSecondMilkTime2(value)
                    if (value === "No milk") {
                        setSecondMilkQuantity2("SELECT")
                    }
                } else {
                    setThirdMilkTime2(value)
                    if (value === "No milk") {
                        setThirdMilkQuantity2("SELECT")
                    }
                }
                break;
            case 2:
                if (no === 1) {
                    setFirstMilkTime3(value)
                    if (value === "No milk") {
                        setFirstMilkQuantity3("SELECT")
                    }
                } else if (no === 2) {
                    setSecondMilkTime3(value)
                    if (value === "No milk") {
                        setSecondMilkQuantity3("SELECT")
                    }
                } else {
                    setThirdMilkTime3(value)
                    if (value === "No milk") {
                        setThirdMilkQuantity3("SELECT")
                    }
                }
                break;
            case 3:
                if (no === 1) {
                    setFirstMilkTime4(value)
                    if (value === "No milk") {
                        setFirstMilkQuantity4("SELECT")
                    }
                } else if (no === 2) {
                    setSecondMilkTime4(value)
                    if (value === "No milk") {
                        setSecondMilkQuantity4("SELECT")
                    }
                } else {
                    setThirdMilkTime4(value)
                    if (value === "No milk") {
                        setThirdMilkQuantity4("SELECT")
                    }
                }
                break;
            case 4:
                if (no === 1) {
                    setFirstMilkTime5(value)
                    if (value === "No milk") {
                        setFirstMilkQuantity5("SELECT")
                    }
                } else if (no === 2) {
                    setSecondMilkTime5(value)
                    if (value === "No milk") {
                        setSecondMilkQuantity5("SELECT")
                    }
                } else {
                    setThirdMilkTime5(value)
                    if (value === "No milk") {
                        setThirdMilkQuantity5("SELECT")
                    }
                }
                break;
            default:
                break;
        }
        saveMilkInfo(params)
    }

    const milkQuantityInfo = (value, index, no) => {
        let params = { no: no, order: index + 1, value: value, type: 2 }
        switch (index) {
            case 0:
                if (no === 1) {
                    setFirstMilkQuantity1(value)
                } else if (no === 2) {
                    setSecondMilkQuantity1(value)
                } else {
                    setThirdMilkQuantity1(value)
                }
                break;
            case 1:
                if (no === 1) {
                    setFirstMilkQuantity2(value)
                } else if (no === 2) {
                    setSecondMilkQuantity2(value)
                } else {
                    setThirdMilkQuantity2(value)
                }
                break;
            case 2:
                if (no === 1) {
                    setFirstMilkQuantity3(value)
                } else if (no === 2) {
                    setSecondMilkQuantity3(value)
                } else {
                    setThirdMilkQuantity3(value)
                }
                break;
            case 3:
                if (no === 1) {
                    setFirstMilkQuantity4(value)
                } else if (no === 2) {
                    setSecondMilkQuantity4(value)
                } else {
                    setThirdMilkQuantity4(value)
                }
                break;
            case 4:
                if (no === 1) {
                    setFirstMilkQuantity5(value)
                } else if (no === 2) {
                    setSecondMilkQuantity5(value)
                } else {
                    setThirdMilkQuantity5(value)
                }
                break;
            default:
                break;
        }
        saveMilkInfo(params)
    }

    const saveMilkInfo = (values) => {
        const params = {
            ...values,
            startOfWeek: startDate.toString(),
            studentId: studentId
        }
        delete params.type
        let url
        switch (values.type) {
            case 1:
                url = "/api/v1/agendas/milk_time"
                break
            case 2:
                url = "/api/v1/agendas/milk_quantity"
                break
            default:
                break
        }
        submitMilkInfo(params, url)
            .then((result) => {
                if (result.status === 200) {
                    toastr.success(result.data.message, "Success")
                } else {
                    toastr.warning(result.data.message, "Warning")
                }

            })
            .catch((error) => {
                toastr.error("Something went wrong", "Error")
            })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Daily Activities Communication Agenda')} breadcrumbItem={props.t('Agenda')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>

                                    <Row className={"mb-3"}>
                                        <Col xs={12} sm={6} className="SelectedWeekExample">
                                            <DayPicker
                                                selectedDays={selectedDays}
                                                showWeekNumbers
                                                showOutsideDays
                                                modifiers={modifiers}
                                                onDayClick={handleDayChange}
                                                onDayMouseEnter={handleDayEnter}
                                                onDayMouseLeave={handleDayLeave}
                                                onWeekClick={handleWeekClick}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6}>
                                            <Row>
                                                <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} onValidSubmit={(e, v) => { handleFindAgenda(e, v) }}>
                                                    <Col xs={12}>
                                                        <div className="form-group">
                                                            <label>{props.t('Select Group')}:</label>
                                                            <AvField
                                                                type="select"
                                                                value={"Select Group"}
                                                                name="group"
                                                                validate={{ required: { value: true } }}
                                                                onChange={getStudent}
                                                            >
                                                                <option disabled={true}>Select Group</option>
                                                                {group_options()}
                                                            </AvField>
                                                        </div>
                                                    </Col>

                                                    <Col xs={12}>
                                                        <div className="form-group">
                                                            <label>{props.t('Select Student')}:</label>
                                                            <AvField type="select" value={"Select Student"} name="student" validate={{ required: { value: true } }}>
                                                                <option disabled={true}>Select Student</option>
                                                                {activityOptions()}
                                                            </AvField>
                                                        </div>
                                                    </Col>

                                                    <Col xs={12} className={"mr-0"}>
                                                        <FormGroup className="mb-0">
                                                            <div>
                                                                <Button type="submit" color="primary" className="mr-1" style={{ width: "100%" }} disabled={props.loading}>
                                                                    {props.loading ? (
                                                                        <React.Fragment>
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                            Finding
                                                                        </React.Fragment>
                                                                    ) : "Find"}
                                                                </Button>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                </AvForm>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {props.agenda && dateValid && (
                                        <Row>
                                            <Col>
                                                <Input
                                                    style={{ border: "none", boxShadow: "none", fontWeight: "bold" }}
                                                    prefix={"Theme of the week:"}
                                                    value={theme}
                                                    onChange={(e) => setValues(e.target.value)}
                                                    placeholder={props.t('Theme of the week')}
                                                    onPressEnter={() => handleTheme()}
                                                    suffix={
                                                        <Button
                                                            color="primary"
                                                            onClick={() => handleTheme()}
                                                            disabled={loadingState}
                                                        >
                                                            {loadingState ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                        Saving...
                                                                </React.Fragment>
                                                            ) : isSave ? "Save" : "Update"}
                                                        </Button>
                                                    }
                                                />
                                            </Col>
                                            <Table id="tech-companies-1" className="mt-3 table table-striped table-bordered">
                                                <Thead>
                                                    <Tr style={{ backgroundColor: "#A4C2F4", color: "Black" }} className={"table-header"}>
                                                        <Th data-priority="1" style={{ backgroundColor: "white", width: "150px" }}>Week From:
                                                            {selectedDays.length === 7 && (
                                                                <div>
                                                                    ({moment(selectedDays[0]).format('YYYY-MM-DD')} : <br />
                                                                    {moment(selectedDays[6]).format('YYYY-MM-DD')})
                                                                </div>
                                                            )}
                                                        </Th>
                                                        <Th className={dayDiff === 1 ? "diff-background" : ""} data-priority="1">{props.t('Monday')}</Th>
                                                        <Th className={dayDiff === 2 ? "diff-background" : ""} data-priority="1">{props.t('Tuesday')}</Th>
                                                        <Th className={dayDiff === 3 ? "diff-background" : ""} data-priority="1">{props.t('Wednesday')}</Th>
                                                        <Th className={dayDiff === 4 ? "diff-background" : ""} data-priority="1">{props.t('Thursday')}</Th>
                                                        <Th className={dayDiff === 5 ? "diff-background" : ""} data-priority="1">{props.t('Friday')}</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    <Tr>
                                                        <Td className={"text-left td-resp-margin"}><strong>{props.t('My mood')}</strong> </Td>
                                                        {emojiTd()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className={"text-left"}><strong>{props.t('My participation')}</strong></Td>
                                                        {partiRate()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className={"text-left"}><strong>{props.t('My respect for the instructions')}</strong></Td>
                                                        {respectTd()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-left"><strong>{props.t('My appetite')}</strong></Td>
                                                        {appetiteTd()}
                                                    </Tr>
                                                    {babyClass && (
                                                        <Fragment>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('My poop')}</strong></Td>
                                                                {poopTd()}
                                                            </Tr>
                                                            <Tr>
                                                                <Td className="text-left"><strong>{props.t('Milk')}</strong></Td>
                                                                {milkTd()}
                                                            </Tr>
                                                        </Fragment>
                                                    )}
                                                    <Tr>
                                                        <Td className="text-left"><strong>{props.t('Nap')}</strong></Td>
                                                        {nap()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-left">
                                                            <strong>{props.t('To note')}</strong>
                                                        </Td>
                                                        {activityChecks()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-left"><strong>{props.t('Note from the educator')}</strong></Td>
                                                        {teacherNotes()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-left"><strong>{props.t('Parents initials or note')}</strong></Td>
                                                        {parentNote()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-left"><strong>{props.t('Name of educator')}</strong></Td>
                                                        {teacherName()}
                                                    </Tr>
                                                    <Tr>
                                                        <Td className="text-left">Save or Update</Td>
                                                        {/* <Td className="text-left"></Td> */}
                                                        {saveBtn()}
                                                    </Tr>
                                                </Tbody>
                                            </Table>
                                        </Row>
                                    )}
                                </CardBody>
                                <p style={{ paddingLeft: "10px", fontSize: "11px", fontStyle: "italic" }}>{props.t('Do not forget to save your comments! Emails are sent to parents everyday at 4pm!')} </p>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    const { students } = state.ReportAttReducer;
    const { groups } = state.ActivityReducer;
    const { agenda, loading } = state.AgendaReducer;
    return { students, groups, agenda, loading };
}

export default withRouter(connect(mapStateToProps, { getStudentsList, getGroupList, getAgendaData })(withNamespaces()(TeacherAgenda)));
