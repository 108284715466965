import React, { useEffect, useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

import ReactDelayRender from 'react-delay-render';
// flags
// import canFlag from "../../../assets/images/flags/can.jpg";
// import french from "../../../assets/images/flags/qc.jpg";

const LanguageDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  useEffect(() => {
    if (localStorage.getItem("locale") && localStorage.getItem("locale") === "fr") {
      // setFlag(french);
      setLng('French');
    } else {
      // setFlag(canFlag);
      setLng('English');
    }
  }, [])

  const [menu, setMenu] = useState(false);
  // const [flag, setFlag] = useState(canFlag);
  const [lng, setLng] = useState("English");


  function changeLanguageAction(lng) {

    //set language as i18n
    i18n.changeLanguage(lng);

    if (lng === "fr") {
      // setFlag(french);
      setLng('French');
      localStorage.setItem("locale", "fr");
    } else {
      // setFlag(canFlag);
      setLng('English');
      localStorage.setItem("locale", "en");
    }
  }


  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          tag="button"
        >
          {/* <img
            src={flag}
            alt="Skote"
            height="16"
            className="mr-1"
          /> */}
          <span className="align-middle">{lng}</span>
        </DropdownToggle>
        <DropdownMenu className="language-switch" right>
          <DropdownItem tag="a" href="#" onClick={() => changeLanguageAction('eng')} className="notify-item">
            {/* <img src={canFlag} alt="Skote" className="mr-1" height="12" /> */}
            <span className="align-middle">English</span>
          </DropdownItem>
          <DropdownItem tag="a" href="#" onClick={() => changeLanguageAction('fr')} className="notify-item">
            {/* <img src={french} alt="Skote" className="mr-1" height="12" /> */}
            <span className="align-middle">French</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default withNamespaces()(LanguageDropdown);