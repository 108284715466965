import axios from 'axios';
import toastr from 'toastr'

const aStudent = (url) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

const editAStudent = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "put",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}

export { aStudent, editAStudent }