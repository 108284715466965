import React from "react";
import { Redirect } from "react-router-dom";



// Authentication related pages
import Login from "../components/Authentication/login";
import Logout from "../components/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
import ForgetPwd from "../components/Authentication/ForgetPassword";
import ResetPwd from "../components/Authentication/resetPwd";

import Students from "../components/Students/index";
import Dashboard from "../components/dashboard/index";
import Users from "../components/users/index";
import StudentDetail from "../components/StudentDetail/index";
import Group from "../components/Group/index";
import CreateActivity from "../components/Activity/create";
import ActivityList from "../components/Activity/activityList";
import ActivityDetail from "../components/Activity/detail";
import Attendances from "../components/attendances/index";
import ReportAttendances from "../components/Report/attendances";
import GroupDetail from "../components/Group/detail";
import ReportItems from "../components/School_Report/create";
import ReportList from "../components/School_Report/activityList";
import ReportDetail from "../components/School_Report/detail";
import Report from "../components/Report/report";
import AccidentReport from "../components/Report/accident_report";
import TeacherAgenda from "../components/agenda/agenda_teacher";
import ParentAgenda from "../components/agenda/agenda_parent";
import TeacherGallery from "../components/gallery/teacher_gallery";
import ParentGallery from "../components/gallery/parent_gallery";
import OldReport from "../components/School_Report/oldReports";

const userRoutes = [
    // this route should be at the end of all other routes
    { path: "/dashboard", component: Dashboard },
    { path: "/users", component: Users},
    { path: "/students/:id", component: StudentDetail},
    { path: "/group/:id", component: GroupDetail},
    { path: "/students", component: Students},
    { path: "/group", component: Group},
    { path: "/teacher_gallery", component: TeacherGallery},
    { path: "/parent_gallery", component: ParentGallery},
    {path: "/create-activity", component: CreateActivity},
    {path: "/activity-list", component: ActivityList},
    {path: "/activity-detail", component: ActivityDetail},
    {path: "/communication-agenda", component: TeacherAgenda},
    {path: "/report-agenda", component: ParentAgenda},
    {path: "/attendances", component: Attendances},
    {path: "/report-items", component: ReportItems},
    {path: "/report-list", component: ReportList},
    {path: "/report-detail", component: ReportDetail},
    {path: "/report-attendances", component: ReportAttendances},
    {path: "/report-show", component: Report},
    {path: "/accident-report", component: AccidentReport},
    {path: "/old-report", component: OldReport},
    { path: "/", exact: true, component: () => {
            if(JSON.parse(localStorage.getItem("authUser")).data.role !== "Teacher") {
               return <Redirect to="/parent_gallery"/>
            } else {
               return <Redirect to="/dashboard" />
            }
        }
    }
];

const authRoutes = [
    { path: "/logout", component: Logout },
    { path: "/forgot-password", component: ForgetPwd },
    // { path: "/reset-password", component: ResetPwd },
    // { path: "/register", component: Register }
];

export { userRoutes, authRoutes };
