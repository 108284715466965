import { RESET_PWD, RESET_PWD_SUCCESS, RESET_PWD_ERROR } from './actionTypes';
import {LOGIN_SUCCESS} from "../login/actionTypes";

const initialState = {
    error: "",
    loading: false
}

const resetPwd = (state = initialState, action) => {
    switch (action.type) {
        case RESET_PWD:
            state = {
                ...state,
                loading: true
            }
            break;
        case RESET_PWD_SUCCESS:
            state = {
                ...state,
                loading: false
            }
            break;
        case RESET_PWD_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default resetPwd;