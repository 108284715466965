import axios from 'axios';
import toastr from 'toastr'

const getGalleryData = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: 'get',
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        params : data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response
    });
}


const uploadFiles = (data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: "/api/v1/image_gallery",
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: {gallery: data}
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response.data
    });
}

const updateImage = (data) => {
    const id = data.id
    delete data.id
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "put",
        url: `/api/v1/image_gallery/${id}`,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response.data
    });
}


const stashUpload = (url, data) => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"))
    const config = {
        method: "post",
        url: url,
        headers: {
            accesstoken: headers.accesstoken,
            client: headers.client,
            uid: headers.uid,
        },
        data: data
    }
    return axios(config).then(response => {
        if (response.status === 400 || response.status === 500)
            throw response.data;
        return response;
    }).catch(err => {
        return err.response.data
    });
}


export { getGalleryData, uploadFiles, updateImage, stashUpload }