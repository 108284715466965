import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
} from "reactstrap";
import { Tooltip, Input } from 'antd';
import { withRouter } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Pagination } from "antd";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import ChangePasswordModal from "../Modals/change_password_modal";
import SweetAlertSuccess from "../Modals/sweet_alert_success";
import { connect } from "react-redux";
import moment from "moment";
import {
  createUser,
  getAllUsers,
  deleteUser,
  editUserInfo,
} from "../../store/actions";
//i18n
import { withNamespaces } from "react-i18next";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import toastr from "toastr";
import axios from "axios";

const Dashboard = (props) => {
  const [confirm_both, setconfirm_both] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [error_dlg, seterror_dlg] = useState(false);
  const [dynamic_title, setdynamic_title] = useState("");
  const [dynamic_description, setdynamic_description] = useState("");

  const [modal, setmodal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [changePwd, setChangePwd]= useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [current, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [modalName, setName] = useState("");
  const [modalEmail, setEmail] = useState("");
  const [modalRole, setRole] = useState("");
  const [id, setId] = useState("cool");
  const [index, setIndex] = useState("index");

  const [delData, setDelData] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [search, setSearch] = useState("")

  useEffect(() => {
    props.getAllUsers({ page: 1, pageSize: pageSize, search: search });
  }, []);

  useEffect(() => {
    if (props.modal === "success") {
      setmodal(false);
    }
  }, [props.modal]);

  useEffect(() => {
    if (props.editModal === "success") {
      setEditModal(false);
    }
  }, [props.editModal]);

  const onChange = (page) => {
    props.getAllUsers({ page: page, pageSize: pageSize, search: search });
    setCurrentPage(page);
  };

  function onShowSizeChange(current, pageSize) {
    props.getAllUsers({ page: current, pageSize: pageSize, search: search });
    setPageSize(pageSize);
    setCurrentPage(current);
  }

  const handleDel = (val) => {
    setDelData(val);
    setconfirm_both(true);
  };

  const handleChangePwd = (val) => {
    setId(val.id);
    setChangePwd(true)
  }
  const postDelete = () => {
    const headers = JSON.parse(localStorage.getItem("authHeaders"));
    const config = {
      method: "delete",
      url: `/api/v1/manage_users/${delData.id}`,
      headers: {
        accesstoken: headers.accesstoken,
        client: headers.client,
        uid: headers.uid,
      },
    };
    return axios(config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        setsuccess_dlg(true);
        // setdynamic_title({props.t("Deleted")});
        setdynamic_title("Deleted");
        setdynamic_description("Your user has been deleted.");
      })
      .catch((err) => {
        seterror_dlg(true);
        throw err[1];
      });
  };

  const handleEdit = (val, index) => {
    setName(val.name);
    setEmail(val.email);
    setRole(val.role);
    setId(val.id);
    setIndex(index);
    setEditModal(!editModal);
  };

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  function handleCreate(event, values) {
    event.persist();
    if (values.password.length < 6) {
      toastr.warning("Your password is less than 6 characters", "Warning");
      return false;
    }
    props.createUser(values);
  }

  const handleEditUser = (event, values) => {
    event.persist();
    values["id"] = id;
    values["index"] = index;
    props.editUserInfo(values);
  };

  const closeModal = (val) => {
    setChangePwd(val)
  }

  const StartSearch = (e) => {
    setSearch(e.target.value);
    props.getAllUsers({ page: current, pageSize: pageSize, search: e.target.value });
  }
  const changePassword = values => {
    let params = values;
    params.id = id;
    setLoading(true);
    const headers = JSON.parse(localStorage.getItem("authHeaders"));
    const config = {
      method: "post",
      url: `/api/v1/manage_users/change_password`,
      headers: {
        accesstoken: headers.accesstoken,
        client: headers.client,
        uid: headers.uid,
      },
      data: params
    };
    return axios(config)
      .then((response) => {
        if (response.status === 400 || response.status === 500)
          throw response.data;
        setLoading(false);
        setChangePwd(false);
        setShowSuccess(true);
      })
      .catch((err) => {
        setLoading(false);
        seterror_dlg(true);
        throw err[1];
      });
  }

  const userList = () => {
    if (props.users) {
      return props.users.map((item, index) => {
        return (
          <Tr key={index}>
            <Th>{item.name}</Th>
            <Td>{item.email}</Td>
            <Td>{item.role}</Td>
            <Td>{moment(item.created_at).format("llll")}</Td>
            <Td>{moment(item.updated_at).format("LLL")}</Td>
            <Td>
              <Tooltip placement="topLeft" title="Edit">
                <i
                  className="fas fa-pencil-alt actions"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleEdit(item, index)}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title="Delete">
                <i
                  className="fas fa-trash-alt actions"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDel(item)}
                />
              </Tooltip>
              <Tooltip placement="topLeft" title="Change Password">
                <i
                  className="fas fa-lock actions"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleChangePwd(item)}
                />
              </Tooltip>
            </Td>
          </Tr>
        );
      });
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("Dashboard")}
          />

          <Row>
            <Col>
              <Card>
                <CardBody>

                  <Row>
                    <Col md={6} xs={12} className="mb-2">
                      <Input value={search} onChange={StartSearch} className="search-input" size="large" placeholder="Search..." prefix={<i className="fas fa-search p-2" />} />
                    </Col>
                    <Col md={6} xs={12} className="mb-2">
                      <Button
                        type="button"
                        color="info"
                        className="mb-3 float-md-right"
                        onClick={() => {
                          setmodal(!modal);
                        }}
                      >
                        {/* <span style={{fontSize: "15px"}}>Add New User</span> */}
                        <span style={{ fontSize: "15px" }}>
                          {props.t("Add New User")}
                        </span>
                      </Button>
                    </Col>
                  </Row>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th data-priority="1">{props.t("Name")}</Th>
                            <Th data-priority="1">{props.t("Email")}</Th>
                            <Th data-priority="1">{props.t("Profile")}</Th>
                            <Th data-priority="1">{props.t("Created At")}</Th>
                            <Th data-priority="1">{props.t("Last Update")}</Th>
                            <Th data-priority="1">{props.t("Actions")}</Th>
                          </Tr>
                        </Thead>
                        <Tbody>{userList()}</Tbody>
                      </Table>
                    </div>
                  </div>
                  <Pagination
                    className="float-right"
                    current={current}
                    defaultPageSize={20}
                    onShowSizeChange={onShowSizeChange}
                    onChange={onChange}
                    total={props.totalUsers ? props.totalUsers : 0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal
            isOpen={modal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setmodal(!modal);
            }}
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => {
                  setmodal(!modal);
                }}
              >
                {props.t("Add New User")}
              </ModalHeader>
              <ModalBody>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleCreate(e, v);
                        }}
                      >
                        <AvField
                          name="name"
                          // label="Name"
                          label={props.t("Name")}
                          placeholder={props.t("First Name Last Name")}
                          type="text"
                          errorMessage={props.t("Enter name")}
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          type="select"
                          value={"Parent"}
                          name="role"
                          label={props.t("Profile")}
                          validate={{ required: { value: true } }}
                        >
                          <option>{props.t("Teacher")}</option>
                          <option>{props.t("Parent")}</option>
                        </AvField>
                        <AvField
                          name="email"
                          label={props.t("Email")}
                          placeholder={props.t("Enter Valid Email")}
                          type="email"
                          errorMessage={props.t("Inalid Email")}
                          validate={{
                            required: { value: true },
                            // email: { value: true }
                          }}
                        />

                        <AvField
                          name="password"
                          label={props.t("Password")}
                          type="password"
                          placeholder={props.t("Password")}
                          errorMessage={props.t("Enter password")}
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          name="password_confirmation"
                          label={props.t("Confirm Password")}
                          type="password"
                          placeholder={props.t("Confirm Password")}
                          errorMessage={props.t("Enter Correct Re-password")}
                          validate={{
                            required: { value: true },
                            match: { value: "password" },
                          }}
                        />
                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                              disabled={props.loading}
                            >
                              {props.loading ? (
                                <React.Fragment>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Saving
                                </React.Fragment>
                              ) : "Save"}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </ModalBody>
            </div>
          </Modal>

          <Modal
            isOpen={editModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="myModal"
            tabIndex="-1"
            toggle={() => {
              setEditModal(!editModal);
            }}
          >
            <div className="modal-content">
              <ModalHeader
                toggle={() => {
                  setEditModal(!editModal);
                }}
              >
                {props.t("Edit User")}
              </ModalHeader>
              <ModalBody>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <AvForm
                        onValidSubmit={(e, v) => {
                          handleEditUser(e, v);
                        }}
                      >
                        <AvField
                          name="name"
                          label={props.t("Name")}
                          placeholder={props.t("First Name Last Name")}
                          type="text"
                          errorMessage="Enter Name"
                          value={modalName}
                          // onChange={(e) => {setName(e.value)}}
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          type="select"
                          value={modalRole}
                          name="role"
                          label={props.t("Profile")}
                          validate={{ required: { value: true } }}
                        >
                          <option>{props.t("Teacher")}</option>
                          <option>{props.t("Parent")}</option>
                        </AvField>
                        <AvField
                          name="email"
                          label="E-Mail"
                          value={modalEmail}
                          placeholder={props.t("Enter Valid Email")}
                          type="email"
                          errorMessage={props.t("Invalid Email")}
                          validate={{
                            required: { value: true },
                          }}
                        />
                        <FormGroup className="mb-0">
                          <div>
                            <Button
                              type="submit"
                              color="primary"
                              className="mr-1"
                              disabled={props.loading}
                            >
                              {props.loading ? (
                                <React.Fragment>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    Updating
                                </React.Fragment>
                              ) : "Update"}
                            </Button>{" "}
                          </div>
                        </FormGroup>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </ModalBody>
            </div>
          </Modal>

          <ChangePasswordModal isOpen={changePwd} loading={loading} changePassword={changePassword} close={closeModal} />
          {showSuccess && (
            <SweetAlertSuccess title={"Good job!"} description={"The password has successfully changed!"} close={() => setShowSuccess(false)} />
          )}

          {confirm_both ? (
            <SweetAlert
              title={props.t("Are you sure?")}
              warning
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                setconfirm_both(false);
                postDelete();
              }}
              onCancel={() => {
                setconfirm_both(false);
                setsuccess_dlg(false);
                setdynamic_title("Cancelled");
                setdynamic_description("Your imaginary file is safe :)");
              }}
            >
              {props.t("You won't be able to revert this!")}
            </SweetAlert>
          ) : null}

          {success_dlg ? (
            <SweetAlert
              success
              title={props.t(dynamic_title)}
              onConfirm={() => {
                props.getAllUsers({ page: current, pageSize: pageSize, search });
                setsuccess_dlg(false);
                setDelData(null);
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}

          {error_dlg ? (
            <SweetAlert
              error
              title={props.t(dynamic_title)}
              onConfirm={() => {
                seterror_dlg(false);
                setDelData(null);
              }}
            >
              {dynamic_description}
            </SweetAlert>
          ) : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { loading, users, totalUsers, modal, editModal } = state.UserReducer;
  return { loading, users, totalUsers, modal, editModal };
};

export default withRouter(
  connect(mapStatetoProps, {
    createUser,
    getAllUsers,
    deleteUser,
    editUserInfo,
  })(withNamespaces()(Dashboard))
);
