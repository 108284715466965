import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_ACTIVITY_LIST } from './actionTypes';
import { getActivityListSuccess, getActivityListFailed } from './actions';
import { getAllActivities } from '../../../apiHelpers/activityApiHelper';
import toastr from 'toastr';
import { getStoreGroups, getStoreTotal } from './selectors';

function* getActivityLists() {
    try {
        const response = yield call(getAllActivities, '/api/v1/activity_lists');
        if(response.status === 200) {
            yield put(getActivityListSuccess(JSON.parse(response.data.lists)));
        } else {
            yield put(getActivityListFailed(response.status));
            // toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getActivityListFailed(error));
    }
}


export function* watchGetActivityLists() {
    yield takeEvery(GET_ACTIVITY_LIST, getActivityLists)
}



function* getAllActivityListsSaga() {
    yield all([
        fork(watchGetActivityLists)
    ]);
}

export default getAllActivityListsSaga;