import {
    GET_AGENDA_DATA,
    GET_AGENDA_DATA_SUCCESS,
    GET_AGENDA_DATA_FAILED
} from './actionTypes';

const initialState = {
    error: undefined,
    loading: false,
    agenda: undefined
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_AGENDA_DATA:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_AGENDA_DATA_SUCCESS:
            state = {
                ...state,
                loading: false,
                agenda: action.payload
            }
            break;
        case GET_AGENDA_DATA_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;