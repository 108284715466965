import {
    GET_ALL_STUDENTS,
    GET_ALL_STUDENTS_SUCCESS,
    GET_ALL_STUDENTS_FAILED,
    CREATE_STUDENT,
    CREATE_STUDENT_SUCCESS,
    CREATE_STUDENT_FAILED,
    UPDATE_STUDENT,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAILED,
    ALL_STUDENTS
} from './actionTypes';

export const getAllStudents= (page) => {
    return {
        type: GET_ALL_STUDENTS,
        payload: page
    }
}

export const getAllStudentsSuccess = (students) => {
    return {
        type: GET_ALL_STUDENTS_SUCCESS,
        payload: students,
    }
}

export const getAllStudentsFailed = (error) => {
    return {
        type: GET_ALL_STUDENTS_FAILED,
        payload: error,
    }
}

export const totalStudents = (total) => {
    return {
        type: ALL_STUDENTS,
        payload: total
    }
}

export const createStudent = (student) => {
    return {
        type: CREATE_STUDENT,
        payload: student
    }
}

export const createStudentSuccess = () => {
    return {
        type: CREATE_STUDENT_SUCCESS,
        payload: {}
    }
}

export const createStudentFailed = (error) => {
    return {
        type: CREATE_STUDENT_FAILED,
        payload: error
    }
}

export const updateStudent = (student) => {
    return {
        type: UPDATE_STUDENT,
        payload: student
    }
}

export const updateStudentSuccess = () => {
    return {
        type: UPDATE_STUDENT_SUCCESS,
        payload: {}
    }
}

export const updateStudentFailed = (error) => {
    return {
        type: UPDATE_STUDENT_FAILED,
        payload: error
    }
}





