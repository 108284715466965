import { takeEvery, fork, put, all, call, select } from 'redux-saga/effects';

import { GET_ALL_STUDENTS, CREATE_STUDENT, UPDATE_STUDENT } from './actionTypes';
import {
    getAllStudentsSuccess,
    getAllStudentsFailed,
    totalStudents,
    createStudentSuccess,
    createStudentFailed,
    updateStudentSuccess,
    updateStudentFailed,
    getAllStudents
} from './actions';
import {addNewStudent, allStudents, editStudent} from '../../../apiHelpers/studentApiHelper';
import toastr from 'toastr';
import { getStoreStudents, getStoreTotal } from './selectors';

function* getStudents({ payload: { page, pageSize } }) {
    try {
        const response = yield call(allStudents, '/api/v1/students', {page, pageSize});
        if(response.status === 200) {
            yield put(getAllStudentsSuccess(JSON.parse(response.data.students)));
            yield put(totalStudents(response.data.total))
        } else {
            yield put(getAllStudentsFailed(response.status));
            toastr.error("Something went wrong","Error")
        }

    } catch (error) {
        yield put(getAllStudentsFailed(error));
    }
}

function* createAStudent({ payload: student }) {
     try {
            const response = yield call(addNewStudent, '/api/v1/students', {...student});
            if (response.status === 200) {
                yield put(getAllStudents({page: 1, pageSize: 20}))
                yield put(createStudentSuccess())
                toastr.success("The student has been saved!")
            } else {
                yield put(createStudentFailed(response.status));
                toastr.error("It seems the SIN informed already exists","Error")
            }
    } catch (error) {
        yield put(createStudentFailed(error));
    }
}

function* updateAStudent({ payload: student }) {
    try {
        const index = student.index
        const id = student.id
        delete student.id
        delete student.index
        const response = yield call(editStudent, `/api/v1/students/${id}`, {...student});
        if (response.status === 200) {
            const newStudents = yield select(getStoreStudents)
            newStudents.splice(index, 1, JSON.parse(response.data.student))
            yield put(getAllStudentsSuccess(newStudents));
            yield put(updateStudentSuccess())
            toastr.success("The child information has been updated!")
        } else {
            toastr.error("It seems the SIN informed already exists","Error")
            yield put(updateStudentFailed(response.status));
        }
    } catch (error) {
        yield put(updateStudentFailed(error));
    }
}


export function* watchGetStudents() {
    yield takeEvery(GET_ALL_STUDENTS, getStudents)
}

export function* watchCreateStudent() {
    yield takeEvery(CREATE_STUDENT, createAStudent)
}

export function* watchUpdateStudent() {
    yield takeEvery(UPDATE_STUDENT, updateAStudent)
}


function* studentSaga() {
    yield all([
        fork(watchGetStudents),
        fork(watchCreateStudent),
        fork(watchUpdateStudent)
    ]);
}

export default studentSaga;