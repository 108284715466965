import React from "react";
import { Route } from "react-router-dom";

const NonAuthMiddleWare = ({
                            component: Component,
                            layout: Layout
                        }) => (
    <Route
        render={props => {

            return (
                <Layout>
                    <Component {...props} />
                </Layout>
            );
        }}
    />
);

export default NonAuthMiddleWare;

