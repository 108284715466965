export const CREATE_STUDENT = 'CREATE_STUDENT';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILED = 'CREATE_STUDENT_FAILED';
export const GET_ALL_STUDENTS = 'GET_ALL_STUDENTS';
export const GET_ALL_STUDENTS_SUCCESS = 'GET_ALL_STUDENTS_SUCCESS';
export const GET_ALL_STUDENTS_FAILED = 'GET_ALL_STUDENTS_FAILED';
export const UPDATE_STUDENT = 'UPDATE_STUDENT';
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS';
export const UPDATE_STUDENT_FAILED = 'UPDATE_STUDENT_FAILED';
export const ALL_STUDENTS = 'ALL_STUDENTS';

