import {
    GET_ACTIVITY,
    GET_WORKBOOK,
    GET_WORKBOOK_SUCCESS,
    GET_WORKBOOK_FAILED,
    GET_ACTIVITY_SUCCESS,
    GET_ACTIVITY_FAILED,
    GET_GROUPS,
    GET_GROUPS_SUCCESS,
    GET_GROUPS_FAILED
} from './actionTypes';

const initialState = {
    error: "",
    loading: false,
    workbooks: [],
    activities: [],
    groups: []
}

const activity = (state = initialState, action) => {
    switch (action.type) {
        case GET_WORKBOOK:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_WORKBOOK_SUCCESS:
            state = {
                ...state,
                loading: false,
                workbooks: action.payload
            }
            break;
        case GET_WORKBOOK_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case GET_ACTIVITY:
            state = {
                ...state,
                loading: true
            }
            break;
        case GET_ACTIVITY_SUCCESS:
            state = {
                ...state,
                loading: false,
                activities: action.payload
            }
            break;
        case GET_ACTIVITY_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case GET_GROUPS:
            state = {
                ...state
            }
            break;
        case GET_GROUPS_SUCCESS:
            state = {
                ...state,
                groups: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activity;