export const GET_WORKBOOK = 'GET_WORKBOOK';
export const GET_WORKBOOK_SUCCESS = 'GET_WORKBOOK_SUCCESS';
export const GET_WORKBOOK_FAILED = 'GET_WORKBOOK_FAILED';
export const GET_ACTIVITY = 'GET_ACTIVITY';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAILED = 'GET_ACTIVITY_FAILED';
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';


