import {
    GET_AGENDA_DATA,
    GET_AGENDA_DATA_SUCCESS,
    GET_AGENDA_DATA_FAILED
} from './actionTypes';

export const getAgendaData = (params) => {
    return {
        type: GET_AGENDA_DATA,
        payload: params
    }
}

export const getAgendaDataSuccess = (data) => {
    return {
        type: GET_AGENDA_DATA_SUCCESS,
        payload: data
    }
}

export const getAgendaDataFailed = (error) => {
    return {
        type: GET_AGENDA_DATA_FAILED,
        payload: error
    }
}