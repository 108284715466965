import React, { useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, CardFooter, FormGroup, UncontrolledAlert } from "reactstrap";
import { withRouter } from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import { connect } from 'react-redux';


import { getGroupList, getGalleryData } from '../../store/actions';
//i18n
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { callStudents, findActivities } from "../../apiHelpers/activityApiHelper";
import { uploadFiles, updateImage, stashUpload } from "../../apiHelpers/photoGalleryApiHelper";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import {
    Modal,
    Select,
    Form,
    Upload,
    Spin,
    Button,
    Pagination,
    Tooltip,
    Checkbox
} from "antd";
import axios from "axios";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import moment from "moment";
import ImgsViewer from 'react-images-viewer'
import { blobToURL, fromBlob } from 'image-resize-compress';

const firebase = require("firebase/app");

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}
// const date = Date.now()
const TeacherGallery = (props) => {


    useEffect(() => {
        props.getGroupList()
    }, [])

    const [students, setStudents] = useState([]);
    const [studentId, setStudentId] = useState(undefined);

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState("")
    const [previewVisible, setPreviewVisible] = useState(false)
    const [fileList, setFileList] = useState([])
    const [previewTitle, setPreviewTitle] = useState('')
    const [pending, setPending] = useState(false)


    const [allActivities, setAllActivities] = useState([]);
    const [imgUrl, setImgUrl] = useState(undefined);
    const [thumbnailUrl, setThumbnailUrl] = useState(undefined);

    const [current, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(120);

    //sweet alert settings
    const [confirm_both, setconfirm_both] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [error_dlg, seterror_dlg] = useState(false);
    const [dynamic_title, setdynamic_title] = useState("");
    const [dynamic_description, setdynamic_description] = useState("");
    const [delData, setDelData] = useState(null)

    const [selectedFiles, setSelectedFiles] = useState([]);

    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const [imgs, setImgs] = useState([])
    const [currentImg, setCurrentImg] = useState(undefined)
    const [imageIndex, setImageIndex] = useState(undefined)

    const [groupUpload, setGroupUpload] = useState(false)

    const [url, setUrl] = useState(undefined)
    const [selectedStudent, setSelectedStudent] = useState("Select Student")


    const clearViewer = () => {
        setViewerIsOpen(false)
        setCurrentImg(undefined)
        setImageIndex(undefined)
    }

    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );
        setSelectedFiles(files);
    };

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };


    function onShowSizeChange(current, pageSize) {
        props.getGalleryData({ studentId: studentId, page: current, pageSize: pageSize })
        setPageSize(pageSize)
        setCurrentPage(current)
    }

    const onChange = (page) => {
        props.getGalleryData({ studentId: studentId, page: page, pageSize: pageSize })
        setCurrentPage(page)
    }

    const FindPhotos = (event, values) => {
        event.persist()
        if (values.group === "Select Group") {
            toastr.info("Please select a group", "Info")
            return false
        }

        if (values.student === "Select Student") {
            toastr.info("Please select a student", "Info")
            return false
        }

        setStudentId(values.student)
        props.getGalleryData({ studentId: values.student, page: current, pageSize: pageSize })
        delete values.group
    }

    const getStudent = (e) => {
        setStudents([])
        clear()
        callStudents({ group_id: e.target.value })
            .then((result) => {
                if (result.status === 200) {
                    setStudents(JSON.parse(result.data.students))
                    setSelectedStudent(JSON.parse(result.data.students)[0].id);
                } else {
                    toastr.warning(result.data.message, "Warning")
                }
            })
            .catch((error) => {
                toastr.error("Something went wrong!", "Error")
            })
    }

    const options = () => {
        if (props.groups) {
            return props.groups.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                )
            })
        }
    }

    const activityOptions = () => {
        if (students) {
            return students.map((item, index) => {
                return (
                    <option key={index} value={item.id}>
                        {item.firstName + " " + item.lastName}
                    </option>
                )
            })
        }
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const handleUpload = () => {
        setPending(true);
        setLoading(true);
        const big_quality = 70;
        const big_width = 800;
        const big_height = 'auto';
        const tiny_quality = 40;
        const tiny_width = 35;
        const tiny_height = 35;
        let loop = 0
        if (selectedFiles.length !== 0) {
            selectedFiles.map(async (file, index) => {
                let filePath = "images/activityImages/" + file.name;
                let thumbnailPath = "images/thumbnailImages/" + file.name;
                await fromBlob(file, big_quality, big_width, big_height).then(async (blob) => {
                    await firebase
                        .storage()
                        .ref(filePath)
                        .put(blob)
                        .then(async (fileSnapshot) => {
                            await fileSnapshot.ref
                                .getDownloadURL()
                                .then(async (url) => {
                                    await uploadFiles({ title: file.name, url: url, studentId: studentId, class_upload: groupUpload })
                                        .then(async (result) => {
                                            loop++;
                                            if (result.status === 200) {
                                                await fromBlob(file, tiny_quality, tiny_width, tiny_height).then(async (tiny_blob) => {
                                                    await firebase
                                                        .storage()
                                                        .ref(thumbnailPath)
                                                        .put(tiny_blob)
                                                        .then(async (fileSnapshot) => {
                                                            await fileSnapshot.ref
                                                                .getDownloadURL()
                                                                .then(async (thumbnailUrl) => {
                                                                    await updateImage({ id: result.data.id, thumbUrl: thumbnailUrl, class_upload: groupUpload })
                                                                        .then((result1) => {
                                                                            if (result1.status === 200) {
                                                                                toastr.success(result1.data.message, "Success")
                                                                                if (loop === selectedFiles.length) {
                                                                                    setPending(false)
                                                                                    setLoading(false)
                                                                                    setGroupUpload(false)
                                                                                    handleCancel();
                                                                                    clear();
                                                                                }
                                                                            } else {
                                                                                toastr.error(result1.message, "Uploading Error")
                                                                                if (loop === fileList.length) {
                                                                                    setPending(false)
                                                                                    setLoading(false)
                                                                                }
                                                                            }
                                                                            if (loop === selectedFiles.length) {
                                                                                props.getGalleryData({ studentId: studentId, page: current, pageSize: pageSize })
                                                                            }
                                                                        })
                                                                        .catch((e) => toastr.warning(e.message, 'Warning'));
                                                                })
                                                                .catch((e) => console.log("firebase error === ", e));
                                                        })
                                                        .catch((e) => console.log("firebase error === ", e));
                                                }).catch((e) => console.log("firebase error === ", e));
                                            } else {
                                                toastr.error(result.message, "Uploading Error")
                                                if (loop === fileList.length) {
                                                    setPending(false)
                                                    setLoading(false)
                                                }
                                            }
                                        })
                                        .catch((e) => toastr.warning(e.message, 'Warning'));
                                })
                                .catch((e) => console.log("firebase error === ", e));
                        })
                        .catch((e) => console.log("firebase error === ", e));
                }).catch((e) => console.log("firebase error === ", e));
            })
        }
    }

    const clear = () => {
        setSelectedFiles([])
    }


    const images = () => {
        if (props.gallery) {
            return props.gallery.map((item, index) => {
                return (

                    <Fragment key={index}>
                        {index === 0 || moment(item.created_at).format('l') !== moment(props.gallery[index - 1].created_at).format('l') ?
                            <Col xs={12} className="image-header pt-3">
                                <h3 style={{ position: "relative", color: "#383838", top: "25px", left: "25px", zIndex: "1" }}>{moment(item.created_at).format("YYYY-MM-DD")}</h3>
                            </Col>
                            : ""
                        }
                        <div className="pl-3 pt-4 pb-2 pr-3 " style={{ position: "relative", left: "12px", backgroundColor: "#f5f5f5", borderRadius: "3px" }}>
                            <div><img src={item.thumbnail_url} onClick={() => handleCurrent(item, index)} /></div>
                            <div className="text-center">
                                <Tooltip placement="top" title="delete image">
                                    <i className="fas fa-trash-alt" onClick={() => handleDelete(item.id, item.image_url, item.thumbnail_url)}></i>
                                </Tooltip>
                            </div>
                        </div>
                    </Fragment >

                )
            })
        }
    }

    const handleDelete = (id, imageUrl, thumbnailUrl) => {
        setDelData(id)
        setconfirm_both(true)
        setImgUrl(imageUrl)
        setThumbnailUrl(thumbnailUrl)
    }

    const postDelete = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        let imageRef = firebase.storage().refFromURL(imgUrl)
        let thumbRef = firebase.storage().refFromURL(thumbnailUrl)
        imageRef.delete()
            .then(() => {
                thumbRef.delete()
                    .then(() => {
                        const config = {
                            method: "delete",
                            url: `/api/v1/image_gallery/${delData}`,
                            headers: {
                                accesstoken: headers.accesstoken,
                                client: headers.client,
                                uid: headers.uid,
                            }
                        }
                        return axios(config).then(response => {
                            if (response.status === 400 || response.status === 500)
                                throw response.data;
                            setsuccess_dlg(true);
                            setdynamic_title("Deleted");
                            setdynamic_description("Image has been deleted.");
                        }).catch(err => {
                            seterror_dlg(true)
                            throw err[1];
                        });
                    })
            })
            .catch((error) => {
                toastr.error("Failed to delete image. Please try again", "Error")
            })
    }

    const handleSave = () => {
        if (selectedFiles.length < 1) {
            toastr.info("Please select files to upload", "Info")
            return false
        }
        handleUpload()
        // handleStagingUpload()
    }

    const previews = () => {
        return selectedFiles.map((f, i) => {
            return (
                <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                >
                    <div className="p-2">
                        <Row className="align-items-center">
                            <Col className="col-auto">
                                <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f.name}
                                    src={f.preview}
                                />
                            </Col>
                            <Col>
                                <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                >
                                    {f.name}
                                </Link>
                                <p className="mb-0">
                                    <strong>{f.formattedSize}</strong>
                                </p>
                            </Col>
                            <Col className="text-right">
                                <i className="fas fa-trash-alt" style={{ cursor: "pointer" }} onClick={() => handleStashImage(f.preview)}></i>
                            </Col>
                        </Row>
                    </div>
                </Card>
            );
        })
    }

    const handleStashImage = (item) => {
        let stash = selectedFiles;
        stash.splice(item, 1);
        setSelectedFiles(selectedFiles.filter(image => image !== item));
    }

    const handleCurrent = (img, num) => {
        if (props.gallery) {
            let images = []
            let total = 0
            props.gallery.forEach((item, index) => {
                if (moment(item.created_at).format('l') === moment(img.created_at).format('l')) {
                    images.push({ src: item.image_url });
                    if (num === index) {
                        setCurrentImg(total)
                        setImageIndex(total)
                    }
                    total++;
                }
            })
            setImgs(images)
        }
        setViewerIsOpen(true)
    }

    const handleViewerOpen = (value) => {
        // setViewerIsOpen(value)
        clearViewer()
    }

    const gotoPrevious = () => {
        setCurrentImg(imageIndex - 1)
        setImageIndex(imageIndex - 1)
    }

    const gotoNext = () => {
        setCurrentImg(imageIndex + 1)
        setImageIndex(imageIndex + 1)
    }

    const handleGroupUploading = (value) => {
        setGroupUpload(value)
    }

    //////////////// Image Download and Update DB Functions(not using in production) ///////////////////
    const getUrls = () => {
        const headers = JSON.parse(localStorage.getItem("authHeaders"))
        const config = {
            method: "get",
            url: `/api/v1/image_gallery/all_urls`,
            headers: {
                accesstoken: headers.accesstoken,
                client: headers.client,
                uid: headers.uid,
            }
        }
        axios(config).then(response => {
            if (response.status === 400 || response.status === 500)
                throw response.data;
        }).catch(err => {
            console.log(err)
            throw err[1];
        });
    }



    const handleStagingUpload = () => {
        setPending(true);
        setLoading(true);
        const big_quality = 70;
        const big_width = 800;
        const big_height = 'auto';
        const tiny_quality = 40;
        const tiny_width = 35;
        const tiny_height = 35;
        let loop = 0
        if (selectedFiles.length !== 0) {
            selectedFiles.map(async (file, index) => {
                let filePath = "images/activityImages/" + file.name;
                let thumbnailPath = "images/thumbnailImages/" + file.name;
                await fromBlob(file, big_quality, big_width, big_height).then(async (blob) => {
                    await firebase
                        .storage()
                        .ref(filePath)
                        .put(blob)
                        .then(async (fileSnapshot) => {
                            await fileSnapshot.ref
                                .getDownloadURL()
                                .then(async (url) => {
                                    await stashUpload("/api/v1/image_gallery/stash_upload", { title: file.name, url: url })
                                        .then(async (result) => {
                                            loop++;
                                            if (result.status === 200) {
                                                await fromBlob(file, tiny_quality, tiny_width, tiny_height).then(async (tiny_blob) => {
                                                    await firebase
                                                        .storage()
                                                        .ref(thumbnailPath)
                                                        .put(tiny_blob)
                                                        .then(async (fileSnapshot) => {
                                                            await fileSnapshot.ref
                                                                .getDownloadURL()
                                                                .then(async (thumbnailUrl) => {
                                                                    await stashUpload('/api/v1/image_gallery/stash_update', { id: result.data.id, thumbUrl: thumbnailUrl })
                                                                        .then((result1) => {
                                                                            if (result1.status === 200) {
                                                                                toastr.success(result1.data.message, "Success")
                                                                            } else {
                                                                                toastr.error(result1.message, "Uploading Error")
                                                                            }
                                                                        })
                                                                        .catch((e) => toastr.warning(e.message, 'Warning'));
                                                                })
                                                                .catch((e) => console.log("firebase error === ", e));
                                                        })
                                                        .catch((e) => console.log("firebase error === ", e));
                                                }).catch((e) => console.log("firebase error === ", e));
                                            } else {
                                                toastr.error(result.message, "Uploading Error")
                                            }
                                        })
                                        .catch((e) => toastr.warning(e.message, 'Warning'));
                                })
                                .catch((e) => console.log("firebase error === ", e));
                        })
                        .catch((e) => console.log("firebase error === ", e));
                }).catch((e) => console.log("firebase error === ", e));
            })
        }
    }

    ////////////////////////////////////////////////////////////////

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={props.t('Photo Gallery')} breadcrumbItem={props.t('photo gallery')} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <AvForm className="outer-repeater row m-auto" style={{ width: "100%" }} onValidSubmit={(e, v) => { FindPhotos(e, v) }}>
                                            <Col sm={4} xs={12}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={"Select Group"}
                                                        name="group"
                                                        validate={{ required: { value: true } }}
                                                        onChange={getStudent}
                                                    >
                                                        <option disabled={true}>Select Group</option>
                                                        {options()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12}>
                                                <div className="form-group">
                                                    <AvField
                                                        type="select"
                                                        value={selectedStudent}
                                                        name="student"
                                                        validate={{ required: { value: true } }}
                                                        onChange={() => clear()}
                                                        disabled={groupUpload}
                                                    >
                                                        <option disabled={true}>Select Student</option>
                                                        {activityOptions()}
                                                    </AvField>
                                                </div>
                                            </Col>
                                            <Col sm={4} xs={12} className={"mr-0"}>
                                                <FormGroup className="mb-0">
                                                    <div>
                                                        <button type="submit" className="btn btn-primary mr-1" style={{ width: "100%" }} disabled={props.loading}>
                                                            {props.loading ? (
                                                                <React.Fragment>
                                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                                    Finding
                                                                </React.Fragment>
                                                            ) : "Find"}
                                                        </button>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </AvForm>
                                        {/* <button onClick={getUrls} className="btn btn-primary">Download</button> */}
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <UncontrolledAlert color="info" className="alert-dismissible fade show mb-0 mt-2" role="alert">
                                                We have improved Photo Gallery!
                                                <li>Uploading and loading pictures when selecting a child is now faster!</li>
                                                <li>Have you noticed the small checkbox <b>Class</b> below? Now a group photo can be uploaded to all parents for the selected class.</li>
                                                Upload once and that's it :D !
                                            </UncontrolledAlert>
                                        </Col>
                                    </Row> */}
                                    {studentId && (
                                        <Fragment>
                                            <Form className="mt-3">
                                                <Dropzone
                                                    onDrop={acceptedFiles => { handleAcceptedFiles(acceptedFiles) }
                                                    }
                                                    maxSize={5000000}
                                                    accept='.jpeg, .png, .jpg, .gif'
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div
                                                                className="dz-message needsclick mt-2"
                                                                {...getRootProps()}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                </div>
                                                                <h4>Drop files here or click to upload.</h4>
                                                                <p style={{ fontSize: "13px" }}>Max size: 5MB, File Formats: jpg, png, jpeg</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                <div
                                                    className="dropzone-previews mt-3"
                                                    id="file-previews"
                                                >
                                                    {previews()}
                                                </div>
                                            </Form>

                                            <div className="text-center mt-4 mb-5">
                                                {/* <span className="badge badge-pill badge-danger">New</span>&nbsp; */}
                                                <Tooltip placement="top" title="The picture will be available to all parents for the selected class">
                                                    <Checkbox
                                                        checked={groupUpload}
                                                        onClick={e => handleGroupUploading(e.target.checked)}
                                                    >
                                                        <span style={{ fontSize: "13px" }}>Class</span>
                                                    </Checkbox>
                                                </Tooltip>
                                                <button
                                                    disabled={pending ? true : false}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light"
                                                    onClick={handleSave}
                                                >
                                                    {pending ? (
                                                        <React.Fragment>
                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            Uploading...
                                                        </React.Fragment>
                                                    ) : "Upload Files"}
                                                </button>
                                            </div>
                                        </Fragment>
                                    )}


                                    <hr />
                                    <Row className="mt-5">
                                        {imgs.length > 0 && (
                                            // console.log(imgSrc())
                                            <ImgsViewer
                                                imgs={imgs}
                                                currImg={currentImg}
                                                isOpen={viewerIsOpen}
                                                closeBtnTitle="Close"
                                                leftArrowTitle={'Previous'}
                                                rightArrowTitle={'Next'}
                                                onClickPrev={gotoPrevious}
                                                onClickNext={gotoNext}
                                                onClose={() => handleViewerOpen(false)}
                                            />
                                        )}
                                        {images()}
                                    </Row>
                                    {props.total && (
                                        <Pagination className="float-right mt-3" current={current} defaultPageSize={120} onShowSizeChange={onShowSizeChange} onChange={onChange} total={props.total ? props.total : 0} />
                                    )}
                                    {confirm_both ? (
                                        <SweetAlert
                                            title="Are you sure?"
                                            warning
                                            showCancel
                                            confirmBtnBsStyle="success"
                                            cancelBtnBsStyle="danger"
                                            onConfirm={() => {
                                                setconfirm_both(false);
                                                postDelete()

                                            }
                                            }
                                            onCancel={() => {
                                                setconfirm_both(false);
                                                setsuccess_dlg(false);
                                                setdynamic_title("Cancelled");
                                                setdynamic_description("Your imaginary file is safe :)");
                                            }
                                            }
                                        >
                                            You won't be able to revert this!
                                        </SweetAlert>
                                    ) : null}

                                    {success_dlg ? (
                                        <SweetAlert
                                            success
                                            title={dynamic_title}
                                            onConfirm={() => {
                                                props.getGalleryData({ studentId: studentId, page: current, pageSize: pageSize })
                                                setsuccess_dlg(false);
                                                setDelData(null);
                                            }}
                                        >
                                            {dynamic_description}
                                        </SweetAlert>
                                    ) : null}

                                    {error_dlg ? (
                                        <SweetAlert
                                            error
                                            title={dynamic_title}
                                            onConfirm={() => {
                                                seterror_dlg(false);
                                                setDelData(null);
                                            }}
                                        >
                                            {dynamic_description}
                                        </SweetAlert>
                                    ) : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = state => {
    const { groups } = state.ActivityReducer;
    const { gallery, total, loading } = state.GalleryReducer;
    return { groups, gallery, total, loading };
}

export default withRouter(connect(mapStateToProps, { getGroupList, getGalleryData })(withNamespaces()(TeacherGallery)));
